import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';

@Injectable()

export class customInterceptor implements HttpInterceptor {

  constructor(private UserService: UserService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
    let auth = window.origin.includes("navyapp.canadacentral.cloudapp.azure.com") ? 'yes' : 'no'; 
    const newReq = req.clone({
      headers: req.headers.set('Authorization', "Bearer " + this.UserService.accessToken).set('Authentication', auth)
    })
    return next.handle(newReq);
  }

}
