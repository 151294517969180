// import { AuthenticationComponent } from './../authentication/authentication.component';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, ViewChild, Input, Output, EventEmitter, Inject, ChangeDetectorRef, HostListener } from '@angular/core';
import { MatSidenav } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../services/language.service';
import { ConfigService } from '../services/config.service';
import { MsalService, BroadcastService } from '@azure/msal-angular';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Logger, CryptoUtils } from 'msal';
import { User } from '../user';
// import { OAuthSettings } from '../../oauth';
// import { Client } from "@microsoft/microsoft-graph-client";
// import * as MicrosoftGraph from '@microsoft/microsoft-graph-types';

import { UserService } from '../services/user.service';
import { ChitService } from '../services/chit.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import * as io from 'socket.io-client';
import { CommunicationService } from '../services/communication.service';

// import { AdminComponent, AdminContentDialog } from '../admin/admin.component';
// import * as userDat from '../../assets/menuData/userInfo.json';
// import { v4 as uuid } from 'uuid';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [DatePipe]
})

export class HeaderComponent implements OnInit {
  socket: any;
  @ViewChild(MatSidenav, { static: false }) sidenav: MatSidenav;
  @Output() public openContentPage = new EventEmitter();
  @Output() public homePage = new EventEmitter();
  @Input() loggedIn: any = false;
  opened: boolean = false;
  search: any = '';
  selectedMenu: any = '';
  sideNavigate: boolean = false;
  LR_Control: any = 'Left';
  languageSelected: any = 'English';
  childMenu: any = '';
  submenuFourthDataList: any;
  scsConfig: any = { "version": [{ "version": "0.0.2", "notes": "scs" }] };
  sdmsConfig: any = { "version": [{ "version": "0.0.1", "notes": "sdms" }] }

  subscriptions: Subscription[] = [];
  isIframe = false;
  // loggedIn = false;
  userId: any = '';
  userName: any = '';
  user: User;
  usersList: Array<String> = [];
  AppSideNavList: any = []
  profileDetails: any = '';
  userAccessDetails: any = {};
  should_open = false;
  isAdmin = false;
  userLoginInfo: boolean = true;
  loaderState: boolean = false;
  scsURL: any = this.getSCSURL();
  sdmsURL: any = this.getSDMSURL();
  slmsURL: any = this.getSLMSURL();
  userScreenResolution: any;
  AccessObject: any = { "activity": "", "chit": "", "hazard_area": "Y", "first-aid": "N", "aed": "Y", "hazard": "Y", "kiosk": "Y", "laydown": "Y" };

  AppUrlList: any = [
    {
      "name": "Digital Dockyard",
      "fr_name": "Chantier naval numérique",
      "key": "sdms",

      "childUrl": [
        {
          "appName": "Officer",
          "keyName": "Officer",
          "key": "sdms",
          "disabled": false,
          "logo": "../assets/images/pacific.png",
          "content": {
            "contentHeader": "Commanding officer",
            "content": [
              "The SDMS application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
            ],
            "control": [
              "place cursor and right-click mouse",
              "right-click-hold and drag",
              "left-click and drag",
              "mouse wheel or controls bottom right",
              "",
              "controls menu at the bottom of screen"
            ],
            "position": [
              { "icon": "menu", "content": "Show or Hide walls" },
              { "icon": "radio_button_checked", "content": "Home view" },
              { "icon": "3d-icon", "content": "2D and 3D views" },
              { "icon": "add", "content": "Zoom in" },
              { "icon": "remove", "content": "Zoom out" }
            ]
          },
          "url": this.sdmsURL + "?appmode=ship"
        },
        {
          "appName": "CFB Esquimalt",
          "keyName": "MARPAC",
          "key": "sdms",
          "disabled": false,
          "logo": "../assets/images/pacific.png",
          "content": {
            "contentHeader": "Commanding officer",
            "content": [
              "The SDMS application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
            ],
            "control": [
              "place cursor and right-click mouse",
              "right-click-hold and drag",
              "left-click and drag",
              "mouse wheel or controls bottom right",
              "",
              "controls menu at the bottom of screen"
            ],
            "position": [
              { "icon": "menu", "content": "Show or Hide walls" },
              { "icon": "radio_button_checked", "content": "Home view" },
              { "icon": "3d-icon", "content": "2D and 3D views" },
              { "icon": "add", "content": "Zoom in" },
              { "icon": "remove", "content": "Zoom out" }
            ]
          },
          "url": this.sdmsURL + "?planType=MARPAC"
        },
        {
          "appName": "CFB Halifax",
          "keyName": "MARLANT",
          "key": "sdms",
          "logo": "../assets/images/atlantic.png",
          "disabled": false,
          "content": {
            "contentHeader": "MARLANT",
            "content": [
              "The SDMS application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
            ],
            "control": [
              "place cursor and right-click mouse",
              "right-click-hold and drag",
              "left-click and drag",
              "mouse wheel or controls bottom right",
              "",
              "controls menu at the bottom of screen"
            ],
            "position": [
              { "icon": "menu", "content": "Show or Hide walls" },
              { "icon": "radio_button_checked", "content": "Home view" },
              { "icon": "3d-icon", "content": "2D and 3D views" },
              { "icon": "add", "content": "Zoom in" },
              { "icon": "remove", "content": "Zoom out" }
            ]
          },
          "url": this.sdmsURL + "?planType=MARLANT"
        },
        {
          "appName": "DVT Plan Report",
          "keyName": "dvt",
          "key": "sdms",
          "disabled": false,
          "content": {
            "contentHeader": "Test DVT plan",
            "content": [
              "The SDMS application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
            ],
            "control": [
              "place cursor and right-click mouse",
              "right-click-hold and drag",
              "left-click and drag",
              "mouse wheel or controls bottom right",
              "",
              "controls menu at the bottom of screen"
            ],
            "position": [
              { "icon": "menu", "content": "Show or Hide walls" },
              { "icon": "radio_button_checked", "content": "Home view" },
              { "icon": "3d-icon", "content": "2D and 3D views" },
              { "icon": "add", "content": "Zoom in" },
              { "icon": "remove", "content": "Zoom out" }
            ]
          },
          "url": this.sdmsURL + "?planType=GDP"
        },
        // {
        //   "appName": "RADHAZ demo",
        //   "keyName": "radhaz",
        //   "key": "sdms",
        //   "disabled": false,
        //   "content": {
        //     "contentHeader": "Cranes",
        //     "content": [
        //       "The SDMS application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
        //     ],
        //     "control": [
        //       "place cursor and right-click mouse",
        //       "right-click-hold and drag",
        //       "left-click and drag",
        //       "mouse wheel or controls bottom right",
        //       "",
        //       "controls menu at the bottom of screen"
        //     ],
        //     "position": [
        //       { "icon": "menu", "content": "Show or Hide walls" },
        //       { "icon": "radio_button_checked", "content": "Home view" },
        //       { "icon": "3d-icon", "content": "2D and 3D views" },
        //       { "icon": "add", "content": "Zoom in" },
        //       { "icon": "remove", "content": "Zoom out" }
        //     ]
        //   },
        //   "url": this.sdmsURL + "?planType=radhaz"
        // },
        {
          "appName": "Comms Console",
          "keyName": "comms-console",
          "parent": "Commsc",
          "key": "sdms",
          "disabled": false,
          "content": {
            "contentHeader": "Comms Console",
            "content": [
              "The SDMS application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
            ],
            "control": [
              "place cursor and right-click mouse",
              "right-click-hold and drag",
              "left-click and drag",
              "mouse wheel or controls bottom right",
              "",
              "controls menu at the bottom of screen"
            ],
            "position": [
              { "icon": "menu", "content": "Show or Hide walls" },
              { "icon": "radio_button_checked", "content": "Home view" },
              { "icon": "3d-icon", "content": "2D and 3D views" },
              { "icon": "add", "content": "Zoom in" },
              { "icon": "remove", "content": "Zoom out" }
            ]
          },
          // "url": this.sdmsURL + "?planType=MARPAC-QHM"
          "url": this.sdmsURL + "?appmode=chat"
        },

        // {
        //   "appName": "Commanding officer",
        //   "keyName": "commanding",
        //   "key": "sdms",
        //   "logo": "../assets/images/navy.png",
        //   "disabled": false,
        //   "content": {
        //     "contentHeader": "Commanding officer",
        //     "content": [
        //       "The SDMS application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
        //     ],
        //     "control": [
        //       "place cursor and right-click mouse",
        //       "right-click-hold and drag",
        //       "left-click and drag",
        //       "mouse wheel or controls bottom right",
        //       "",
        //       "controls menu at the bottom of screen"
        //     ],
        //     "position": [
        //       { "icon": "menu", "content": "Show or Hide walls" },
        //       { "icon": "radio_button_checked", "content": "Home view" },
        //       { "icon": "3d-icon", "content": "2D and 3D views" },
        //       { "icon": "add", "content": "Zoom in" },
        //       { "icon": "remove", "content": "Zoom out" }
        //     ]
        //   },
        //   "url": "commanding"
        // },
        {
          "appName": "Library",
          "keyName": "sdmslibrary",
          "key": "sdms",
          "disabled": false,
          "content": {
            "contentHeader": "Library",
            "content": [
              "The SDMS application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
            ],
            "control": [
              "place cursor and right-click mouse",
              "right-click-hold and drag",
              "left-click and drag",
              "mouse wheel or controls bottom right",
              "",
              "controls menu at the bottom of screen"
            ],
            "position": [
              { "icon": "menu", "content": "Show or Hide walls" },
              { "icon": "radio_button_checked", "content": "Home view" },
              { "icon": "3d-icon", "content": "2D and 3D views" },
              { "icon": "add", "content": "Zoom in" },
              { "icon": "remove", "content": "Zoom out" }
            ]
          },
          "url": "LIBRARY"
        },
        {
          "appName": "Visitor Information",
          "keyName": "visitor_information",
          "key": "navy_app",
          "disabled": false,
          "url": '',
          "childList": [
            {
              "appName": "Registration",
              "keyName": "visitor_registration",
              "key": "navy_app",
              "disabled": true,
              "url": "",
              "subkey": "visitor_registration_navy_app",
            },
            {
              "appName": "Scan",
              "keyName": "visitor_scan",
              "key": "scs",
              "disabled": false,
              "url": "",
              "subkey": "visitor_scan_navy_app",
            }
          ],
          "child": false,
          "subkey": "visitor_navy_app",
        },
        // {
        //   "appName": "Modify Tree Exercise",
        //   "keyName": "node_dvt",
        //   "key": "scs",
        //   "disabled": false,
        //   "content": {
        //     "contentHeader": "nodedvt",
        //     "content": [
        //       "The scs application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
        //     ],
        //     "control": [
        //       "place cursor and right-click mouse",
        //       "right-click-hold and drag",
        //       "left-click and drag",
        //       "mouse wheel or controls bottom right",
        //       "",
        //       "controls menu at the bottom of screen"
        //     ],
        //     "position": [
        //       { "icon": "menu", "content": "Show or Hide walls" },
        //       { "icon": "radio_button_checked", "content": "Home view" },
        //       { "icon": "3d-icon", "content": "2D and 3D views" },
        //       { "icon": "add", "content": "Zoom in" },
        //       { "icon": "remove", "content": "Zoom out" }
        //     ]
        //   },
        //   "url": this.slmsURL + "?appmode=network&nodetree=dvt"
        // },
        // {
        //   "appName": "Fleet maintenance",
        //   "keyName": "fleet-maintenance",
        //   "parent": "MARLANT",
        //   "key": "sdms",
        //   "disabled": false,
        //   "subkey": "ant_fleet_sdms",
        //   "content": {
        //     "contentHeader": "Fleet maintenance",
        //     "content": [
        //       "The SDMS application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
        //     ],
        //     "control": [
        //       "place cursor and right-click mouse",
        //       "right-click-hold and drag",
        //       "left-click and drag",
        //       "mouse wheel or controls bottom right",
        //       "",
        //       "controls menu at the bottom of screen"
        //     ],
        //     "position": [
        //       { "icon": "menu", "content": "Show or Hide walls" },
        //       { "icon": "radio_button_checked", "content": "Home view" },
        //       { "icon": "3d-icon", "content": "2D and 3D views" },
        //       { "icon": "add", "content": "Zoom in" },
        //       { "icon": "remove", "content": "Zoom out" }
        //     ]
        //   },
        //   "childList": [
        //     {
        //       "appName": "FMF",
        //       "keyName": "FMF",
        //       "key": "sdms",
        //       "disabled": false,
        //       "url": this.sdmsURL+  "?planType=MARLANT_FMF"
        //     },
        //     {
        //       "appName": "Hazard areas",
        //       "keyName": "hazard",
        //       "key": "sdms",
        //       "disabled": false,
        //       "url": this.sdmsURL+  "?planType=MARLANT_FMF_HAZARD"
        //     },
        //     {
        //       "appName": "AED",
        //       "keyName": "aed",
        //       "key": "sdms",
        //       "disabled": false,
        //       "url": this.sdmsURL+  "?planType=MARLANT_FMF_FAID"
        //     },
        //     {
        //       "appName": "First aid stations",
        //       "keyName": "first-aid",
        //       "key": "sdms",
        //       "disabled": false,
        //       "url": this.sdmsURL+  "?planType=MARLANT_FMF_FAID"
        //     },
        //     {
        //       "appName": "Spill kits",
        //       "keyName": "spill-kits",
        //       "key": "sdms",
        //       "disabled": true,
        //       "url": this.sdmsURL+  "?planType=FLEET"
        //     }
        //   ],
        //   "url": "",
        //   "child": false
        // },
        // {
        //   "appName": "Waterfront management",
        //   "keyName": "waterfront-management",
        //   "parent": "MARLANT",
        //   "key": "sdms",
        //   "subkey": "ant_waterfront_sdms",
        //   "disabled": false,
        //   "content": {
        //     "contentHeader": "Comms Console",
        //     "content": [
        //       "The SDMS application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
        //     ],
        //     "control": [
        //       "place cursor and right-click mouse",
        //       "right-click-hold and drag",
        //       "left-click and drag",
        //       "mouse wheel or controls bottom right",
        //       "",
        //       "controls menu at the bottom of screen"
        //     ],
        //     "position": [
        //       { "icon": "menu", "content": "Show or Hide walls" },
        //       { "icon": "radio_button_checked", "content": "Home view" },
        //       { "icon": "3d-icon", "content": "2D and 3D views" },
        //       { "icon": "add", "content": "Zoom in" },
        //       { "icon": "remove", "content": "Zoom out" }
        //     ]
        //   },
        //   "childList": [
        //     {
        //       "appName": "WfM",
        //       "keyName": "WFM",
        //       "key": "sdms",
        //       "disabled": false,
        //       "url": this.sdmsURL+  "?planType=MARLANT_WFM"
        //     },
        //     {
        //       "appName": "Hazard areas",
        //       "keyName": "hazard",
        //       "key": "sdms",
        //       "disabled": false,
        //       "url": this.sdmsURL+  "?planType=MARLANT_WFM_HAZARD"
        //     },
        //     {
        //       "appName": "AED",
        //       "keyName": "aed",
        //       "key": "sdms",
        //       "disabled": false,
        //       "url": this.sdmsURL+  "?planType=MARLANT_WFM_FAID"
        //     },
        //     {
        //       "appName": "First aid stations",
        //       "keyName": "first-aid",
        //       "key": "sdms",
        //       "disabled": false,
        //       "url": this.sdmsURL+  "?planType=MARLANT_WFM_FAID"
        //     },
        //     {
        //       "appName": "Kiosks",
        //       "keyName": "kiosk",
        //       "key": "sdms",
        //       "disabled": true,
        //       "url": this.sdmsURL+  "?planType=MARLANT_WFM_KIOSK"
        //     },
        //     {
        //       "appName": "Laydown areas",
        //       "keyName": "laydown",
        //       "key": "sdms",
        //       "disabled": false,
        //       "url": this.sdmsURL+  "?planType=MARLANT_WFM_LAYDOWN"
        //     },
        //     {
        //       "appName": "Cranes",
        //       "keyName": "cranes",
        //       "key": "sdms",
        //       "disabled": true,
        //       "url": this.sdmsURL+  "?planType=FLEET"
        //     },
        //     {
        //       "appName": "Forklifts",
        //       "keyName": "fork-lifts",
        //       "key": "sdms",
        //       "disabled": true,
        //       "url": this.sdmsURL+  "?planType=FLEET"
        //     },
        //     {
        //       "appName": "Generators",
        //       "keyName": "portable-generators",
        //       "key": "sdms",
        //       "disabled": true,
        //       "url": this.sdmsURL+  "?planType=FLEET"
        //     },
        //     {
        //       "appName": "Shore cable",
        //       "keyName": "shore-cable",
        //       "key": "sdms",
        //       "disabled": true,
        //       "url": this.sdmsURL+  "?planType=FLEET"
        //     }
        //   ],
        //   "url": "",
        //   "child": false
        // },
        // {
        //   "appName": "QHM",
        //   "keyName": "qhm",
        //   "parent": "MARLANT",
        //   "key": "sdms",
        //   "disabled": false,
        //   "content": {
        //     "contentHeader": "Comms Console",
        //     "content": [
        //       "The SDMS application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
        //     ],
        //     "control": [
        //       "place cursor and right-click mouse",
        //       "right-click-hold and drag",
        //       "left-click and drag",
        //       "mouse wheel or controls bottom right",
        //       "",
        //       "controls menu at the bottom of screen"
        //     ],
        //     "position": [
        //       { "icon": "menu", "content": "Show or Hide walls" },
        //       { "icon": "radio_button_checked", "content": "Home view" },
        //       { "icon": "3d-icon", "content": "2D and 3D views" },
        //       { "icon": "add", "content": "Zoom in" },
        //       { "icon": "remove", "content": "Zoom out" }
        //     ]
        //   },
        //   "url": this.sdmsURL+  "?planType=MARLANT-QHM"
        // },
        // {
        //   "appName": "Comms Console",
        //   "keyName": "comms-console",
        //   "parent": "MARLANT",
        //   "key": "sdms",
        //   "disabled": false,
        //   "content": {
        //     "contentHeader": "Comms Console",
        //     "content": [
        //       "The SDMS application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
        //     ],
        //     "control": [
        //       "place cursor and right-click mouse",
        //       "right-click-hold and drag",
        //       "left-click and drag",
        //       "mouse wheel or controls bottom right",
        //       "",
        //       "controls menu at the bottom of screen"
        //     ],
        //     "position": [
        //       { "icon": "menu", "content": "Show or Hide walls" },
        //       { "icon": "radio_button_checked", "content": "Home view" },
        //       { "icon": "3d-icon", "content": "2D and 3D views" },
        //       { "icon": "add", "content": "Zoom in" },
        //       { "icon": "remove", "content": "Zoom out" }
        //     ]
        //   },
        //   "url": this.sdmsURL+  "?planType=MARLANT-QHM"
        // },
        // {
        //   "appName": "Reports",
        //   "keyName": "reports",
        //   "parent": "MARLANT",
        //   "key": "sdms",
        //   "disabled": false,
        //   "content": {
        //     "contentHeader": "Comms Console",
        //     "content": [
        //       "The SDMS application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
        //     ],
        //     "control": [
        //       "place cursor and right-click mouse",
        //       "right-click-hold and drag",
        //       "left-click and drag",
        //       "mouse wheel or controls bottom right",
        //       "",
        //       "controls menu at the bottom of screen"
        //     ],
        //     "position": [
        //       { "icon": "menu", "content": "Show or Hide walls" },
        //       { "icon": "radio_button_checked", "content": "Home view" },
        //       { "icon": "3d-icon", "content": "2D and 3D views" },
        //       { "icon": "add", "content": "Zoom in" },
        //       { "icon": "remove", "content": "Zoom out" }
        //     ]
        //   },
        //   "url": this.sdmsURL+  "?planType=MARLANT-REPORT"
        // },
        // {
        //   "appName": "MARPAC",
        //   "keyName": "MARPAC",
        //   "key": "sdms",
        //   "disabled": false,
        //   "logo": "../assets/images/pacific.png",
        //   "content": {
        //     "contentHeader": "Commanding officer",
        //     "content": [
        //       "The SDMS application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
        //     ],
        //     "control": [
        //       "place cursor and right-click mouse",
        //       "right-click-hold and drag",
        //       "left-click and drag",
        //       "mouse wheel or controls bottom right",
        //       "",
        //       "controls menu at the bottom of screen"
        //     ],
        //     "position": [
        //       { "icon": "menu", "content": "Show or Hide walls" },
        //       { "icon": "radio_button_checked", "content": "Home view" },
        //       { "icon": "3d-icon", "content": "2D and 3D views" },
        //       { "icon": "add", "content": "Zoom in" },
        //       { "icon": "remove", "content": "Zoom out" }
        //     ]
        //   },
        //   "url": this.sdmsURL+  "?planType=MARPAC"
        // },
        // {
        //   "appName": "Fleet maintenance",
        //   "keyName": "fleet-maintenance",
        //   "parent": "MARPAC",
        //   "key": "sdms",
        //   "subkey": "pac_fleet_sdms",
        //   "disabled": false,
        //   "content": {
        //     "contentHeader": "Fleet maintenance",
        //     "content": [
        //       "The SDMS application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
        //     ],
        //     "control": [
        //       "place cursor and right-click mouse",
        //       "right-click-hold and drag",
        //       "left-click and drag",
        //       "mouse wheel or controls bottom right",
        //       "",
        //       "controls menu at the bottom of screen"
        //     ],
        //     "position": [
        //       { "icon": "menu", "content": "Show or Hide walls" },
        //       { "icon": "radio_button_checked", "content": "Home view" },
        //       { "icon": "3d-icon", "content": "2D and 3D views" },
        //       { "icon": "add", "content": "Zoom in" },
        //       { "icon": "remove", "content": "Zoom out" }
        //     ]
        //   },
        //   "childList": [
        //     {
        //       "appName": "FMF",
        //       "keyName": "FMF",
        //       "key": "sdms",
        //       "disabled": false,
        //       "url": this.sdmsURL+  "?planType=MARPAC_FMF"
        //     },
        //     {
        //       "appName": "Hazard areas",
        //       "keyName": "hazard",
        //       "key": "sdms",
        //       "disabled": false,
        //       "url": this.sdmsURL+  "?planType=MARPAC_FMF_HAZARD"
        //     },
        //     {
        //       "appName": "AED",
        //       "keyName": "aed",
        //       "key": "sdms",
        //       "disabled": false,
        //       "url": this.sdmsURL+  "?planType=MARPAC_FMF_FAID"
        //     },
        //     {
        //       "appName": "First aid stations",
        //       "keyName": "first-aid",
        //       "key": "sdms",
        //       "disabled": false,
        //       "url": this.sdmsURL+  "?planType=MARPAC_FMF_FAID"
        //     },
        //     {
        //       "appName": "Spill kits",
        //       "keyName": "spill-kits",
        //       "key": "sdms",
        //       "disabled": true,
        //       "url": this.sdmsURL+  "?planType=FLEET"
        //     }
        //   ],
        //   "url": "",
        //   "child": false
        // },
        // {
        //   "appName": "Waterfront management",
        //   "keyName": "waterfront-management",
        //   "parent": "MARPAC",
        //   "key": "sdms",
        //   "subkey": "pac_waterfront_sdms",
        //   "disabled": false,
        //   "content": {
        //     "contentHeader": "Comms Console",
        //     "content": [
        //       "The SDMS application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
        //     ],
        //     "control": [
        //       "place cursor and right-click mouse",
        //       "right-click-hold and drag",
        //       "left-click and drag",
        //       "mouse wheel or controls bottom right",
        //       "",
        //       "controls menu at the bottom of screen"
        //     ],
        //     "position": [
        //       { "icon": "menu", "content": "Show or Hide walls" },
        //       { "icon": "radio_button_checked", "content": "Home view" },
        //       { "icon": "3d-icon", "content": "2D and 3D views" },
        //       { "icon": "add", "content": "Zoom in" },
        //       { "icon": "remove", "content": "Zoom out" }
        //     ]
        //   },
        //   "childList": [
        //     {
        //       "appName": "WfM",
        //       "keyName": "WFM",
        //       "key": "sdms",
        //       "disabled": false,
        //       "url": this.sdmsURL+  "?planType=MARPAC_WFM"
        //     },
        //     {
        //       "appName": "Hazard areas",
        //       "keyName": "hazard",
        //       "key": "sdms",
        //       "disabled": false,
        //       "url": this.sdmsURL+  "?planType=MARPAC_WFM_HAZARD"
        //     },
        //     {
        //       "appName": "AED",
        //       "keyName": "aed",
        //       "key": "sdms",
        //       "disabled": false,
        //       "url": this.sdmsURL+  "?planType=MARPAC_WFM_FAID"
        //     },
        //     {
        //       "appName": "First aid stations",
        //       "keyName": "first-aid",
        //       "key": "sdms",
        //       "disabled": false,
        //       "url": this.sdmsURL+  "?planType=MARPAC_WFM_FAID"
        //     },
        //     {
        //       "appName": "Kiosks",
        //       "keyName": "kiosk",
        //       "key": "sdms",
        //       "disabled": false,
        //       "url": this.sdmsURL+  "?planType=MARPAC_WFM_KIOSK"
        //     },
        //     {
        //       "appName": "Laydown areas",
        //       "keyName": "laydown",
        //       "key": "sdms",
        //       "disabled": false,
        //       "url": this.sdmsURL+  "?planType=MARPAC_WFM_LAYDOWN"
        //     },
        //     {
        //       "appName": "Cranes",
        //       "keyName": "cranes",
        //       "key": "sdms",
        //       "disabled": true,
        //       "url": this.sdmsURL+  "?planType=FLEET"
        //     },
        //     {
        //       "appName": "Forklifts",
        //       "keyName": "fork-lifts",
        //       "key": "sdms",
        //       "disabled": true,
        //       "url": this.sdmsURL+  "?planType=FLEET"
        //     },
        //     {
        //       "appName": "Generators",
        //       "keyName": "portable-generators",
        //       "key": "sdms",
        //       "disabled": true,
        //       "url": this.sdmsURL+  "?planType=FLEET"
        //     },
        //     {
        //       "appName": "Shore cable",
        //       "keyName": "shore-cable",
        //       "key": "sdms",
        //       "disabled": true,
        //       "url": this.sdmsURL+  "?planType=FLEET"
        //     }
        //   ],
        //   "url": "",
        //   "child": false
        // },
        // {
        //   "appName": "QHM",
        //   "keyName": "qhm",
        //   "parent": "MARPAC",
        //   "key": "sdms",
        //   "disabled": false,
        //   "content": {
        //     "contentHeader": "Comms Console",
        //     "content": [
        //       "The SDMS application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
        //     ],
        //     "control": [
        //       "place cursor and right-click mouse",
        //       "right-click-hold and drag",
        //       "left-click and drag",
        //       "mouse wheel or controls bottom right",
        //       "",
        //       "controls menu at the bottom of screen"
        //     ],
        //     "position": [
        //       { "icon": "menu", "content": "Show or Hide walls" },
        //       { "icon": "radio_button_checked", "content": "Home view" },
        //       { "icon": "3d-icon", "content": "2D and 3D views" },
        //       { "icon": "add", "content": "Zoom in" },
        //       { "icon": "remove", "content": "Zoom out" }
        //     ]
        //   },
        //   "url": this.sdmsURL+  "?planType=MARPAC-QHM"
        // },
        // {
        //   "appName": "Comms Console",
        //   "keyName": "comms-console",
        //   "parent": "MARPAC",
        //   "key": "sdms",
        //   "disabled": false,
        //   "content": {
        //     "contentHeader": "Comms Console",
        //     "content": [
        //       "The SDMS application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
        //     ],
        //     "control": [
        //       "place cursor and right-click mouse",
        //       "right-click-hold and drag",
        //       "left-click and drag",
        //       "mouse wheel or controls bottom right",
        //       "",
        //       "controls menu at the bottom of screen"
        //     ],
        //     "position": [
        //       { "icon": "menu", "content": "Show or Hide walls" },
        //       { "icon": "radio_button_checked", "content": "Home view" },
        //       { "icon": "3d-icon", "content": "2D and 3D views" },
        //       { "icon": "add", "content": "Zoom in" },
        //       { "icon": "remove", "content": "Zoom out" }
        //     ]
        //   },
        //   "url": this.sdmsURL+  "?planType=MARPAC-QHM"
        // },
        // {
        //   "appName": "Reports",
        //   "keyName": "reports",
        //   "parent": "MARPAC",
        //   "key": "sdms",
        //   "disabled": false,
        //   "content": {
        //     "contentHeader": "Comms Console",
        //     "content": [
        //       "The SDMS application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
        //     ],
        //     "control": [
        //       "place cursor and right-click mouse",
        //       "right-click-hold and drag",
        //       "left-click and drag",
        //       "mouse wheel or controls bottom right",
        //       "",
        //       "controls menu at the bottom of screen"
        //     ],
        //     "position": [
        //       { "icon": "menu", "content": "Show or Hide walls" },
        //       { "icon": "radio_button_checked", "content": "Home view" },
        //       { "icon": "3d-icon", "content": "2D and 3D views" },
        //       { "icon": "add", "content": "Zoom in" },
        //       { "icon": "remove", "content": "Zoom out" }
        //     ]
        //   },
        //   "url": this.sdmsURL+  "?planType=MARPAC-REPORT"
        // }
        // {
        //   "appName": "Live Day",
        //   "key": "sdms",

        //   "content": {
        //     "contentHeader": "Live Day",
        //     "content": [
        //       "The SDMS application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
        //     ],
        //     "control": [
        //       "place cursor and right-click mouse",
        //       "right-click-hold and drag",
        //       "left-click and drag",
        //       "mouse wheel or controls bottom right",
        //       "",
        //       "controls menu at the bottom of screen"
        //     ],
        //     "position": [
        //       { "icon": "menu", "content": "Show or Hide walls" },
        //       { "icon": "radio_button_checked", "content": "Home view" },
        //       { "icon": "3d-icon", "content": "2D and 3D views" },
        //       { "icon": "add", "content": "Zoom in" },
        //       { "icon": "remove", "content": "Zoom out" }
        //     ]
        //   },
        //   "url": this.sdmsURL+  "?planType=PTP"
        // },
        // {
        //   "appName": "Kiosk Manager",
        //   "key": "sdms",

        //   "content": {
        //     "contentHeader": "Kiosk Manager",
        //     "content": [
        //       "The SDMS application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
        //     ],
        //     "control": [
        //       "place cursor and right-click mouse",
        //       "right-click-hold and drag",
        //       "left-click and drag",
        //       "mouse wheel or controls bottom right",
        //       "",
        //       "controls menu at the bottom of screen"
        //     ],
        //     "position": [
        //       { "icon": "menu", "content": "Show or Hide walls" },
        //       { "icon": "radio_button_checked", "content": "Home view" },
        //       { "icon": "3d-icon", "content": "2D and 3D views" },
        //       { "icon": "add", "content": "Zoom in" },
        //       { "icon": "remove", "content": "Zoom out" }
        //     ]
        //   },
        //   "url": this.sdmsURL+  "?planType=KIOSK"
        // },
        // {
        //   "appName": "Generate Day Plan",
        //   "key": "sdms",

        //   "content": {
        //     "contentHeader": "Generate Day Plan",
        //     "content": [
        //       "The SDMS application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
        //     ],
        //     "control": [
        //       "place cursor and right-click mouse",
        //       "right-click-hold and drag",
        //       "left-click and drag",
        //       "mouse wheel or controls bottom right",
        //       "",
        //       "controls menu at the bottom of screen"
        //     ],
        //     "position": [
        //       { "icon": "menu", "content": "Show or Hide walls" },
        //       { "icon": "radio_button_checked", "content": "Home view" },
        //       { "icon": "3d-icon", "content": "2D and 3D views" },
        //       { "icon": "add", "content": "Zoom in" },
        //       { "icon": "remove", "content": "Zoom out" }
        //     ]
        //   },
        //   "url": this.sdmsURL+  "?planType=GDP"
        // },
        // {
        //   "appName": "Share Cables",
        //   "key": "sdms",

        //   "content": {
        //     "contentHeader": "Share Cables",
        //     "content": [
        //       "The SDMS application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
        //     ],
        //     "control": [
        //       "place cursor and right-click mouse",
        //       "right-click-hold and drag",
        //       "left-click and drag",
        //       "mouse wheel or controls bottom right",
        //       "",
        //       "controls menu at the bottom of screen"
        //     ],
        //     "position": [
        //       { "icon": "menu", "content": "Show or Hide walls" },
        //       { "icon": "radio_button_checked", "content": "Home view" },
        //       { "icon": "3d-icon", "content": "2D and 3D views" },
        //       { "icon": "add", "content": "Zoom in" },
        //       { "icon": "remove", "content": "Zoom out" }
        //     ]
        //   },
        //   "url": ""
        // },
        // {
        //   "appName": "QHM Assets",
        //   "key": "sdms",

        //   "content": {
        //     "contentHeader": "QHM Assets",
        //     "content": [
        //       "The SDMS application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
        //     ],
        //     "control": [
        //       "place cursor and right-click mouse",
        //       "right-click-hold and drag",
        //       "left-click and drag",
        //       "mouse wheel or controls bottom right",
        //       "",
        //       "controls menu at the bottom of screen"
        //     ],
        //     "position": [
        //       { "icon": "menu", "content": "Show or Hide walls" },
        //       { "icon": "radio_button_checked", "content": "Home view" },
        //       { "icon": "3d-icon", "content": "2D and 3D views" },
        //       { "icon": "add", "content": "Zoom in" },
        //       { "icon": "remove", "content": "Zoom out" }
        //     ]
        //   },
        //   "url": ""
        // },
        // {
        //   "appName": "Portable Generators",
        //   "key": "sdms",

        //   "content": {
        //     "contentHeader": "Portable Generators",
        //     "content": [
        //       "The SDMS application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
        //     ],
        //     "control": [
        //       "place cursor and right-click mouse",
        //       "right-click-hold and drag",
        //       "left-click and drag",
        //       "mouse wheel or controls bottom right",
        //       "",
        //       "controls menu at the bottom of screen"
        //     ],
        //     "position": [
        //       { "icon": "menu", "content": "Show or Hide walls" },
        //       { "icon": "radio_button_checked", "content": "Home view" },
        //       { "icon": "3d-icon", "content": "2D and 3D views" },
        //       { "icon": "add", "content": "Zoom in" },
        //       { "icon": "remove", "content": "Zoom out" }
        //     ]
        //   },
        //   "url": ""
        // },
        // {
        //   "appName": "Forklifts",
        //   "key": "sdms",

        //   "content": {
        //     "contentHeader": "Forklifts",
        //     "content": [
        //       "The SDMS application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
        //     ],
        //     "control": [
        //       "place cursor and right-click mouse",
        //       "right-click-hold and drag",
        //       "left-click and drag",
        //       "mouse wheel or controls bottom right",
        //       "",
        //       "controls menu at the bottom of screen"
        //     ],
        //     "position": [
        //       { "icon": "menu", "content": "Show or Hide walls" },
        //       { "icon": "radio_button_checked", "content": "Home view" },
        //       { "icon": "3d-icon", "content": "2D and 3D views" },
        //       { "icon": "add", "content": "Zoom in" },
        //       { "icon": "remove", "content": "Zoom out" }
        //     ]
        //   },
        //   "url": ""
        // },
        // {
        //   "appName": "Cranes",
        //   "key": "sdms",

        //   "content": {
        //     "contentHeader": "Cranes",
        //     "content": [
        //       "The SDMS application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
        //     ],
        //     "control": [
        //       "place cursor and right-click mouse",
        //       "right-click-hold and drag",
        //       "left-click and drag",
        //       "mouse wheel or controls bottom right",
        //       "",
        //       "controls menu at the bottom of screen"
        //     ],
        //     "position": [
        //       { "icon": "menu", "content": "Show or Hide walls" },
        //       { "icon": "radio_button_checked", "content": "Home view" },
        //       { "icon": "3d-icon", "content": "2D and 3D views" },
        //       { "icon": "add", "content": "Zoom in" },
        //       { "icon": "remove", "content": "Zoom out" }
        //     ]
        //   },
        //   "url": ""
        // },

        // {
        //   "appName": "Live Day",
        //   "key": "sdms",

        //   "content": {
        //     "contentHeader": "Live Day",
        //     "content": [
        //       "The SDMS application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
        //     ],
        //     "control": [
        //       "place cursor and right-click mouse",
        //       "right-click-hold and drag",
        //       "left-click and drag",
        //       "mouse wheel or controls bottom right",
        //       "",
        //       "controls menu at the bottom of screen"
        //     ],
        //     "position": [
        //       { "icon": "menu", "content": "Show or Hide walls" },
        //       { "icon": "radio_button_checked", "content": "Home view" },
        //       { "icon": "3d-icon", "content": "2D and 3D views" },
        //       { "icon": "add", "content": "Zoom in" },
        //       { "icon": "remove", "content": "Zoom out" }
        //     ]
        //   },
        //   "url": this.sdmsURL+  "?planType=LIVE"
        // },
        // {
        //   "appName": "Officer of the Day",
        //   "key": "sdms",

        //   "content": {
        //     "contentHeader": "Officer of the Day",
        //     "content": [
        //       "The SDMS application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
        //     ],
        //     "control": [
        //       "place cursor and right-click mouse",
        //       "right-click-hold and drag",
        //       "left-click and drag",
        //       "mouse wheel or controls bottom right",
        //       "",
        //       "controls menu at the bottom of screen"
        //     ],
        //     "position": [
        //       { "icon": "menu", "content": "Show or Hide walls" },
        //       { "icon": "radio_button_checked", "content": "Home view" },
        //       { "icon": "3d-icon", "content": "2D and 3D views" },
        //       { "icon": "add", "content": "Zoom in" },
        //       { "icon": "remove", "content": "Zoom out" }
        //     ]
        //   },
        //   "url": this.sdmsURL+  ""
        // },
        // {
        //   "appName": "QHM",
        //   "key": "sdms",

        //   "content": {
        //     "contentHeader": "QHM",
        //     "content": [
        //       "The SDMS application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
        //     ],
        //     "control": [
        //       "place cursor and right-click mouse",
        //       "right-click-hold and drag",
        //       "left-click and drag",
        //       "mouse wheel or controls bottom right",
        //       "",
        //       "controls menu at the bottom of screen"
        //     ],
        //     "position": [
        //       { "icon": "menu", "content": "Show or Hide walls" },
        //       { "icon": "radio_button_checked", "content": "Home view" },
        //       { "icon": "3d-icon", "content": "2D and 3D views" },
        //       { "icon": "add", "content": "Zoom in" },
        //       { "icon": "remove", "content": "Zoom out" }
        //     ]
        //   },
        //   "url": this.sdmsURL+  ""
        // },
        // {
        //   "appName": "Waterfront Operations",
        //   "key": "sdms",

        //   "content": {
        //     "contentHeader": "Waterfront Operations",
        //     "content": [
        //       "The SDMS application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
        //     ],
        //     "control": [
        //       "place cursor and right-click mouse",
        //       "right-click-hold and drag",
        //       "left-click and drag",
        //       "mouse wheel or controls bottom right",
        //       "",
        //       "controls menu at the bottom of screen"
        //     ],
        //     "position": [
        //       { "icon": "menu", "content": "Show or Hide walls" },
        //       { "icon": "radio_button_checked", "content": "Home view" },
        //       { "icon": "3d-icon", "content": "2D and 3D views" },
        //       { "icon": "add", "content": "Zoom in" },
        //       { "icon": "remove", "content": "Zoom out" }
        //     ]
        //   },
        //   "url": this.sdmsURL+  ""
        // }

      ]
    },
    // {
    //   "name": "maintenance support",
    //   "fr_name": "fleetway",
    //   "key": "Fleetway",
    //   "childUrl": [],
    //   "url": this.slmsURL + "?appmode=kys",
    //   "child": false
    // },
    {
      "name": "maintenance support",
      "fr_name": "fleetway",
      "key": "Fleetway",
      "childUrl": [
        {
          "appName": "Maritime Equipment Management (MEPM)",
          "keyName": "mepm",
          "key": "scs",
          "disabled": false,
          "logo": "../assets/images/pacific.png",
          "content": {
            "contentHeader": "Mepm",
            "content": [
              "The KYS application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
            ],
            "control": [
              "place cursor and right-click mouse",
              "right-click-hold and drag",
              "left-click and drag",
              "mouse wheel or controls bottom right",
              "",
              "controls menu at the bottom of screen"
            ],
            "position": [
              { "icon": "menu", "content": "Show or Hide walls" },
              { "icon": "radio_button_checked", "content": "Home view" },
              { "icon": "3d-icon", "content": "2D and 3D views" },
              { "icon": "add", "content": "Zoom in" },
              { "icon": "remove", "content": "Zoom out" }
            ]
          },
          "url": this.slmsURL + "?appmode=mepm"
        },
        {
          "appName": "Maritime Equipment Management (MEPM)-(2.0)",
          "keyName": "mepm",
          "key": "scs",
          "disabled": false,
          "logo": "../assets/images/pacific.png",
          "content": {
            "contentHeader": "Mepm",
            "content": [
              "The KYS application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
            ],
            "control": [
              "place cursor and right-click mouse",
              "right-click-hold and drag",
              "left-click and drag",
              "mouse wheel or controls bottom right",
              "",
              "controls menu at the bottom of screen"
            ],
            "position": [
              { "icon": "menu", "content": "Show or Hide walls" },
              { "icon": "radio_button_checked", "content": "Home view" },
              { "icon": "3d-icon", "content": "2D and 3D views" },
              { "icon": "add", "content": "Zoom in" },
              { "icon": "remove", "content": "Zoom out" }
            ]
          },
          "url": this.sdmsURL + "?appmode=mepm"
        },
        {
          "appName": "FMF Cape Breton",
          "keyName": "Fmfcb",
          "key": "scs",
          "logo": "../assets/images/atlantic.png",
          "disabled": false,
          "content": {
            "contentHeader": "Fmfcb",
            "content": [
              "The scs application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
            ],
            "control": [
              "place cursor and right-click mouse",
              "right-click-hold and drag",
              "left-click and drag",
              "mouse wheel or controls bottom right",
              "",
              "controls menu at the bottom of screen"
            ],
            "position": [
              { "icon": "menu", "content": "Show or Hide walls" },
              { "icon": "radio_button_checked", "content": "Home view" },
              { "icon": "3d-icon", "content": "2D and 3D views" },
              { "icon": "add", "content": "Zoom in" },
              { "icon": "remove", "content": "Zoom out" }
            ]
          },
          "url": this.slmsURL + "?appmode=fmfcb"
        },
        {
          "appName": "FleetVIEW MS demo",
          "keyName": "msd",
          "key": "scs",
          "disabled": false,
          "content": {
            "contentHeader": "msd",
            "content": [
              "The scs application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
            ],
            "control": [
              "place cursor and right-click mouse",
              "right-click-hold and drag",
              "left-click and drag",
              "mouse wheel or controls bottom right",
              "",
              "controls menu at the bottom of screen"
            ],
            "position": [
              { "icon": "menu", "content": "Show or Hide walls" },
              { "icon": "radio_button_checked", "content": "Home view" },
              { "icon": "3d-icon", "content": "2D and 3D views" },
              { "icon": "add", "content": "Zoom in" },
              { "icon": "remove", "content": "Zoom out" }
            ]
          },
          "url": this.slmsURL + "?appmode=msd"
        },
        {
          "appName": "Halifax Version",
          "keyName": "fleetview-ms-demo",
          "key": "slms",
          "disabled": true,
          "url": "",
        },
      ]
    },
    {
      "name": "MARTECH",
      "fr_name": "Académie MARTECH",
      "key": "slms",
      "childUrl": [
        // {
        //   "appName": "Courses",
        //   "keyName": "courses",
        //   "key": "scs",
        //   "disabled": false,
        //   "url": this.slmsURL + "?appmode=network",
        // },
        // {
        //   "appName": "Diesel Generator",
        //   "keyName": "idg",
        //   "key": "scs",
        //   "disabled": false,
        //   "url": "",
        //   "subkey": "idg_slms",
        //   "childList": [
        //     {
        //       "appName": "Diesel Generator",
        //       "keyName": "diesel_gen",
        //       "key": "scs",
        //       "disabled": false,
        //       "url": this.slmsURL,
        //       "subkey": "diesel_gen_slms",
        //     },
        //     {
        //       "appName": "Diesel Generator in Operation",
        //       "keyName": "engine_model",
        //       "key": "scs",
        //       "disabled": false,
        //       "url": this.slmsURL + '?appmode=idgo',
        //       "subkey": "enigne_model_slms",
        //     },
        //     // {
        //     //   "appName": "Engine Model",
        //     //   "keyName": "engine_model",
        //     //   "key": "scs",
        //     //   "disabled": false,
        //     //   "url": this.slmsURL + '?appmode=iengine',
        //     //   "subkey": "enigne_model_slms",
        //     // },
        //     {
        //       "appName": "Knowledge Check",
        //       "keyName": "quiz",
        //       "key": "scs",
        //       "disabled": false,
        //       "url": "",
        //       "subkey": "quiz_slms",
        //       "childList": [
        //         {
        //           "appName": "Identify Parts",
        //           "keyName": "id_part",
        //           "key": "scs",
        //           "disabled": false,
        //           "url": this.slmsURL + '?appmode=idg&submode=test&testid=1',
        //           "subkey": "quiz_slms",
        //         },
        //         {
        //           "appName": "Assemble Parts",
        //           "keyName": "assemble_part",
        //           "key": "scs",
        //           "disabled": false,
        //           "url": this.slmsURL + '?appmode=idg&submode=test&testid=2',
        //           "subkey": "assemble_part_slms",
        //         },
        //       ],
        //       "child": false
        //     },
        //   ],
        //   "child": false
        // },
        {
          "appName": "iNeXTT Diesel Generator",
          "keyName": "idg",
          "key": "scs",
          "disabled": false,
          "url": "",
          "subkey": "idg_slms",
          "childList": [
            {
              "appName": "Diesel Generator External",
              "keyName": "diesel_gen",
              "key": "scs",
              "disabled": false,
              "url": this.slmsURL,
              "subkey": "diesel_gen_slms",
            },
            {
              "appName": "Diesel Generator Internal",
              "keyName": "engine_model",
              "key": "scs",
              "disabled": false,
              "url": this.slmsURL + '?appmode=idg-animation',
              "subkey": "enigne_model_slms",
            },
            // {
            //   "appName": "Fluid Systems",
            //   "keyName": "fluid_Systems",
            //   "key": "scs",
            //   "disabled": false,
            //   "url": this.slmsURL + '?appmode=idgo',
            //   "subkey": "enigne_model_slms",
            // },
            // {
            //   "appName": "Engine Model",
            //   "keyName": "engine_model",
            //   "key": "scs",
            //   "disabled": false,
            //   "url": this.slmsURL + '?appmode=iengine',
            //   "subkey": "enigne_model_slms",
            // },
            {
              "appName": "Troubleshooting",
              "keyName": "trouble",
              "key": "scs",
              "disabled": true,
              "url": "",
              "subkey": "trouble_slms",
              "childList": [
                {
                  "appName": "Engine",
                  "keyName": "Engineulid",
                  "key": "scs",
                  "disabled": false,
                  "url": "",
                  "subkey": "trouble_engine_slms",
                  "childList": [
                    {
                      "appName": "Air Flow",
                      "keyName": "bulid",
                      "key": "scs",
                      "disabled": true,
                      "url": "",

                    },
                    {
                      "appName": "Restrictions",
                      "keyName": "bulid",
                      "key": "scs",
                      "disabled": true,
                      "url": "",
                    },
                    {
                      "appName": "Coolant Leaks",
                      "keyName": "bulid",
                      "key": "scs",
                      "disabled": true,
                      "url": "",
                    },
                    {
                      "appName": "Worn Piston Rings",
                      "keyName": "bulid",
                      "key": "scs",
                      "disabled": true,
                      "url": "",
                    },
                    {
                      "appName": "Oil Interruption",
                      "keyName": "bulid",
                      "key": "scs",
                      "disabled": true,
                      "url": "",
                    }
                  ]
                },
                {
                  "appName": "Fluid Systems",
                  "keyName": "trouble_power_gen",
                  "key": "scs",
                  "disabled": true,
                  "url": "",
                }
              ],
              "child": false
            },
            {
              "appName": "Knowledge Check",
              "keyName": "quiz",
              "key": "scs",
              "disabled": false,
              "url": "",
              "subkey": "quiz_slms",
              "childList": [
                {
                  "appName": "Identify Parts",
                  "keyName": "id_part",
                  "key": "scs",
                  "disabled": false,
                  "url": this.slmsURL + '?appmode=idg&submode=test&testid=1',
                  "subkey": "quiz_slms",
                },
                {
                  "appName": "Assemble Parts",
                  "keyName": "assemble_part",
                  "key": "scs",
                  "disabled": false,
                  "url": this.slmsURL + '?appmode=idg&submode=test&testid=2',
                  "subkey": "assemble_part_slms",
                },
              ],
              "child": false
            },
          ],
          "child": false
        },
        {
          "appName": "MARTECH Campus",
          "keyName": "inexxt_campus",
          "key": "scs",
          "disabled": false,
          "url": "",
          "subkey": "ic_slms",
          "childList": [
            {
              "appName": "Buildings",
              "keyName": "Buildingsbulid",
              "key": "scs",
              "disabled": false,
              "url": this.slmsURL + "?appmode=inexxt",
              "subkey": "bulid_ic_slms",
            },
            {
              "appName": "Power Generation",
              "keyName": "power_gen",
              "key": "scs",
              "disabled": true,
              "url": "",
              "subkey": "power_gen_ic_slms",
            },
            {
              "appName": "Power Distribution",
              "keyName": "power_dis",
              "key": "scs",
              "disabled": true,
              "url": "",
              "subkey": "power_dis_ic_slms",
            },
            {
              "appName": "Building Operations",
              "keyName": "build_op",
              "key": "scs",
              "disabled": true,
              "url": "",
              "subkey": "build_op_ic_slms",
            },
            {
              "appName": "Maintenance Roles",
              "keyName": "main_role",
              "key": "scs",
              "disabled": true,
              "url": "",
              "subkey": "main_role_ic_slms",
            },
            {
              "appName": "MAST",
              "keyName": "mast",
              "key": "scs",
              "disabled": true,
              "url": "",
              "subkey": "mast_ic_slms",
            },
          ],
          "child": false
        },
        {
          "appName": "Certification Paths",
          "keyName": "certPath",
          "key": "scs",
          "disabled": false,
          "url": "",
          "subkey": "certPath_slms",
          "childList": [
            {
              "appName": "RQS1",
              "keyName": "bulid",
              "key": "scs",
              "disabled": false,
              "url": this.slmsURL + "?appmode=network&nodetree=dvt",
            },
            {
              "appName": "RQS2",
              "keyName": "power_gen_rqs",
              "key": "scs",
              "disabled": false,
              "url": this.slmsURL + "?appmode=network&node=Misc",
            },
            {
              "appName": "RQS3",
              "keyName": "power_dis_rqs",
              "key": "scs",
              "disabled": false,
              "url": this.slmsURL + "?appmode=network&nodetree=rqs",
            },
            {
              "appName": "Mechanical Specialist - LS",
              "keyName": "power_dis_ls",
              "key": "scs",
              "disabled": false,
              "url": this.slmsURL + "?appmode=network&nodetree=mechanical",
            },
            {
              "appName": "Modify Curriculum Demo",
              "keyName": "node_dvt",
              "key": "scs",
              "disabled": false,
              "content": {
                "contentHeader": "nodedvt",
                "content": [
                  "The scs application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
                ],
                "control": [
                  "place cursor and right-click mouse",
                  "right-click-hold and drag",
                  "left-click and drag",
                  "mouse wheel or controls bottom right",
                  "",
                  "controls menu at the bottom of screen"
                ],
                "position": [
                  { "icon": "menu", "content": "Show or Hide walls" },
                  { "icon": "radio_button_checked", "content": "Home view" },
                  { "icon": "3d-icon", "content": "2D and 3D views" },
                  { "icon": "add", "content": "Zoom in" },
                  { "icon": "remove", "content": "Zoom out" }
                ]
              },
              "url": this.slmsURL + "?appmode=network&nodetree=dvt"
            },
            {
              "appName": "TECHS",
              "keyName": "roleRes",
              "key": "scs",
              "disabled": false,
              "url": "",
              "subkey": "roleRes_slms",
              "childList": [
                {
                  "appName": "MARTECH",
                  "keyName": "role_bulid",
                  "key": "scs",
                  "disabled": true,
                  "url": "",
                },
                {
                  "appName": "Naval Warfare Officer",
                  "keyName": "role_power_gen",
                  "key": "scs",
                  "disabled": true,
                  "url": "",
                },
                {
                  "appName": "Naval Technical Officer",
                  "keyName": "role_power_dis",
                  "key": "scs",
                  "disabled": true,
                  "url": "",
                }
              ],
              "child": false
            },
          ],
          "child": false
        },
        {
          "appName": "Know Your Ship Halifax Class",
          "keyName": "kys_halifax_class",
          "key": "scs",
          "disabled": false,
          "url": "",
          "subkey": "kys_halifax_class_slms",
          "childList": [
            {
              "appName": "Halifax Class Vessel",
              "keyName": "know_ur_ship_kys",
              "key": "scs",
              "disabled": false,
              "logo": "../assets/images/pacific.png",
              "content": {
                "contentHeader": "Ship",
                "content": [
                  "The KYS application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
                ],
                "control": [
                  "place cursor and right-click mouse",
                  "right-click-hold and drag",
                  "left-click and drag",
                  "mouse wheel or controls bottom right",
                  "",
                  "controls menu at the bottom of screen"
                ],
                "position": [
                  { "icon": "menu", "content": "Show or Hide walls" },
                  { "icon": "radio_button_checked", "content": "Home view" },
                  { "icon": "3d-icon", "content": "2D and 3D views" },
                  { "icon": "add", "content": "Zoom in" },
                  { "icon": "remove", "content": "Zoom out" }
                ]
              },
              "url": this.slmsURL + "?appmode=kys"
            },
            {
              "appName": "Compartments",
              "keyName": "compartments",
              "key": "scs",
              // "logo": "../assets/images/KHA_logo.png",
              "disabled": false,
              "content": {
                "contentHeader": "Materiel",
                "content": [
                  "The SCS application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
                ],
                "control": [
                  "place cursor and right-click mouse",
                  "right-click-hold and drag",
                  "left-click and drag",
                  "mouse wheel or controls bottom right",
                  "",
                  "controls menu at the bottom of screen"
                ],
                "position": [
                  { "icon": "menu", "content": "Show or Hide walls" },
                  { "icon": "radio_button_checked", "content": "Home view" },
                  { "icon": "3d-icon", "content": "2D and 3D views" },
                  { "icon": "add", "content": "Zoom in" },
                  { "icon": "remove", "content": "Zoom out" }
                ]
              },
              "url": this.scsURL + "?screenType=materiel_comp"
            },
            // {
            //   "appName": "Compartments",
            //   "keyName": "comp_ur_ship_kys",
            //   "key": "scs",
            //   "disabled": false,
            //   "logo": "../assets/images/pacific.png",
            //   "content": {
            //     "contentHeader": "Ship",
            //     "content": [
            //       "The KYS application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
            //     ],
            //     "control": [
            //       "place cursor and right-click mouse",
            //       "right-click-hold and drag",
            //       "left-click and drag",
            //       "mouse wheel or controls bottom right",
            //       "",
            //       "controls menu at the bottom of screen"
            //     ],
            //     "position": [
            //       { "icon": "menu", "content": "Show or Hide walls" },
            //       { "icon": "radio_button_checked", "content": "Home view" },
            //       { "icon": "3d-icon", "content": "2D and 3D views" },
            //       { "icon": "add", "content": "Zoom in" },
            //       { "icon": "remove", "content": "Zoom out" }
            //     ]
            //   },
            //   "url": this.slmsURL + "?appmode=kys"
            // },
            {
              "appName": "KHAs",
              "keyName": "KHAs_kys",
              "key": "scs",
              "disabled": false,
              "logo": "../assets/images/pacific.png",
              "content": {
                "contentHeader": "Ship",
                "content": [
                  "The KYS application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
                ],
                "control": [
                  "place cursor and right-click mouse",
                  "right-click-hold and drag",
                  "left-click and drag",
                  "mouse wheel or controls bottom right",
                  "",
                  "controls menu at the bottom of screen"
                ],
                "position": [
                  { "icon": "menu", "content": "Show or Hide walls" },
                  { "icon": "radio_button_checked", "content": "Home view" },
                  { "icon": "3d-icon", "content": "2D and 3D views" },
                  { "icon": "add", "content": "Zoom in" },
                  { "icon": "remove", "content": "Zoom out" }
                ]
              },
              "url": this.slmsURL + "?appmode=kys-kha"
            },
            {
              "appName": "Seawater Circulation System",
              "keyName": "kys_sea",
              "key": "scs",
              "disabled": false,
              "logo": "../assets/images/pacific.png",
              "content": {
                "contentHeader": "Ship",
                "content": [
                  "The KYS application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
                ],
                "control": [
                  "place cursor and right-click mouse",
                  "right-click-hold and drag",
                  "left-click and drag",
                  "mouse wheel or controls bottom right",
                  "",
                  "controls menu at the bottom of screen"
                ],
                "position": [
                  { "icon": "menu", "content": "Show or Hide walls" },
                  { "icon": "radio_button_checked", "content": "Home view" },
                  { "icon": "3d-icon", "content": "2D and 3D views" },
                  { "icon": "add", "content": "Zoom in" },
                  { "icon": "remove", "content": "Zoom out" }
                ]
              },
              "url": this.slmsURL + "?appmode=kys-sea"
            },
            {
              "appName": "Show Exit Work",
              "keyName": "kys_exit",
              "key": "scs",
              "disabled": false,
              "logo": "../assets/images/pacific.png",
              "content": {
                "contentHeader": "Ship",
                "content": [
                  "The KYS application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
                ],
                "control": [
                  "place cursor and right-click mouse",
                  "right-click-hold and drag",
                  "left-click and drag",
                  "mouse wheel or controls bottom right",
                  "",
                  "controls menu at the bottom of screen"
                ],
                "position": [
                  { "icon": "menu", "content": "Show or Hide walls" },
                  { "icon": "radio_button_checked", "content": "Home view" },
                  { "icon": "3d-icon", "content": "2D and 3D views" },
                  { "icon": "add", "content": "Zoom in" },
                  { "icon": "remove", "content": "Zoom out" }
                ]
              },
              "url": this.slmsURL + "?appmode=kys-exit"
            },
            {
              "appName": "Courses",
              "keyName": "kys_course",
              "key": "kys_course",
              "disabled": false,
              "url": '',
              "childList": [
                {
                  "appName": "Damage Control Procedures",
                  "keyName": "know_ur_ship_bulid",
                  "key": "scs",
                  "logo": "../assets/images/atlantic.png",
                  "disabled": false,
                  "content": {
                    "contentHeader": "MARLANT",
                    "content": [
                      "The scs application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
                    ],
                    "control": [
                      "place cursor and right-click mouse",
                      "right-click-hold and drag",
                      "left-click and drag",
                      "mouse wheel or controls bottom right",
                      "",
                      "controls menu at the bottom of screen"
                    ],
                    "position": [
                      { "icon": "menu", "content": "Show or Hide walls" },
                      { "icon": "radio_button_checked", "content": "Home view" },
                      { "icon": "3d-icon", "content": "2D and 3D views" },
                      { "icon": "add", "content": "Zoom in" },
                      { "icon": "remove", "content": "Zoom out" }
                    ]
                  },
                  "url": this.slmsURL + "?appmode=network&node=Procedures"
                },
                {
                  "appName": "Compartment Locations or Ship Layout",
                  "keyName": "know_ur_ship_compartment",
                  "key": "scs",
                  "disabled": false,
                  "content": {
                    "contentHeader": "Test DVT plan",
                    "content": [
                      "The scs application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
                    ],
                    "control": [
                      "place cursor and right-click mouse",
                      "right-click-hold and drag",
                      "left-click and drag",
                      "mouse wheel or controls bottom right",
                      "",
                      "controls menu at the bottom of screen"
                    ],
                    "position": [
                      { "icon": "menu", "content": "Show or Hide walls" },
                      { "icon": "radio_button_checked", "content": "Home view" },
                      { "icon": "3d-icon", "content": "2D and 3D views" },
                      { "icon": "add", "content": "Zoom in" },
                      { "icon": "remove", "content": "Zoom out" }
                    ]
                  },
                  "url": this.slmsURL + "?appmode=network&node=Locations"
                },
                {
                  "appName": "Misc",
                  "keyName": "know_ur_ship_misc",
                  "key": "scs",
                  "disabled": false,
                  "content": {
                    "contentHeader": "Cranes",
                    "content": [
                      "The scs application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
                    ],
                    "control": [
                      "place cursor and right-click mouse",
                      "right-click-hold and drag",
                      "left-click and drag",
                      "mouse wheel or controls bottom right",
                      "",
                      "controls menu at the bottom of screen"
                    ],
                    "position": [
                      { "icon": "menu", "content": "Show or Hide walls" },
                      { "icon": "radio_button_checked", "content": "Home view" },
                      { "icon": "3d-icon", "content": "2D and 3D views" },
                      { "icon": "add", "content": "Zoom in" },
                      { "icon": "remove", "content": "Zoom out" }
                    ]
                  },
                  "url": this.slmsURL + "?appmode=network&node=Misc"
                },
              ],
              "child": false,
              "subkey": "kys_course_sub",
            },

            {
              "appName": "KYS Quiz",
              "keyName": "kys_quiz",
              "key": "scs",
              "disabled": false,
              "content": {
                "contentHeader": "Cranes",
                "content": [
                  "The scs application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
                ],
                "control": [
                  "place cursor and right-click mouse",
                  "right-click-hold and drag",
                  "left-click and drag",
                  "mouse wheel or controls bottom right",
                  "",
                  "controls menu at the bottom of screen"
                ],
                "position": [
                  { "icon": "menu", "content": "Show or Hide walls" },
                  { "icon": "radio_button_checked", "content": "Home view" },
                  { "icon": "3d-icon", "content": "2D and 3D views" },
                  { "icon": "add", "content": "Zoom in" },
                  { "icon": "remove", "content": "Zoom out" }
                ]
              },
              "url": this.slmsURL + "?appmode=kys-quiz-mode"
            },
          ],
          "child": false
        },
        {
          "appName": "Save Our Ship",
          "keyName": "sos_save_our_ship",
          "key": "scs",
          "disabled": false,
          "content": {
            "contentHeader": "nodedvt",
            "content": [
              "The scs application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
            ],
            "control": [
              "place cursor and right-click mouse",
              "right-click-hold and drag",
              "left-click and drag",
              "mouse wheel or controls bottom right",
              "",
              "controls menu at the bottom of screen"
            ],
            "position": [
              { "icon": "menu", "content": "Show or Hide walls" },
              { "icon": "radio_button_checked", "content": "Home view" },
              { "icon": "3d-icon", "content": "2D and 3D views" },
              { "icon": "add", "content": "Zoom in" },
              { "icon": "remove", "content": "Zoom out" }
            ]
          },
          "url": this.slmsURL + "?appmode=kys&submode=test&testid=2"
        },

        // {
        //   "appName": "Know Your Ship",
        //   "keyName": "know_ur_ship",
        //   "key": "scs",
        //   "disabled": false,
        //   "url": "",
        //   "subkey": "kys_slms",
        //   "childList": [
        //     {
        //       "appName": "Damage Control Procedures",
        //       "keyName": "know_ur_ship_bulid",
        //       "key": "scs",
        //       "disabled": false,
        //       "url": this.slmsURL + "?appmode=network&node=Procedures",

        //     },
        //     {
        //       "appName": "Compartment Locations or Ship Layout",
        //       "keyName": "know_ur_ship_compartment",
        //       "key": "scs",
        //       "disabled": false,
        //       "url": this.slmsURL + "?appmode=network&node=Locations",
        //     },
        //     {
        //       "appName": "Misc",
        //       "keyName": "know_ur_ship_misc",
        //       "key": "scs",
        //       "disabled": false,
        //       "url": this.slmsURL + "?appmode=network&node=Misc",
        //     },
        //     {
        //       "appName": "KYS",
        //       "keyName": "know_ur_ship_kys",
        //       "key": "scs",
        //       "disabled": false,
        //       "url": this.slmsURL + "?appmode=kys",
        //     }
        //   ],
        //   // "childList": [
        //   //   {
        //   //     "appName": "Halifax",
        //   //     "keyName": "Halifaxbulid",
        //   //     "key": "scs",
        //   //     "disabled": false,
        //   //     "url": "",
        //   //     "subkey": "kys_hal_slms",
        //   //     "childList": [
        //   //       {
        //   //         "appName": "Procedures",
        //   //         "keyName": "bulid",
        //   //         "key": "scs",
        //   //         "disabled": false,
        //   //         "url": this.slmsURL + "?appmode=network&node=Procedures",

        //   //       },
        //   //       {
        //   //         "appName": "Locations",
        //   //         "keyName": "bulid",
        //   //         "key": "scs",
        //   //         "disabled": false,
        //   //         "url": this.slmsURL + "?appmode=network&node=Locations",
        //   //       },
        //   //       {
        //   //         "appName": "Misc",
        //   //         "keyName": "bulid",
        //   //         "key": "scs",
        //   //         "disabled": false,
        //   //         "url": this.slmsURL + "?appmode=network&node=Misc",
        //   //       },
        //   //       {
        //   //         "appName": "KYS",
        //   //         "keyName": "bulid",
        //   //         "key": "scs",
        //   //         "disabled": false,
        //   //         "url": this.slmsURL + "?appmode=kys",
        //   //       }
        //   //     ]
        //   //   },
        //   //   {
        //   //     "appName": "AOPS",
        //   //     "keyName": "power_gen",
        //   //     "key": "scs",
        //   //     "disabled": true,
        //   //     "url": "",
        //   //     "subkey": "power_gen_ic_slms",
        //   //   }
        //   // ],
        //   "child": false
        // },


        {
          "appName": "RADHAZ",
          "keyName": "radhaz",
          "key": "sdms",
          "disabled": false,
          "content": {
            "contentHeader": "Cranes",
            "content": [
              "The SDMS application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
            ],
            "control": [
              "place cursor and right-click mouse",
              "right-click-hold and drag",
              "left-click and drag",
              "mouse wheel or controls bottom right",
              "",
              "controls menu at the bottom of screen"
            ],
            "position": [
              { "icon": "menu", "content": "Show or Hide walls" },
              { "icon": "radio_button_checked", "content": "Home view" },
              { "icon": "3d-icon", "content": "2D and 3D views" },
              { "icon": "add", "content": "Zoom in" },
              { "icon": "remove", "content": "Zoom out" }
            ]
          },
          "url": this.sdmsURL + "?planType=radhaz"
        },
        {
          "appName": "RHIB Demo",
          "keyName": "rhib",
          "key": "slms",
          "disabled": false,
          "url": this.slmsURL + "?appmode=rhib",
        },
        {
          "appName": "Videos",
          "keyName": "slmsvideos",
          "key": "scs",
          "disabled": false,
          "url": "",
          "subkey": "videos_slms",
          "childList": [
            {
              "appName": "RQS3 Diesel Generator",
              "keyName": "video_rqs3",
              "key": "scs",
              "disabled": false,
              "url": "https://navy.datifex.com/RQS3_Diesel_Generator.mp4",
            },
            {
              "appName": "Mechanical Specialist LS Diesel Generator",
              "keyName": "video_mechanical",
              "key": "scs",
              "disabled": false,
              "url": "https://navy.datifex.com/Mechanical_Specialist_LS_Diesel_Generator.mp4",
            },
            {
              "appName": "Seawater Circulation System",
              "keyName": "video_seawater",
              "key": "scs",
              "disabled": false,
              "url": "https://navy.datifex.com/Seawater_Circulation_System.mp4",
            },
            {
              "appName": "Course Editor",
              "keyName": "video_course",
              "key": "scs",
              "disabled": false,
              "url": "https://navy.datifex.com/Course_Editor.mp4",
            },
            {
              "appName": "FleetVIEW Lesson Packages",
              "keyName": "video_fleetview",
              "key": "scs",
              "disabled": false,
              "url": "https://navy.datifex.com/FleetVIEW_Lesson_Packages.mp4",
            }, {
              "appName": "SOS",
              "keyName": "video_sos",
              "key": "scs",
              "disabled": false,
              "url": "https://navy.datifex.com/SOS.mp4",
            }, {
              "appName": "RADHAZ",
              "keyName": "video_radhaz",
              "key": "scs",
              "disabled": false,
              "url": "https://navy.datifex.com/RADHAZ.mp4",
            },
            {
              "appName": "CANSEC 2022",
              "keyName": "video_c2022",
              "key": "scs",
              "disabled": false,
              "url": "https://navy.datifex.com/CANSEC2022.mp4",
            },
            {
              "appName": "CANSEC 2021 Digital Dockyard",
              "keyName": "video_digiDock",
              "key": "scs",
              "disabled": false,
              "url": "https://player.vimeo.com/video/544749432?h=913dc54d29",
            },
            {
              "appName": "CANSEC 2021 NATO Certification",
              "keyName": "video_nato",
              "key": "scs",
              "disabled": false,
              "url": "https://player.vimeo.com/video/543668397?h=bdf2ab269a",
            }
          ],
          "child": false
        },
        // {
        //   "appName": "Damage Control System",
        //   "keyName": "damage_ctrl_ sys",
        //   "key": "scs",
        //   "disabled": true,
        //   "url": "",
        // },
        // {
        //   "appName": "Sea Water Circulation System",
        //   "keyName": "SWCS",
        //   "key": "scs",
        //   "disabled": true,
        //   "url": "",
        // },

        // {
        //   "appName": "Materiel",
        //   "keyName": "materiel",
        //   "key": "scs",
        //   "subkey": "materiel_scs",
        //   "disabled": false,
        //   "childList": [
        //     {
        //       "appName": "Structure",
        //       "keyName": "stru",
        //       "key": "scs",
        //       "disabled": true,
        //       "url": "",
        //       "subkey": "materiel_kha_stru_scs",
        //     },
        //     {
        //       "appName": "Buoyancy",
        //       "keyName": "buoyancy",
        //       "key": "scs",
        //       "disabled": false,
        //       "url": this.scsURL + "?screenType=materiel_kha&KHA=buoyancy",
        //       "subkey": "materiel_kha_buoyancy_scs",
        //     },
        //     {
        //       "appName": "Engineering",
        //       "keyName": "eng",
        //       "key": "scs",
        //       "disabled": true,
        //       "url": "",
        //       "subkey": "materiel_kha_eng_scs",
        //     },
        //     {
        //       "appName": "Seamanship",
        //       "keyName": "seam",
        //       "key": "scs",
        //       "disabled": true,
        //       "url": "",
        //       "subkey": "materiel_kha_seam_scs",
        //     },
        //     {
        //       "appName": "Fire Safety",
        //       "keyName": "fire",
        //       "key": "scs",
        //       "disabled": false,
        //       "url": this.scsURL + "?screenType=materiel_kha&KHA=firesafety",
        //       "subkey": "materiel_kha_fire_scs",
        //     },
        //     {
        //       "appName": "EER",
        //       "keyName": "eer",
        //       "key": "scs",
        //       "disabled": false,
        //       "url": this.scsURL + "?screenType=materiel_kha&KHA=eer",
        //       "subkey": "materiel_kha_eer_scs",
        //     },
        //     {
        //       "appName": "Comms",
        //       "keyName": "comms",
        //       "key": "scs",
        //       "disabled": true,
        //       "url": "",
        //       "subkey": "materiel_kha_comms_scs",
        //     },
        //     {
        //       "appName": "Navigation",
        //       "keyName": "navi",
        //       "key": "scs",
        //       "disabled": true,
        //       "url": "",
        //       "subkey": "materiel_kha_navi_scs",
        //     },
        //     {
        //       "appName": "Dangerous",
        //       "keyName": "dang",
        //       "key": "scs",
        //       "disabled": true,
        //       "url": "",
        //       "subkey": "materiel_kha_dang_scs",
        //     },
        //     {
        //       "appName": "Goods",
        //       "keyName": "good",
        //       "key": "scs",
        //       "disabled": true,
        //       "url": "",
        //       "subkey": "materiel_kha_good_scs",
        //     },
        //   ],
        //   "url": "",
        //   "child": false
        // },
        // {
        //   "appName": "Compartments",
        //   "keyName": "compartments",
        //   "key": "scs",
        //   // "logo": "../assets/images/KHA_logo.png",
        //   "disabled": false,
        //   "content": {
        //     "contentHeader": "Materiel",
        //     "content": [
        //       "The SCS application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
        //     ],
        //     "control": [
        //       "place cursor and right-click mouse",
        //       "right-click-hold and drag",
        //       "left-click and drag",
        //       "mouse wheel or controls bottom right",
        //       "",
        //       "controls menu at the bottom of screen"
        //     ],
        //     "position": [
        //       { "icon": "menu", "content": "Show or Hide walls" },
        //       { "icon": "radio_button_checked", "content": "Home view" },
        //       { "icon": "3d-icon", "content": "2D and 3D views" },
        //       { "icon": "add", "content": "Zoom in" },
        //       { "icon": "remove", "content": "Zoom out" }
        //     ]
        //   },
        //   "url": this.scsURL + "?screenType=materiel_comp"
        // },
        // {
        //   "appName": "ERN Families",
        //   "keyName": "materiel",
        //   "key": "scs",
        //   "subkey": "ern_family",
        //   "disabled": false,
        //   "childList": [
        //     {
        //       "appName": "Halifax",
        //       "keyName": "ern",
        //       "key": "scs",
        //       // "logo": "../assets/images/KHA_logo.png",
        //       "disabled": false,
        //       "subkey": "ern_scs",
        //       "content": {
        //         "contentHeader": "ERN Family Halifax",
        //         "content": [
        //           "The SCS application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
        //         ],
        //         "control": [
        //           "place cursor and right-click mouse",
        //           "right-click-hold and drag",
        //           "left-click and drag",
        //           "mouse wheel or controls bottom right",
        //           "",
        //           "controls menu at the bottom of screen"
        //         ],
        //         "position": [
        //           { "icon": "menu", "content": "Show or Hide walls" },
        //           { "icon": "radio_button_checked", "content": "Home view" },
        //           { "icon": "3d-icon", "content": "2D and 3D views" },
        //           { "icon": "add", "content": "Zoom in" },
        //           { "icon": "remove", "content": "Zoom out" }
        //         ]
        //       },
        //       "childList": [
        //         {
        //           "appName": "Damage Control Systems",
        //           "keyName": "damage",
        //           "key": "scs",
        //           "disabled": false,
        //           "url": this.scsURL + "?screenType=ern_kha&erncategory=ern-damagecontrol",
        //           "subkey": "nsc_ern_damage_scs",
        //         },
        //         {
        //           "appName": "Propulsion",
        //           "keyName": "prop",
        //           "key": "scs",
        //           "disabled": false,
        //           "url": this.scsURL + "?screenType=ern_kha&erncategory=ern-populasion",
        //           "subkey": "nsc_ern_prop_scs",
        //         },
        //         {
        //           "appName": "Electrical Power",
        //           "keyName": "electrical",
        //           "key": "scs",
        //           "disabled": false,
        //           "url": this.scsURL + "?screenType=ern_kha&erncategory=ern-electricalpower",
        //           "subkey": "nsc_ern_electrical_scs",
        //         },
        //         {
        //           "appName": "Marine",
        //           "keyName": "marine",
        //           "key": "scs",
        //           "disabled": false,
        //           "url": this.scsURL + "?screenType=ern_kha&erncategory=ern-marine",
        //           "subkey": "nsc_ern_marine_scs",
        //         },
        //         {
        //           "appName": "Deck & Hull Equipment",
        //           "keyName": "deck",
        //           "key": "scs",
        //           "disabled": false,
        //           "url": this.scsURL + "?screenType=ern_kha&erncategory=ern-deckhull",
        //           "subkey": "nsc_ern_deck_scs",
        //         },
        //         {
        //           "appName": "Hull Systems",
        //           "keyName": "hull",
        //           "key": "scs",
        //           "disabled": false,
        //           "url": this.scsURL + "?screenType=ern_kha&erncategory=ern-hullsystems",
        //           "subkey": "nsc_ern_hull_scs",
        //         },
        //         {
        //           "appName": "HVAC",
        //           "keyName": "hvac",
        //           "key": "scs",
        //           "disabled": false,
        //           "url": this.scsURL + "?screenType=ern_kha&erncategory=ern-hvac",
        //           "subkey": "nsc_ern_hvac_scs",
        //         }

        //       ],
        //       "url": "",
        //       "child": false
        //     },
        //     {
        //       "appName": "AOPS",
        //       "keyName": "aops",
        //       "key": "scs",
        //       // "logo": "../assets/images/KHA_logo.png",
        //       "disabled": false,
        //       "subkey": "aops_scs",
        //       "content": {
        //         "contentHeader": "ERN Family AOPS",
        //         "content": [
        //           "The SCS application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
        //         ],
        //         "control": [
        //           "place cursor and right-click mouse",
        //           "right-click-hold and drag",
        //           "left-click and drag",
        //           "mouse wheel or controls bottom right",
        //           "",
        //           "controls menu at the bottom of screen"
        //         ],
        //         "position": [
        //           { "icon": "menu", "content": "Show or Hide walls" },
        //           { "icon": "radio_button_checked", "content": "Home view" },
        //           { "icon": "3d-icon", "content": "2D and 3D views" },
        //           { "icon": "add", "content": "Zoom in" },
        //           { "icon": "remove", "content": "Zoom out" }
        //         ]
        //       },
        //       "childList": [
        //         {
        //           "appName": "Damage Control Systems",
        //           "keyName": "damage",
        //           "key": "scs",
        //           "disabled": false,
        //           "url": this.scsURL + "?screenType=aops_kha&aopscategory=aops-damagecontrol",
        //           "subkey": "nsc_aops_damage_scs",
        //         },
        //         {
        //           "appName": "Propulsion",
        //           "keyName": "prop",
        //           "key": "scs",
        //           "disabled": false,
        //           "url": this.scsURL + "?screenType=aops_kha&aopscategory=aops-populasion",
        //           "subkey": "nsc_aops_prop_scs",
        //         },
        //         {
        //           "appName": "Electrical Power",
        //           "keyName": "electrical",
        //           "key": "scs",
        //           "disabled": false,
        //           "url": this.scsURL + "?screenType=aops_kha&aopscategory=aops-electricalpower",
        //           "subkey": "nsc_aops_electrical_scs",
        //         },
        //         {
        //           "appName": "Marine",
        //           "keyName": "marine",
        //           "key": "scs",
        //           "disabled": false,
        //           "url": this.scsURL + "?screenType=aops_kha&aopscategory=aops-marine",
        //           "subkey": "nsc_aops_marine_scs",
        //         },
        //         {
        //           "appName": "Deck & Hull Equipment",
        //           "keyName": "deck",
        //           "key": "scs",
        //           "disabled": false,
        //           "url": this.scsURL + "?screenType=aops_kha&aopscategory=aops-deckhull",
        //           "subkey": "nsc_aops_deck_scs",
        //         },
        //         {
        //           "appName": "Hull Systems",
        //           "keyName": "hull",
        //           "key": "scs",
        //           "disabled": false,
        //           "url": this.scsURL + "?screenType=aops_kha&aopscategory=aops-hullsystems",
        //           "subkey": "nsc_aops_hull_scs",
        //         },
        //         {
        //           "appName": "HVAC",
        //           "keyName": "hvac",
        //           "key": "scs",
        //           "disabled": false,
        //           "url": this.scsURL + "?screenType=aops_kha&aopscategory=aops-hvac",
        //           "subkey": "nsc_aops_hvac_scs",
        //         },
        //         {
        //           "appName": "2ND Electrical",
        //           "keyName": "2electrical",
        //           "key": "scs",
        //           "disabled": false,
        //           "url": this.scsURL + "?screenType=aops_kha&aopscategory=aops-2electrical",
        //           "subkey": "nsc_aops_2electrical_scs",
        //         },
        //         {
        //           "appName": "Aircraft Support",
        //           "keyName": "aircraftsupport",
        //           "key": "scs",
        //           "disabled": false,
        //           "url": this.scsURL + "?screenType=aops_kha&aopscategory=aops-aircraftsupport",
        //           "subkey": "nsc_aops_aircraftsupport_scs",
        //         },
        //         {
        //           "appName": "NAVIS",
        //           "keyName": "navis",
        //           "key": "scs",
        //           "disabled": false,
        //           "url": this.scsURL + "?screenType=aops_kha&aopscategory=aops-navis",
        //           "subkey": "nsc_aops_navis_scs",
        //         },
        //         {
        //           "appName": "IMPS",
        //           "keyName": "imps",
        //           "key": "scs",
        //           "disabled": false,
        //           "url": this.scsURL + "?screenType=aops_kha&aopscategory=aops-imps",
        //           "subkey": "nsc_aops_imps_scs",
        //         },
        //         {
        //           "appName": "Workshop",
        //           "keyName": "workshop",
        //           "key": "scs",
        //           "disabled": false,
        //           "url": this.scsURL + "?screenType=aops_kha&aopscategory=aops-workshop",
        //           "subkey": "nsc_aops_workshop_scs",
        //         },
        //         {
        //           "appName": "Communication",
        //           "keyName": "communication",
        //           "key": "scs",
        //           "disabled": false,
        //           "url": this.scsURL + "?screenType=aops_kha&aopscategory=aops-communication",
        //           "subkey": "nsc_aops_communication_scs",
        //         },
        //         {
        //           "appName": "Navysys",
        //           "keyName": "navysys",
        //           "key": "scs",
        //           "disabled": false,
        //           "url": this.scsURL + "?screenType=aops_kha&aopscategory=aops-navysys",
        //           "subkey": "nsc_aops_navysys_scs",
        //         },
        //         {
        //           "appName": "C&C",
        //           "keyName": "c&c",
        //           "key": "scs",
        //           "disabled": false,
        //           "url": this.scsURL + "?screenType=aops_kha&aopscategory=aops-c&c",
        //           "subkey": "nsc_aops_c&c_scs",
        //         },
        //         {
        //           "appName": "Weapons",
        //           "keyName": "weapons",
        //           "key": "scs",
        //           "disabled": false,
        //           "url": this.scsURL + "?screenType=aops_kha&aopscategory=aops-weapons",
        //           "subkey": "nsc_aops_weapons_scs",
        //         },
        //         {
        //           "appName": "Domestic",
        //           "keyName": "domestic",
        //           "key": "scs",
        //           "disabled": false,
        //           "url": this.scsURL + "?screenType=aops_kha&aopscategory=aops-domestic",
        //           "subkey": "nsc_aops_domestic_scs",
        //         }

        //       ],
        //       "url": "",
        //       "child": false
        //     },
        //   ],
        //   "url": "",
        //   "child": false
        // },
        // {
        //   "appName": "Naval Ship Code",
        //   "keyName": "nsc",
        //   "key": "scs",
        //   // "logo": "../assets/images/NSC_logo.png",
        //   "disabled": false,
        //   "subkey": "nsc_scs",
        //   "content": {
        //     "contentHeader": "Naval Ship Code",
        //     "content": [
        //       "The SCS application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
        //     ],
        //     "control": [
        //       "place cursor and right-click mouse",
        //       "right-click-hold and drag",
        //       "left-click and drag",
        //       "mouse wheel or controls bottom right",
        //       "",
        //       "controls menu at the bottom of screen"
        //     ],
        //     "position": [
        //       { "icon": "menu", "content": "Show or Hide walls" },
        //       { "icon": "radio_button_checked", "content": "Home view" },
        //       { "icon": "3d-icon", "content": "2D and 3D views" },
        //       { "icon": "add", "content": "Zoom in" },
        //       { "icon": "remove", "content": "Zoom out" }
        //     ]
        //   },
        //   "childList": [
        //     {
        //       "appName": "Structure",
        //       "keyName": "stru",
        //       "key": "scs",
        //       "disabled": true,
        //       "url": "",
        //       "subkey": "nsc_kha_stru_scs",
        //     },
        //     {
        //       "appName": "Buoyancy",
        //       "keyName": "buoyancy",
        //       "key": "scs",
        //       "disabled": false,
        //       "url": this.scsURL + "?screenType=nsc&KHA=buoyancy",
        //       "subkey": "nsc_kha_buoyancy_scs",
        //     },
        //     {
        //       "appName": "Engineering",
        //       "keyName": "eng",
        //       "key": "scs",
        //       "disabled": true,
        //       "url": "",
        //       "subkey": "nsc_kha_eng_scs",

        //     },
        //     {
        //       "appName": "Seamanship",
        //       "keyName": "seam",
        //       "key": "scs",
        //       "disabled": true,
        //       "url": "",
        //       "subkey": "nsc_kha_seam_scs",
        //     },
        //     {
        //       "appName": "Fire Safety",
        //       "keyName": "fire",
        //       "key": "scs",
        //       "disabled": false,
        //       "url": this.scsURL + "?screenType=nsc&KHA=firesafety",
        //       "subkey": "nsc_kha_fire_scs",
        //     },
        //     {
        //       "appName": "EER",
        //       "keyName": "eer",
        //       "key": "scs",
        //       "disabled": false,
        //       "url": this.scsURL + "?screenType=nsc&KHA=eer",
        //       "subkey": "nsc_kha_eer_scs",
        //     },
        //     {
        //       "appName": "Comms",
        //       "keyName": "comms",
        //       "key": "scs",
        //       "disabled": true,
        //       "url": "",
        //       "subkey": "nsc_kha_comms_scs",
        //     },
        //     {
        //       "appName": "Navigation",
        //       "keyName": "navi",
        //       "key": "scs",
        //       "disabled": true,
        //       "url": "",
        //       "subkey": "nsc_kha_navi_scs",
        //     },
        //     {
        //       "appName": "Dangerous",
        //       "keyName": "dang",
        //       "key": "scs",
        //       "disabled": true,
        //       "url": "",
        //       "subkey": "nsc_kha_dang_scs",
        //     },
        //     {
        //       "appName": "Goods",
        //       "keyName": "good",
        //       "key": "scs",
        //       "disabled": true,
        //       "url": "",
        //       "subkey": "nsc_kha_good_scs",
        //     },

        //   ],
        //   // "childList": [
        //   //   {
        //   //     "appName": "KHA Systems",
        //   //     "keyName": "kha",
        //   //     "key": "scs",
        //   //     "disabled": false,
        //   //     "subkey": "nsc_kha_scs",
        //   //     "url": "",
        //   //     "childList": [
        //   //       {
        //   //         "appName": "Structure",
        //   //         "keyName": "stru",
        //   //         "key": "scs",
        //   //         "disabled": true,
        //   //         "url": "",
        //   //         "subkey": "nsc_kha_stru_scs",
        //   //       },
        //   //       {
        //   //         "appName": "Buoyancy",
        //   //         "keyName": "buoyancy",
        //   //         "key": "scs",
        //   //         "disabled": false,
        //   //         "url": this.scsURL+  "?screenType=nsc&KHA=buoyancy",
        //   //         "subkey": "nsc_kha_buoyancy_scs",
        //   //       },
        //   //       {
        //   //         "appName": "Engineering",
        //   //         "keyName": "eng",
        //   //         "key": "scs",
        //   //         "disabled": true,
        //   //         "url": "",
        //   //         "subkey": "nsc_kha_eng_scs",

        //   //       },
        //   //       {
        //   //         "appName": "Seamanship",
        //   //         "keyName": "seam",
        //   //         "key": "scs",
        //   //         "disabled": true,
        //   //         "url": "",
        //   //         "subkey": "nsc_kha_seam_scs",
        //   //       },
        //   //       {
        //   //         "appName": "Fire Safety",
        //   //         "keyName": "fire",
        //   //         "key": "scs",
        //   //         "disabled": false,
        //   //         "url": this.scsURL+  "?screenType=nsc&KHA=firesafety",
        //   //         "subkey": "nsc_kha_fire_scs",
        //   //       },
        //   //       {
        //   //         "appName": "EER",
        //   //         "keyName": "eer",
        //   //         "key": "scs",
        //   //         "disabled": false,
        //   //         "url": this.scsURL+  "?screenType=nsc&KHA=eer",
        //   //         "subkey": "nsc_kha_eer_scs",
        //   //       },
        //   //       {
        //   //         "appName": "Comms",
        //   //         "keyName": "comms",
        //   //         "key": "scs",
        //   //         "disabled": true,
        //   //         "url": "",
        //   //         "subkey": "nsc_kha_comms_scs",
        //   //       },
        //   //       {
        //   //         "appName": "Navigation",
        //   //         "keyName": "navi",
        //   //         "key": "scs",
        //   //         "disabled": true,
        //   //         "url": "",
        //   //         "subkey": "nsc_kha_navi_scs",
        //   //       },
        //   //       {
        //   //         "appName": "Dangerous",
        //   //         "keyName": "dang",
        //   //         "key": "scs",
        //   //         "disabled": true,
        //   //         "url": "",
        //   //         "subkey": "nsc_kha_dang_scs",
        //   //       },
        //   //       {
        //   //         "appName": "Goods",
        //   //         "keyName": "good",
        //   //         "key": "scs",
        //   //         "disabled": true,
        //   //         "url": "",
        //   //         "subkey": "nsc_kha_good_scs",
        //   //       },

        //   //     ],
        //   //     "child": false
        //   //   },
        //   //   {
        //   //     "appName": "ERN",
        //   //     "key": "scs",
        //   //     "disabled": false,
        //   //     "subkey": "nsc_ern_scs",
        //   //     "url": "",
        //   //     "childList": [
        //   //       {
        //   //         "appName": "Damage Control Systems",
        //   //         "keyName": "damage",
        //   //         "key": "scs",
        //   //         "disabled": false,
        //   //         "url": this.scsURL+  "?screenType=ern_kha&erncategory=ern-damagecontrol",
        //   //         "subkey": "nsc_ern_damage_scs",
        //   //       },
        //   //       {
        //   //         "appName": "Propulsion",
        //   //         "keyName": "prop",
        //   //         "key": "scs",
        //   //         "disabled": false,
        //   //         "url": this.scsURL+  "?screenType=ern_kha&erncategory=ern-populasion",
        //   //         "subkey": "nsc_ern_prop_scs",
        //   //       },
        //   //       {
        //   //         "appName": "Electrical Power",
        //   //         "keyName": "electrical",
        //   //         "key": "scs",
        //   //         "disabled": false,
        //   //         "url": this.scsURL+  "?screenType=ern_kha&erncategory=ern-electricalpower",
        //   //         "subkey": "nsc_ern_electrical_scs",
        //   //       },
        //   //       {
        //   //         "appName": "Marine",
        //   //         "keyName": "marine",
        //   //         "key": "scs",
        //   //         "disabled": false,
        //   //         "url": this.scsURL+  "?screenType=ern_kha&erncategory=ern-marine",
        //   //         "subkey": "nsc_ern_marine_scs",
        //   //       },
        //   //       {
        //   //         "appName": "Deck & Hull Equipment",
        //   //         "keyName": "deck",
        //   //         "key": "scs",
        //   //         "disabled": false,
        //   //         "url": this.scsURL+  "?screenType=ern_kha&erncategory=ern-deckhull",
        //   //         "subkey": "nsc_ern_deck_scs",
        //   //       },
        //   //       {
        //   //         "appName": "Hull Systems",
        //   //         "keyName": "hull",
        //   //         "key": "scs",
        //   //         "disabled": false,
        //   //         "url": this.scsURL+  "?screenType=ern_kha&erncategory=ern-hullsystems",
        //   //         "subkey": "nsc_ern_hull_scs",
        //   //       },
        //   //       {
        //   //         "appName": "HVAC",
        //   //         "keyName": "hvac",
        //   //         "key": "scs",
        //   //         "disabled": false,
        //   //         "url": this.scsURL+  "?screenType=ern_kha&erncategory=ern-hvac",
        //   //         "subkey": "nsc_ern_hvac_scs",
        //   //       }

        //   //     ],
        //   //     "child": false
        //   //   }
        //   // ],
        //   "url": "",
        //   "child": false
        // },
      ],
      "url": "",
      "child": false
    },
    // {
    //   "name": "Save Our Ship",
    //   "fr_name": "ourship",
    //   "key": "ourship",
    //   "childUrl": [],
    //   "url": this.slmsURL + "?appmode=kys&submode=test&testid=2",
    //   "child": false
    // },
    // {
    //   "name": "Know Your Ship",
    //   "fr_name": "yourship",
    //   "key": "yourship",
    //   "childUrl": [
    //     {
    //       "appName": "Halifax Class Vessel",
    //       "keyName": "know_ur_ship_kys",
    //       "key": "scs",
    //       "disabled": false,
    //       "logo": "../assets/images/pacific.png",
    //       "content": {
    //         "contentHeader": "Ship",
    //         "content": [
    //           "The KYS application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
    //         ],
    //         "control": [
    //           "place cursor and right-click mouse",
    //           "right-click-hold and drag",
    //           "left-click and drag",
    //           "mouse wheel or controls bottom right",
    //           "",
    //           "controls menu at the bottom of screen"
    //         ],
    //         "position": [
    //           { "icon": "menu", "content": "Show or Hide walls" },
    //           { "icon": "radio_button_checked", "content": "Home view" },
    //           { "icon": "3d-icon", "content": "2D and 3D views" },
    //           { "icon": "add", "content": "Zoom in" },
    //           { "icon": "remove", "content": "Zoom out" }
    //         ]
    //       },
    //       "url": this.slmsURL + "?appmode=kys"
    //     },
    //     {
    //       "appName": "Compartments",
    //       "keyName": "comp_ur_ship_kys",
    //       "key": "scs",
    //       "disabled": false,
    //       "logo": "../assets/images/pacific.png",
    //       "content": {
    //         "contentHeader": "Ship",
    //         "content": [
    //           "The KYS application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
    //         ],
    //         "control": [
    //           "place cursor and right-click mouse",
    //           "right-click-hold and drag",
    //           "left-click and drag",
    //           "mouse wheel or controls bottom right",
    //           "",
    //           "controls menu at the bottom of screen"
    //         ],
    //         "position": [
    //           { "icon": "menu", "content": "Show or Hide walls" },
    //           { "icon": "radio_button_checked", "content": "Home view" },
    //           { "icon": "3d-icon", "content": "2D and 3D views" },
    //           { "icon": "add", "content": "Zoom in" },
    //           { "icon": "remove", "content": "Zoom out" }
    //         ]
    //       },
    //       "url": this.slmsURL + "?appmode=kys"
    //     },
    //     {
    //       "appName": "KHAs",
    //       "keyName": "KHAs_kys",
    //       "key": "scs",
    //       "disabled": false,
    //       "logo": "../assets/images/pacific.png",
    //       "content": {
    //         "contentHeader": "Ship",
    //         "content": [
    //           "The KYS application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
    //         ],
    //         "control": [
    //           "place cursor and right-click mouse",
    //           "right-click-hold and drag",
    //           "left-click and drag",
    //           "mouse wheel or controls bottom right",
    //           "",
    //           "controls menu at the bottom of screen"
    //         ],
    //         "position": [
    //           { "icon": "menu", "content": "Show or Hide walls" },
    //           { "icon": "radio_button_checked", "content": "Home view" },
    //           { "icon": "3d-icon", "content": "2D and 3D views" },
    //           { "icon": "add", "content": "Zoom in" },
    //           { "icon": "remove", "content": "Zoom out" }
    //         ]
    //       },
    //       "url": this.slmsURL + "?appmode=kys-kha"
    //     },
    //     {
    //       "appName": "Seawater Circulation System",
    //       "keyName": "kys_sea",
    //       "key": "scs",
    //       "disabled": false,
    //       "logo": "../assets/images/pacific.png",
    //       "content": {
    //         "contentHeader": "Ship",
    //         "content": [
    //           "The KYS application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
    //         ],
    //         "control": [
    //           "place cursor and right-click mouse",
    //           "right-click-hold and drag",
    //           "left-click and drag",
    //           "mouse wheel or controls bottom right",
    //           "",
    //           "controls menu at the bottom of screen"
    //         ],
    //         "position": [
    //           { "icon": "menu", "content": "Show or Hide walls" },
    //           { "icon": "radio_button_checked", "content": "Home view" },
    //           { "icon": "3d-icon", "content": "2D and 3D views" },
    //           { "icon": "add", "content": "Zoom in" },
    //           { "icon": "remove", "content": "Zoom out" }
    //         ]
    //       },
    //       "url": this.slmsURL + "?appmode=kys-sea"
    //     },
    //             {
    //       "appName": "Show Exit Work",
    //       "keyName": "kys_exit",
    //       "key": "scs",
    //       "disabled": false,
    //       "logo": "../assets/images/pacific.png",
    //       "content": {
    //         "contentHeader": "Ship",
    //         "content": [
    //           "The KYS application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
    //         ],
    //         "control": [
    //           "place cursor and right-click mouse",
    //           "right-click-hold and drag",
    //           "left-click and drag",
    //           "mouse wheel or controls bottom right",
    //           "",
    //           "controls menu at the bottom of screen"
    //         ],
    //         "position": [
    //           { "icon": "menu", "content": "Show or Hide walls" },
    //           { "icon": "radio_button_checked", "content": "Home view" },
    //           { "icon": "3d-icon", "content": "2D and 3D views" },
    //           { "icon": "add", "content": "Zoom in" },
    //           { "icon": "remove", "content": "Zoom out" }
    //         ]
    //       },
    //       "url": this.slmsURL + "?appmode=kys-exit"
    //     },
    //     {
    //       "appName": "Courses",
    //       "keyName": "kys_course",
    //       "key": "kys_course",
    //       "disabled": false,
    //       "url": '',
    //       "childList": [
    //         {
    //           "appName": "Damage Control Procedures",
    //           "keyName": "know_ur_ship_bulid",
    //           "key": "scs",
    //           "logo": "../assets/images/atlantic.png",
    //           "disabled": false,
    //           "content": {
    //             "contentHeader": "MARLANT",
    //             "content": [
    //               "The scs application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
    //             ],
    //             "control": [
    //               "place cursor and right-click mouse",
    //               "right-click-hold and drag",
    //               "left-click and drag",
    //               "mouse wheel or controls bottom right",
    //               "",
    //               "controls menu at the bottom of screen"
    //             ],
    //             "position": [
    //               { "icon": "menu", "content": "Show or Hide walls" },
    //               { "icon": "radio_button_checked", "content": "Home view" },
    //               { "icon": "3d-icon", "content": "2D and 3D views" },
    //               { "icon": "add", "content": "Zoom in" },
    //               { "icon": "remove", "content": "Zoom out" }
    //             ]
    //           },
    //           "url": this.slmsURL + "?appmode=network&node=Procedures"
    //         },
    //         {
    //           "appName": "Compartment Locations or Ship Layout",
    //           "keyName": "know_ur_ship_compartment",
    //           "key": "scs",
    //           "disabled": false,
    //           "content": {
    //             "contentHeader": "Test DVT plan",
    //             "content": [
    //               "The scs application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
    //             ],
    //             "control": [
    //               "place cursor and right-click mouse",
    //               "right-click-hold and drag",
    //               "left-click and drag",
    //               "mouse wheel or controls bottom right",
    //               "",
    //               "controls menu at the bottom of screen"
    //             ],
    //             "position": [
    //               { "icon": "menu", "content": "Show or Hide walls" },
    //               { "icon": "radio_button_checked", "content": "Home view" },
    //               { "icon": "3d-icon", "content": "2D and 3D views" },
    //               { "icon": "add", "content": "Zoom in" },
    //               { "icon": "remove", "content": "Zoom out" }
    //             ]
    //           },
    //           "url": this.slmsURL + "?appmode=network&node=Locations"
    //         },
    //         {
    //           "appName": "Misc",
    //           "keyName": "know_ur_ship_misc",
    //           "key": "scs",
    //           "disabled": false,
    //           "content": {
    //             "contentHeader": "Cranes",
    //             "content": [
    //               "The scs application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
    //             ],
    //             "control": [
    //               "place cursor and right-click mouse",
    //               "right-click-hold and drag",
    //               "left-click and drag",
    //               "mouse wheel or controls bottom right",
    //               "",
    //               "controls menu at the bottom of screen"
    //             ],
    //             "position": [
    //               { "icon": "menu", "content": "Show or Hide walls" },
    //               { "icon": "radio_button_checked", "content": "Home view" },
    //               { "icon": "3d-icon", "content": "2D and 3D views" },
    //               { "icon": "add", "content": "Zoom in" },
    //               { "icon": "remove", "content": "Zoom out" }
    //             ]
    //           },
    //           "url": this.slmsURL + "?appmode=network&node=Misc"
    //         },
    //       ],
    //       "child": false,
    //       "subkey": "kys_course_sub",
    //     },

    //     {
    //       "appName": "KYS Quiz",
    //       "keyName": "kys_quiz",
    //       "key": "scs",
    //       "disabled": false,
    //       "content": {
    //         "contentHeader": "Cranes",
    //         "content": [
    //           "The scs application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
    //         ],
    //         "control": [
    //           "place cursor and right-click mouse",
    //           "right-click-hold and drag",
    //           "left-click and drag",
    //           "mouse wheel or controls bottom right",
    //           "",
    //           "controls menu at the bottom of screen"
    //         ],
    //         "position": [
    //           { "icon": "menu", "content": "Show or Hide walls" },
    //           { "icon": "radio_button_checked", "content": "Home view" },
    //           { "icon": "3d-icon", "content": "2D and 3D views" },
    //           { "icon": "add", "content": "Zoom in" },
    //           { "icon": "remove", "content": "Zoom out" }
    //         ]
    //       },
    //       "url": this.slmsURL + "?appmode=kys-quiz-mode"
    //     },
    //   ]
    // },
    {
      "name": "Materiel and Certification",
      "fr_name": "Certification intelligente",
      "key": "scs",
      "childUrl": [
        // {
        //   "appName": "Start Menu",
        //   "key": "scs",
        //   "disabled":false,
        //   "content": {
        //     "contentHeader": "Start Menu",
        //     "content": [
        //       "The SCS application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
        //     ],
        //     "control": [
        //       "place cursor and right-click mouse",
        //       "right-click-hold and drag",
        //       "left-click and drag",
        //       "mouse wheel or controls bottom right",
        //       "",
        //       "controls menu at the bottom of screen"
        //     ],
        //     "position": [
        //       { "icon": "menu", "content": "Show or Hide walls" },
        //       { "icon": "radio_button_checked", "content": "Home view" },
        //       { "icon": "3d-icon", "content": "2D and 3D views" },
        //       { "icon": "add", "content": "Zoom in" },
        //       { "icon": "remove", "content": "Zoom out" }
        //     ]
        //   },
        //   "url": this.scsURL+  ""
        // },
        {
          "appName": "Fleet",
          "keyName": "fleets",
          "key": "scs",
          // "logo": "../assets/images/navy.png",
          "disabled": false,
          "content": {
            "contentHeader": "Fleet",
            "content": [
              "The SCS application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
            ],
            "control": [
              "place cursor and right-click mouse",
              "right-click-hold and drag",
              "left-click and drag",
              "mouse wheel or controls bottom right",
              "",
              "controls menu at the bottom of screen"
            ],
            "position": [
              { "icon": "menu", "content": "Show or Hide walls" },
              { "icon": "radio_button_checked", "content": "Home view" },
              { "icon": "3d-icon", "content": "2D and 3D views" },
              { "icon": "add", "content": "Zoom in" },
              { "icon": "remove", "content": "Zoom out" }
            ]
          },
          "url": this.scsURL + "?screenType=FLEET"
        },

        {
          "appName": "Naval Ship Code",
          "keyName": "nsc",
          "key": "scs",
          // "logo": "../assets/images/NSC_logo.png",
          "disabled": false,
          "subkey": "nsc_scs",
          "content": {
            "contentHeader": "Naval Ship Code",
            "content": [
              "The SCS application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
            ],
            "control": [
              "place cursor and right-click mouse",
              "right-click-hold and drag",
              "left-click and drag",
              "mouse wheel or controls bottom right",
              "",
              "controls menu at the bottom of screen"
            ],
            "position": [
              { "icon": "menu", "content": "Show or Hide walls" },
              { "icon": "radio_button_checked", "content": "Home view" },
              { "icon": "3d-icon", "content": "2D and 3D views" },
              { "icon": "add", "content": "Zoom in" },
              { "icon": "remove", "content": "Zoom out" }
            ]
          },
          "childList": [
            {
              "appName": "Structure",
              "keyName": "stru",
              "key": "scs",
              "disabled": true,
              "url": "",
              "subkey": "nsc_kha_stru_scs",
            },
            {
              "appName": "Buoyancy",
              "keyName": "buoyancy",
              "key": "scs",
              "disabled": false,
              "url": this.scsURL + "?screenType=nsc&KHA=buoyancy",
              "subkey": "nsc_kha_buoyancy_scs",
            },
            {
              "appName": "Engineering",
              "keyName": "eng",
              "key": "scs",
              "disabled": true,
              "url": "",
              "subkey": "nsc_kha_eng_scs",

            },
            {
              "appName": "Seamanship",
              "keyName": "seam",
              "key": "scs",
              "disabled": true,
              "url": "",
              "subkey": "nsc_kha_seam_scs",
            },
            {
              "appName": "Fire Safety",
              "keyName": "fire",
              "key": "scs",
              "disabled": false,
              "url": this.scsURL + "?screenType=nsc&KHA=firesafety",
              "subkey": "nsc_kha_fire_scs",
            },
            {
              "appName": "EER",
              "keyName": "eer",
              "key": "scs",
              "disabled": false,
              "url": this.scsURL + "?screenType=nsc&KHA=eer",
              "subkey": "nsc_kha_eer_scs",
            },
            {
              "appName": "Comms",
              "keyName": "navalcomms",
              "key": "scs",
              "disabled": true,
              "url": "",
              "subkey": "nsc_kha_comms_scs",
            },
            {
              "appName": "Navigation",
              "keyName": "navi",
              "key": "scs",
              "disabled": true,
              "url": "",
              "subkey": "nsc_kha_navi_scs",
            },
            {
              "appName": "Dangerous",
              "keyName": "dang",
              "key": "scs",
              "disabled": true,
              "url": "",
              "subkey": "nsc_kha_dang_scs",
            },
            {
              "appName": "Goods",
              "keyName": "good",
              "key": "scs",
              "disabled": true,
              "url": "",
              "subkey": "nsc_kha_good_scs",
            },

          ],
          // "childList": [
          //   {
          //     "appName": "KHA Systems",
          //     "keyName": "kha",
          //     "key": "scs",
          //     "disabled": false,
          //     "subkey": "nsc_kha_scs",
          //     "url": "",
          //     "childList": [
          //       {
          //         "appName": "Structure",
          //         "keyName": "stru",
          //         "key": "scs",
          //         "disabled": true,
          //         "url": "",
          //         "subkey": "nsc_kha_stru_scs",
          //       },
          //       {
          //         "appName": "Buoyancy",
          //         "keyName": "buoyancy",
          //         "key": "scs",
          //         "disabled": false,
          //         "url": this.scsURL+  "?screenType=nsc&KHA=buoyancy",
          //         "subkey": "nsc_kha_buoyancy_scs",
          //       },
          //       {
          //         "appName": "Engineering",
          //         "keyName": "eng",
          //         "key": "scs",
          //         "disabled": true,
          //         "url": "",
          //         "subkey": "nsc_kha_eng_scs",

          //       },
          //       {
          //         "appName": "Seamanship",
          //         "keyName": "seam",
          //         "key": "scs",
          //         "disabled": true,
          //         "url": "",
          //         "subkey": "nsc_kha_seam_scs",
          //       },
          //       {
          //         "appName": "Fire Safety",
          //         "keyName": "fire",
          //         "key": "scs",
          //         "disabled": false,
          //         "url": this.scsURL+  "?screenType=nsc&KHA=firesafety",
          //         "subkey": "nsc_kha_fire_scs",
          //       },
          //       {
          //         "appName": "EER",
          //         "keyName": "eer",
          //         "key": "scs",
          //         "disabled": false,
          //         "url": this.scsURL+  "?screenType=nsc&KHA=eer",
          //         "subkey": "nsc_kha_eer_scs",
          //       },
          //       {
          //         "appName": "Comms",
          //         "keyName": "comms",
          //         "key": "scs",
          //         "disabled": true,
          //         "url": "",
          //         "subkey": "nsc_kha_comms_scs",
          //       },
          //       {
          //         "appName": "Navigation",
          //         "keyName": "navi",
          //         "key": "scs",
          //         "disabled": true,
          //         "url": "",
          //         "subkey": "nsc_kha_navi_scs",
          //       },
          //       {
          //         "appName": "Dangerous",
          //         "keyName": "dang",
          //         "key": "scs",
          //         "disabled": true,
          //         "url": "",
          //         "subkey": "nsc_kha_dang_scs",
          //       },
          //       {
          //         "appName": "Goods",
          //         "keyName": "good",
          //         "key": "scs",
          //         "disabled": true,
          //         "url": "",
          //         "subkey": "nsc_kha_good_scs",
          //       },

          //     ],
          //     "child": false
          //   },
          //   {
          //     "appName": "ERN",
          //     "key": "scs",
          //     "disabled": false,
          //     "subkey": "nsc_ern_scs",
          //     "url": "",
          //     "childList": [
          //       {
          //         "appName": "Damage Control Systems",
          //         "keyName": "damage",
          //         "key": "scs",
          //         "disabled": false,
          //         "url": this.scsURL+  "?screenType=ern_kha&erncategory=ern-damagecontrol",
          //         "subkey": "nsc_ern_damage_scs",
          //       },
          //       {
          //         "appName": "Propulsion",
          //         "keyName": "prop",
          //         "key": "scs",
          //         "disabled": false,
          //         "url": this.scsURL+  "?screenType=ern_kha&erncategory=ern-populasion",
          //         "subkey": "nsc_ern_prop_scs",
          //       },
          //       {
          //         "appName": "Electrical Power",
          //         "keyName": "electrical",
          //         "key": "scs",
          //         "disabled": false,
          //         "url": this.scsURL+  "?screenType=ern_kha&erncategory=ern-electricalpower",
          //         "subkey": "nsc_ern_electrical_scs",
          //       },
          //       {
          //         "appName": "Marine",
          //         "keyName": "marine",
          //         "key": "scs",
          //         "disabled": false,
          //         "url": this.scsURL+  "?screenType=ern_kha&erncategory=ern-marine",
          //         "subkey": "nsc_ern_marine_scs",
          //       },
          //       {
          //         "appName": "Deck & Hull Equipment",
          //         "keyName": "deck",
          //         "key": "scs",
          //         "disabled": false,
          //         "url": this.scsURL+  "?screenType=ern_kha&erncategory=ern-deckhull",
          //         "subkey": "nsc_ern_deck_scs",
          //       },
          //       {
          //         "appName": "Hull Systems",
          //         "keyName": "hull",
          //         "key": "scs",
          //         "disabled": false,
          //         "url": this.scsURL+  "?screenType=ern_kha&erncategory=ern-hullsystems",
          //         "subkey": "nsc_ern_hull_scs",
          //       },
          //       {
          //         "appName": "HVAC",
          //         "keyName": "hvac",
          //         "key": "scs",
          //         "disabled": false,
          //         "url": this.scsURL+  "?screenType=ern_kha&erncategory=ern-hvac",
          //         "subkey": "nsc_ern_hvac_scs",
          //       }

          //     ],
          //     "child": false
          //   }
          // ],
          "url": "",
          "child": false
        },
        {
          "appName": "ERN Family Halifax",
          "keyName": "ern",
          "key": "scs",
          // "logo": "../assets/images/KHA_logo.png",
          "disabled": false,
          "subkey": "ern_scs",
          "content": {
            "contentHeader": "ERN Family Halifax",
            "content": [
              "The SCS application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
            ],
            "control": [
              "place cursor and right-click mouse",
              "right-click-hold and drag",
              "left-click and drag",
              "mouse wheel or controls bottom right",
              "",
              "controls menu at the bottom of screen"
            ],
            "position": [
              { "icon": "menu", "content": "Show or Hide walls" },
              { "icon": "radio_button_checked", "content": "Home view" },
              { "icon": "3d-icon", "content": "2D and 3D views" },
              { "icon": "add", "content": "Zoom in" },
              { "icon": "remove", "content": "Zoom out" }
            ]
          },
          "childList": [
            {
              "appName": "Damage Control Systems",
              "keyName": "damage",
              "key": "scs",
              "disabled": false,
              "url": this.scsURL + "?screenType=ern_kha&erncategory=ern-damagecontrol",
              "subkey": "nsc_ern_damage_scs",
            },
            {
              "appName": "Propulsion",
              "keyName": "prop",
              "key": "scs",
              "disabled": false,
              "url": this.scsURL + "?screenType=ern_kha&erncategory=ern-populasion",
              "subkey": "nsc_ern_prop_scs",
            },
            {
              "appName": "Electrical Power",
              "keyName": "electrical",
              "key": "scs",
              "disabled": false,
              "url": this.scsURL + "?screenType=ern_kha&erncategory=ern-electricalpower",
              "subkey": "nsc_ern_electrical_scs",
            },
            {
              "appName": "Marine",
              "keyName": "marine",
              "key": "scs",
              "disabled": false,
              "url": this.scsURL + "?screenType=ern_kha&erncategory=ern-marine",
              "subkey": "nsc_ern_marine_scs",
            },
            {
              "appName": "Deck & Hull Equipment",
              "keyName": "deck",
              "key": "scs",
              "disabled": false,
              "url": this.scsURL + "?screenType=ern_kha&erncategory=ern-deckhull",
              "subkey": "nsc_ern_deck_scs",
            },
            {
              "appName": "Hull Systems",
              "keyName": "hull",
              "key": "scs",
              "disabled": false,
              "url": this.scsURL + "?screenType=ern_kha&erncategory=ern-hullsystems",
              "subkey": "nsc_ern_hull_scs",
            },
            {
              "appName": "HVAC",
              "keyName": "hvac",
              "key": "scs",
              "disabled": false,
              "url": this.scsURL + "?screenType=ern_kha&erncategory=ern-hvac",
              "subkey": "nsc_ern_hvac_scs",
            }

          ],
          "url": "",
          "child": false
        },
        {
          "appName": "ERN Family AOPS",
          "keyName": "aops",
          "key": "scs",
          // "logo": "../assets/images/KHA_logo.png",
          "disabled": false,
          "subkey": "aops_scs",
          "content": {
            "contentHeader": "ERN Family AOPS",
            "content": [
              "The SCS application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
            ],
            "control": [
              "place cursor and right-click mouse",
              "right-click-hold and drag",
              "left-click and drag",
              "mouse wheel or controls bottom right",
              "",
              "controls menu at the bottom of screen"
            ],
            "position": [
              { "icon": "menu", "content": "Show or Hide walls" },
              { "icon": "radio_button_checked", "content": "Home view" },
              { "icon": "3d-icon", "content": "2D and 3D views" },
              { "icon": "add", "content": "Zoom in" },
              { "icon": "remove", "content": "Zoom out" }
            ]
          },
          "childList": [
            {
              "appName": "Damage Control Systems",
              "keyName": "aopsdamage",
              "key": "scs",
              "disabled": false,
              "url": this.scsURL + "?screenType=aops_kha&aopscategory=aops-damagecontrol",
              "subkey": "nsc_aops_damage_scs",
            },
            {
              "appName": "Propulsion",
              "keyName": "aopsprop",
              "key": "scs",
              "disabled": false,
              "url": this.scsURL + "?screenType=aops_kha&aopscategory=aops-populasion",
              "subkey": "nsc_aops_prop_scs",
            },
            {
              "appName": "Electrical Power",
              "keyName": "aopselectrical",
              "key": "scs",
              "disabled": false,
              "url": this.scsURL + "?screenType=aops_kha&aopscategory=aops-electricalpower",
              "subkey": "nsc_aops_electrical_scs",
            },
            {
              "appName": "Marine",
              "keyName": "aopsmarine",
              "key": "scs",
              "disabled": false,
              "url": this.scsURL + "?screenType=aops_kha&aopscategory=aops-marine",
              "subkey": "nsc_aops_marine_scs",
            },
            {
              "appName": "Deck & Hull Equipment",
              "keyName": "aopsdeck",
              "key": "scs",
              "disabled": false,
              "url": this.scsURL + "?screenType=aops_kha&aopscategory=aops-deckhull",
              "subkey": "nsc_aops_deck_scs",
            },
            {
              "appName": "Hull Systems",
              "keyName": "aopshull",
              "key": "scs",
              "disabled": false,
              "url": this.scsURL + "?screenType=aops_kha&aopscategory=aops-hullsystems",
              "subkey": "nsc_aops_hull_scs",
            },
            {
              "appName": "HVAC",
              "keyName": "aopshvac",
              "key": "scs",
              "disabled": false,
              "url": this.scsURL + "?screenType=aops_kha&aopscategory=aops-hvac",
              "subkey": "nsc_aops_hvac_scs",
            },
            {
              "appName": "2ND Electrical",
              "keyName": "aopscoundselectrical",
              "key": "scs",
              "disabled": false,
              "url": this.scsURL + "?screenType=aops_kha&aopscategory=aops-2electrical",
              "subkey": "nsc_aops_2electrical_scs",
            },
            {
              "appName": "Aircraft Support",
              "keyName": "aircraftsupport",
              "key": "scs",
              "disabled": false,
              "url": this.scsURL + "?screenType=aops_kha&aopscategory=aops-aircraftsupport",
              "subkey": "nsc_aops_aircraftsupport_scs",
            },
            {
              "appName": "NAVIS",
              "keyName": "navis",
              "key": "scs",
              "disabled": false,
              "url": this.scsURL + "?screenType=aops_kha&aopscategory=aops-navis",
              "subkey": "nsc_aops_navis_scs",
            },
            {
              "appName": "IMPS",
              "keyName": "imps",
              "key": "scs",
              "disabled": false,
              "url": this.scsURL + "?screenType=aops_kha&aopscategory=aops-imps",
              "subkey": "nsc_aops_imps_scs",
            },
            {
              "appName": "Workshop",
              "keyName": "workshop",
              "key": "scs",
              "disabled": false,
              "url": this.scsURL + "?screenType=aops_kha&aopscategory=aops-workshop",
              "subkey": "nsc_aops_workshop_scs",
            },
            {
              "appName": "Communication",
              "keyName": "communication",
              "key": "scs",
              "disabled": false,
              "url": this.scsURL + "?screenType=aops_kha&aopscategory=aops-communication",
              "subkey": "nsc_aops_communication_scs",
            },
            {
              "appName": "Navysys",
              "keyName": "navysys",
              "key": "scs",
              "disabled": false,
              "url": this.scsURL + "?screenType=aops_kha&aopscategory=aops-navysys",
              "subkey": "nsc_aops_navysys_scs",
            },
            {
              "appName": "C&C",
              "keyName": "candc",
              "key": "scs",
              "disabled": false,
              "url": this.scsURL + "?screenType=aops_kha&aopscategory=aops-c&c",
              "subkey": "nsc_aops_c&c_scs",
            },
            {
              "appName": "Weapons",
              "keyName": "weapons",
              "key": "scs",
              "disabled": false,
              "url": this.scsURL + "?screenType=aops_kha&aopscategory=aops-weapons",
              "subkey": "nsc_aops_weapons_scs",
            },
            {
              "appName": "Domestic",
              "keyName": "domestic",
              "key": "scs",
              "disabled": false,
              "url": this.scsURL + "?screenType=aops_kha&aopscategory=aops-domestic",
              "subkey": "nsc_aops_domestic_scs",
            }

          ],
          "url": "",
          "child": false
        },
        {
          "appName": "Materiel",
          "keyName": "materiel",
          // "logo": "../assets/images/KHA_logo.png",
          "key": "scs",
          "subkey": "materiel_scs",
          "disabled": false,
          "content": {
            "contentHeader": "Fleet maintenance",
            "content": [
              "The SDMS application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
            ],
            "control": [
              "place cursor and right-click mouse",
              "right-click-hold and drag",
              "left-click and drag",
              "mouse wheel or controls bottom right",
              "",
              "controls menu at the bottom of screen"
            ],
            "position": [
              { "icon": "menu", "content": "Show or Hide walls" },
              { "icon": "radio_button_checked", "content": "Home view" },
              { "icon": "3d-icon", "content": "2D and 3D views" },
              { "icon": "add", "content": "Zoom in" },
              { "icon": "remove", "content": "Zoom out" }
            ]
          },
          "childList": [
            {
              "appName": "Structure",
              "keyName": "materielstru",
              "key": "scs",
              "disabled": true,
              "url": "",
              "subkey": "materiel_kha_stru_scs",
            },
            {
              "appName": "Buoyancy",
              "keyName": "materielbuoyancy",
              "key": "scs",
              "disabled": false,
              "url": this.scsURL + "?screenType=materiel_kha&KHA=buoyancy",
              "subkey": "materiel_kha_buoyancy_scs",
            },
            {
              "appName": "Engineering",
              "keyName": "materieleng",
              "key": "scs",
              "disabled": true,
              "url": "",
              "subkey": "materiel_kha_eng_scs",
            },
            {
              "appName": "Seamanship",
              "keyName": "materielseam",
              "key": "scs",
              "disabled": true,
              "url": "",
              "subkey": "materiel_kha_seam_scs",
            },
            {
              "appName": "Fire Safety",
              "keyName": "materielfire",
              "key": "scs",
              "disabled": false,
              "url": this.scsURL + "?screenType=materiel_kha&KHA=firesafety",
              "subkey": "materiel_kha_fire_scs",
            },
            {
              "appName": "EER",
              "keyName": "materieleer",
              "key": "scs",
              "disabled": false,
              "url": this.scsURL + "?screenType=materiel_kha&KHA=eer",
              "subkey": "materiel_kha_eer_scs",
            },
            {
              "appName": "Comms",
              "keyName": "materielcomms",
              "key": "scs",
              "disabled": true,
              "url": "",
              "subkey": "materiel_kha_comms_scs",
            },
            {
              "appName": "Navigation",
              "keyName": "materielnavi",
              "key": "scs",
              "disabled": true,
              "url": "",
              "subkey": "materiel_kha_navi_scs",
            },
            {
              "appName": "Dangerous",
              "keyName": "materieldang",
              "key": "scs",
              "disabled": true,
              "url": "",
              "subkey": "materiel_kha_dang_scs",
            },
            {
              "appName": "Goods",
              "keyName": "materielgood",
              "key": "scs",
              "disabled": true,
              "url": "",
              "subkey": "materiel_kha_good_scs",
            },

          ],
          // "childList": [
          //   {
          //     "appName": "KHA Systems",
          //     "keyName": "kha",
          //     "key": "scs",
          //     "disabled": false,
          //     "subkey": "materiel_kha_scs",
          //     "url": "",
          //     "childList": [
          //       {
          //         "appName": "Structure",
          //         "keyName": "stru",
          //         "key": "scs",
          //         "disabled": true,
          //         "url": "",
          //         "subkey": "materiel_kha_stru_scs",
          //       },
          //       {
          //         "appName": "Buoyancy",
          //         "keyName": "buoyancy",
          //         "key": "scs",
          //         "disabled": false,
          //         "url": this.scsURL+  "?screenType=materiel_kha&KHA=buoyancy",
          //         "subkey": "materiel_kha_buoyancy_scs",
          //       },
          //       {
          //         "appName": "Engineering",
          //         "keyName": "eng",
          //         "key": "scs",
          //         "disabled": true,
          //         "url": "",
          //         "subkey": "materiel_kha_eng_scs",
          //       },
          //       {
          //         "appName": "Seamanship",
          //         "keyName": "seam",
          //         "key": "scs",
          //         "disabled": true,
          //         "url": "",
          //         "subkey": "materiel_kha_seam_scs",
          //       },
          //       {
          //         "appName": "Fire Safety",
          //         "keyName": "fire",
          //         "key": "scs",
          //         "disabled": false,
          //         "url": this.scsURL+  "?screenType=materiel_kha&KHA=firesafety",
          //         "subkey": "materiel_kha_fire_scs",
          //       },
          //       {
          //         "appName": "EER",
          //         "keyName": "eer",
          //         "key": "scs",
          //         "disabled": false,
          //         "url": this.scsURL+  "?screenType=materiel_kha&KHA=eer",
          //         "subkey": "materiel_kha_eer_scs",
          //       },
          //       {
          //         "appName": "Comms",
          //         "keyName": "comms",
          //         "key": "scs",
          //         "disabled": true,
          //         "url": "",
          //         "subkey": "materiel_kha_comms_scs",
          //       },
          //       {
          //         "appName": "Navigation",
          //         "keyName": "navi",
          //         "key": "scs",
          //         "disabled": true,
          //         "url": "",
          //         "subkey": "materiel_kha_navi_scs",
          //       },
          //       {
          //         "appName": "Dangerous",
          //         "keyName": "dang",
          //         "key": "scs",
          //         "disabled": true,
          //         "url": "",
          //         "subkey": "materiel_kha_dang_scs",
          //       },
          //       {
          //         "appName": "Goods",
          //         "keyName": "good",
          //         "key": "scs",
          //         "disabled": true,
          //         "url": "",
          //         "subkey": "materiel_kha_good_scs",
          //       },

          //     ],
          //     "child": false
          //   },
          //   {
          //     "appName": "Compartments",
          //     "keyName": "compartments",
          //     "key": "scs",
          //     "subkey": "materiel_comp_scs",
          //     "disabled": false,
          //     "url": this.scsURL+  "?screenType=materiel_commanding_comp"
          //   },
          // ],
          "url": "",
          "child": false
        },
        // {
        //   "appName": "KHA Systems View",
        //   "keyName": "kha",
        //   "key": "scs",
        //   "logo": "../assets/images/KHA_logo.png",
        //   "disabled": false,
        //   "content": {
        //     "contentHeader": "ERN Family Trees",
        //     "content": [
        //       "The SCS application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
        //     ],
        //     "control": [
        //       "place cursor and right-click mouse",
        //       "right-click-hold and drag",
        //       "left-click and drag",
        //       "mouse wheel or controls bottom right",
        //       "",
        //       "controls menu at the bottom of screen"
        //     ],
        //     "position": [
        //       { "icon": "menu", "content": "Show or Hide walls" },
        //       { "icon": "radio_button_checked", "content": "Home view" },
        //       { "icon": "3d-icon", "content": "2D and 3D views" },
        //       { "icon": "add", "content": "Zoom in" },
        //       { "icon": "remove", "content": "Zoom out" }
        //     ]
        //   },
        //   "url": this.scsURL+  "?screenType=materiel_kha"
        // },
        {
          "appName": "Compartments",
          "keyName": "compartments",
          "key": "scs",
          // "logo": "../assets/images/KHA_logo.png",
          "disabled": false,
          "content": {
            "contentHeader": "Materiel",
            "content": [
              "The SCS application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
            ],
            "control": [
              "place cursor and right-click mouse",
              "right-click-hold and drag",
              "left-click and drag",
              "mouse wheel or controls bottom right",
              "",
              "controls menu at the bottom of screen"
            ],
            "position": [
              { "icon": "menu", "content": "Show or Hide walls" },
              { "icon": "radio_button_checked", "content": "Home view" },
              { "icon": "3d-icon", "content": "2D and 3D views" },
              { "icon": "add", "content": "Zoom in" },
              { "icon": "remove", "content": "Zoom out" }
            ]
          },
          "url": this.scsURL + "?screenType=materiel_comp"
        },
        {
          "appName": "Library",
          "keyName": "scslibrary",
          "key": "scs",
          // "logo": "../assets/images/library.png",
          "disabled": false,
          "content": {
            "contentHeader": "Materiel",
            "content": [
              "The SCS application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
            ],
            "control": [
              "place cursor and right-click mouse",
              "right-click-hold and drag",
              "left-click and drag",
              "mouse wheel or controls bottom right",
              "",
              "controls menu at the bottom of screen"
            ],
            "position": [
              { "icon": "menu", "content": "Show or Hide walls" },
              { "icon": "radio_button_checked", "content": "Home view" },
              { "icon": "3d-icon", "content": "2D and 3D views" },
              { "icon": "add", "content": "Zoom in" },
              { "icon": "remove", "content": "Zoom out" }
            ]
          },
          "url": "LIBRARY"
        },
        // {
        //   "appName": "Commanding officer",
        //   "keyName": "commanding",
        //   "key": "scs",
        //   "disabled": false,
        //   "content": {
        //     "contentHeader": "Commanding officer",
        //     "content": [
        //       "The SDMS application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
        //     ],
        //     "control": [
        //       "place cursor and right-click mouse",
        //       "right-click-hold and drag",
        //       "left-click and drag",
        //       "mouse wheel or controls bottom right",
        //       "",
        //       "controls menu at the bottom of screen"
        //     ],
        //     "position": [
        //       { "icon": "menu", "content": "Show or Hide walls" },
        //       { "icon": "radio_button_checked", "content": "Home view" },
        //       { "icon": "3d-icon", "content": "2D and 3D views" },
        //       { "icon": "add", "content": "Zoom in" },
        //       { "icon": "remove", "content": "Zoom out" }
        //     ]
        //   },
        //   "url": this.scsURL+  "?screenType=fleet_commanding"
        // },
        // {
        //   "appName": "Fleet maintenance",
        //   "keyName": "fleet-maintenance",
        //   "key": "scs",
        //   "disabled": false,
        //   "subkey": "fleet_scs",
        //   "content": {
        //     "contentHeader": "Fleet maintenance",
        //     "content": [
        //       "The SDMS application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
        //     ],
        //     "control": [
        //       "place cursor and right-click mouse",
        //       "right-click-hold and drag",
        //       "left-click and drag",
        //       "mouse wheel or controls bottom right",
        //       "",
        //       "controls menu at the bottom of screen"
        //     ],
        //     "position": [
        //       { "icon": "menu", "content": "Show or Hide walls" },
        //       { "icon": "radio_button_checked", "content": "Home view" },
        //       { "icon": "3d-icon", "content": "2D and 3D views" },
        //       { "icon": "add", "content": "Zoom in" },
        //       { "icon": "remove", "content": "Zoom out" }
        //     ]
        //   },
        //   "childList": [
        //     {
        //       "appName": "KHA Systems",
        //       "keyName": "kha",
        //       "key": "scs",
        //       "disabled": false,
        //       "url": this.scsURL+  "?screenType=nsc_kha"
        //     },
        //     {
        //       "appName": "ERNs",
        //       "keyName": "ern",
        //       "key": "scs",
        //       "disabled": false,
        //       "url": this.scsURL+  "?screenType=nsc_ern"
        //     },
        //     // {
        //     //   "appName": "Compartments",
        //     //   "key": "scs",
        //     //   "disabled": false,
        //     //   "url": this.scsURL+  "?screenType=materiel_commanding_comp"
        //     // },
        //   ],

        //   "url": "",
        //   "child": false
        // },
        // {
        //   "appName": "Materiel",
        //   "keyName": "materiel",
        //   "key": "scs",
        //   "subkey": "materiel_scs",
        //   "disabled": false,
        //   "content": {
        //     "contentHeader": "Fleet maintenance",
        //     "content": [
        //       "The SDMS application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
        //     ],
        //     "control": [
        //       "place cursor and right-click mouse",
        //       "right-click-hold and drag",
        //       "left-click and drag",
        //       "mouse wheel or controls bottom right",
        //       "",
        //       "controls menu at the bottom of screen"
        //     ],
        //     "position": [
        //       { "icon": "menu", "content": "Show or Hide walls" },
        //       { "icon": "radio_button_checked", "content": "Home view" },
        //       { "icon": "3d-icon", "content": "2D and 3D views" },
        //       { "icon": "add", "content": "Zoom in" },
        //       { "icon": "remove", "content": "Zoom out" }
        //     ]
        //   },
        //   "childList": [
        //     {
        //       "appName": "KHA Systems",
        //       "keyName": "kha",
        //       "key": "scs",
        //       "disabled": false,
        //       "url": this.scsURL+  "?screenType=materiel_commanding_kha",
        //     },
        //     {
        //       "appName": "ERNs",
        //       "key": "scs",
        //       "disabled": false,
        //       "url": this.scsURL+  "?screenType=nsc_ern",
        //     },
        //     {
        //       "appName": "Compartments",
        //       "keyName": "compartments",
        //       "key": "scs",
        //       "disabled": false,
        //       "url": this.scsURL+  "?screenType=materiel_commanding_comp"
        //     },
        //   ],
        //   "url": "",
        //   "child": false
        // },
        // {
        //   "appName": "Certification officer",
        //   "keyName": "certification",
        //   "key": "scs",
        //   "disabled": false,
        //   "content": {
        //     "contentHeader": "Certification officer",
        //     "content": [
        //       "The SCS application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
        //     ],
        //     "control": [
        //       "place cursor and right-click mouse",
        //       "right-click-hold and drag",
        //       "left-click and drag",
        //       "mouse wheel or controls bottom right",
        //       "",
        //       "controls menu at the bottom of screen"
        //     ],
        //     "position": [
        //       { "icon": "menu", "content": "Show or Hide walls" },
        //       { "icon": "radio_button_checked", "content": "Home view" },
        //       { "icon": "3d-icon", "content": "2D and 3D views" },
        //       { "icon": "add", "content": "Zoom in" },
        //       { "icon": "remove", "content": "Zoom out" }
        //     ]
        //   },
        //   "url": this.scsURL+  "?screenType=NSC_certification"
        // },
        // {
        //   "appName": "Waterfront management",
        //   "keyName": "waterfront-management",
        //   "key": "scs",
        //   "disabled": true,
        //   "content": {
        //     "contentHeader": "Waterfront management",
        //     "content": [
        //       "The SCS application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
        //     ],
        //     "control": [
        //       "place cursor and right-click mouse",
        //       "right-click-hold and drag",
        //       "left-click and drag",
        //       "mouse wheel or controls bottom right",
        //       "",
        //       "controls menu at the bottom of screen"
        //     ],
        //     "position": [
        //       { "icon": "menu", "content": "Show or Hide walls" },
        //       { "icon": "radio_button_checked", "content": "Home view" },
        //       { "icon": "3d-icon", "content": "2D and 3D views" },
        //       { "icon": "add", "content": "Zoom in" },
        //       { "icon": "remove", "content": "Zoom out" }
        //     ]
        //   },
        //   "url": "",
        //   "child": false
        // },
        // {
        //   "appName": "Design Authority (NMRA)",
        //   "keyName": "design-authority",
        //   "key": "scs",
        //   "disabled": true,
        //   "content": {
        //     "contentHeader": "Design Authority (NMRA)",
        //     "content": [
        //       "The SCS application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
        //     ],
        //     "control": [
        //       "place cursor and right-click mouse",
        //       "right-click-hold and drag",
        //       "left-click and drag",
        //       "mouse wheel or controls bottom right",
        //       "",
        //       "controls menu at the bottom of screen"
        //     ],
        //     "position": [
        //       { "icon": "menu", "content": "Show or Hide walls" },
        //       { "icon": "radio_button_checked", "content": "Home view" },
        //       { "icon": "3d-icon", "content": "2D and 3D views" },
        //       { "icon": "add", "content": "Zoom in" },
        //       { "icon": "remove", "content": "Zoom out" }
        //     ]
        //   },
        //   "url": "",
        //   "child": false
        // }
        // {
        //   "appName": "Certification",
        //   "key": "scs",

        //   "content": {
        //     "contentHeader": "Ship View",
        //     "content": [
        //       "The SCS application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
        //     ],
        //     "control": [
        //       "place cursor and right-click mouse",
        //       "right-click-hold and drag",
        //       "left-click and drag",
        //       "mouse wheel or controls bottom right",
        //       "",
        //       "controls menu at the bottom of screen"
        //     ],
        //     "position": [
        //       { "icon": "menu", "content": "Show or Hide walls" },
        //       { "icon": "radio_button_checked", "content": "Home view" },
        //       { "icon": "3d-icon", "content": "2D and 3D views" },
        //       { "icon": "add", "content": "Zoom in" },
        //       { "icon": "remove", "content": "Zoom out" }
        //     ]
        //   },
        //   "url": this.scsURL+  ""
        // }
      ],
      "url": this.scsURL + ""
    },
    {
      "name": "demo models",
      "fr_name": "demo models",
      "key": "demo_models",

      "childUrl": [
        {
          "appName": "Cranes",
          "keyName": "cranes_ship",
          "key": "sdms",
          "disabled": false,
          "subkey": "showcase_crane",
          "content": { "contentHeader": "Cranes" },
          "childList": [
            {
              "appName": "Esquimalt Crane",
              "keyName": "esq-crane",
              "key": "sdms",

              "disabled": false,
              "url": this.sdmsURL + "?appmode=dfxModels&model=Esquimalt-Crane",
              "subkey": "showcase_esq_crane",
            },
            {
              "appName": "S2S Crane",
              "keyName": "s2s-crane",
              "key": "sdms",
              "disabled": false,
              "url": this.sdmsURL + "?appmode=dfxModels&model=S2S-Crane",
              "subkey": "showcase_s2s_crane",

            },
            {
              "appName": "Thor Crane",
              "keyName": "thor-crane",
              "key": "sdms",
              "disabled": false,
              "url": this.sdmsURL + "?appmode=dfxModels&model=Thor-Crane",
              "subkey": "showcase_thor_crane",
            },
            {
              "appName": "EBCO Crane",
              "keyName": "Ebco-Crane",
              "key": "sdms",
              "disabled": false,
              "url": this.sdmsURL + "?appmode=dfxModels&model=Ebco-Crane",
              "subkey": "showcase_ebco_crane",
            },
            {
              "appName": "Kone Crane",
              "keyName": "kone-Crane",
              "key": "sdms",
              "disabled": false,
              "url": this.sdmsURL + "?appmode=dfxModels&model=Kone-Crane",
              "subkey": "showcase_kone_crane",
            },
            {
              "appName": "Halifax Crane",
              "keyName": "halifax-crane",
              "key": "sdms",
              "disabled": false,
              "url": this.sdmsURL + "?appmode=dfxModels&model=Halifax-Crane",
              "subkey": "showcase_halifax_crane",
            },


          ],
          "url": "",
          "child": false
        },
        {
          "appName": "Naval Ships",
          "keyName": "ships",
          "key": "sdms",
          // "logo": "../assets/images/KHA_logo.png",
          "disabled": false,
          "subkey": "showcase_ship",
          "content": { "contentHeader": "Ships" },
          "childList": [
            {
              "appName": "Halifax",
              "keyName": "halifax-ship",
              "key": "sdms",
              "disabled": false,
              "url": this.sdmsURL + "?appmode=dfxModels&model=Halifax",
              "subkey": "showcase_halifax_ship",
            },
            {
              "appName": "AOPS",
              "keyName": "aops-ship",
              "key": "sdms",
              "disabled": false,
              "url": this.sdmsURL + "?appmode=dfxModels&model=AOPS",
              "subkey": "showcase_aops_ship",
            },
            {
              "appName": "AOPS CCG",
              "keyName": "aops-ccg-ship",
              "key": "sdms",
              "disabled": false,
              "url": this.sdmsURL + "?appmode=dfxModels&model=AOPS_CCG",
              "subkey": "showcase_aops_ccg_ship",
            },
            {
              "appName": "Ice Breaker",
              "keyName": "ice-breaker-ship",
              "key": "sdms",
              "disabled": false,
              "url": this.sdmsURL + "?appmode=dfxModels&model=Ice_Breaker",
              "subkey": "showcase_ice_breaker_ship",
            },
            {
              "appName": "CSC",
              "keyName": "csc-ship",
              "key": "sdms",
              "disabled": false,
              "url": this.sdmsURL + "?appmode=dfxModels&model=CSC",
              "subkey": "showcase_csc_ship",
            },
            {
              "appName": "Asterix",
              "keyName": "asterix-ship",
              "key": "sdms",
              "disabled": false,
              "url": this.sdmsURL + "?appmode=dfxModels&model=Asterix",
              "subkey": "showcase_asterix_ship",
            },
            {
              "appName": "Submarine",
              "keyName": "submarine-ship",
              "key": "sdms",
              "disabled": false,
              "url": this.sdmsURL + "?appmode=dfxModels&model=Submarine",
              "subkey": "showcase_submarine_ship",
            },
            {
              "appName": "Boats",
              "keyName": "boats",
              "key": "sdms",
              // "logo": "../assets/images/KHA_logo.png",
              "disabled": false,
              "subkey": "showcase_barge",
              "content": { "contentHeader": "Boats", },
              "childList": [
                {
                  "appName": "TUG",
                  "keyName": "tug-barge",
                  "key": "sdms",
                  "disabled": false,
                  "url": this.sdmsURL + "?appmode=dfxModels&model=TUG",
                  "subkey": "showcase_tug_barge",
                },
                {
                  "appName": "RHIB",
                  "keyName": "rhib-barge",
                  "key": "sdms",
                  "disabled": false,
                  "url": this.sdmsURL + "?appmode=dfxModels&model=RHIB",
                  "subkey": "showcase_rhib_barge",
                },
              ],
              "url": "",
              "child": false
            },
          ],
          "url": "",
          "child": false
        },
        // {
        //   "appName": "Boats",
        //   "keyName": "boats",
        //   "key": "sdms",
        //   // "logo": "../assets/images/KHA_logo.png",
        //   "disabled": false,
        //   "subkey": "showcase_barge",
        //   "content": { "contentHeader": "Boats", },
        //   "childList": [
        //     {
        //       "appName": "TUG",
        //       "keyName": "tug-barge",
        //       "key": "sdms",
        //       "disabled": false,
        //       "url": this.sdmsURL + "?appmode=dfxModels&model=TUG",
        //       "subkey": "showcase_tug_barge",
        //     },
        //     {
        //       "appName": "RHIB",
        //       "keyName": "rhib-barge",
        //       "key": "sdms",
        //       "disabled": false,
        //       "url": this.sdmsURL + "?appmode=dfxModels&model=RHIB",
        //       "subkey": "showcase_rhib_barge",
        //     },
        //   ],
        //   "url": "",
        //   "child": false
        // },
        {
          "appName": "Commercial Ships",
          "keyName": "barges",
          "key": "sdms",
          // "logo": "../assets/images/KHA_logo.png",
          "disabled": false,
          "subkey": "showcase_barges",
          "content": { "contentHeader": "Ships" },
          "childList": [
            {
              "appName": "Box Ship",
              "keyName": "box-ship",
              "key": "sdms",
              "disabled": false,
              "url": this.sdmsURL + "?appmode=dfxModels&model=Barge-01",
              "subkey": "showcase_barge_ship",
            },
            {
              "appName": "Bulk Carrier",
              "keyName": "bulk-carrier",
              "key": "sdms",
              "disabled": false,
              "url": this.sdmsURL + "?appmode=dfxModels&model=Bulk_Carrier",
              "subkey": "showcase_bulk_carrier_ship",
            },
            {
              "appName": "Oil Tanker",
              "keyName": "oil_tanker",
              "key": "sdms",
              "disabled": false,
              "url": this.sdmsURL + "?appmode=dfxModels&model=Tanker",
              "subkey": "showcase_tanker_ship",
            },
          ],
          "url": "",
          "child": false
        },
        {
          "appName": "F35",
          "keyName": "f35",
          "key": "sdms",
          "disabled": false,
          "content": {
            "contentHeader": "Fleet",
          },
          "url": this.sdmsURL + "?appmode=dfxModels&model=F35",
        }
      ],
      "child": false
    },
    // {
    //   "name": "Proof of Concepts",
    //   "fr_name": "Preuve de concepts",
    //   "key": "poc",
    //   "childUrl": [
    //     {
    //       "appName": "Bathymetric Ocean",
    //       "keyName": "bathymetric-ocean",
    //       "key": "oceanApp",
    //       "disabled": false,
    //       "content": {
    //         "contentHeader": "Bathymetric Ocean",
    //         "content": [
    //           "The Proof of Concepts application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
    //         ],
    //         "control": [
    //           "place cursor and right-click mouse",
    //           "right-click-hold and drag",
    //           "left-click and drag",
    //           "mouse wheel or controls bottom right",
    //           "",
    //           "controls menu at the bottom of screen"
    //         ],
    //         "position": [
    //           { "icon": "menu", "content": "Show or Hide walls" },
    //           { "icon": "radio_button_checked", "content": "Home view" },
    //           { "icon": "3d-icon", "content": "2D and 3D views" },
    //           { "icon": "add", "content": "Zoom in" },
    //           { "icon": "remove", "content": "Zoom out" }
    //         ]
    //       },
    //       "url": "http://gc.datifex.com:6121/"
    //     },
    //     {
    //       "appName": "Halifax Digital Twin",
    //       "keyName": "halifax-digital",
    //       "key": "poc",
    //       "disabled": false,
    //       "content": {
    //         "contentHeader": "Halifax Digital Twin",
    //         "content": [
    //           "The Proof of Concepts application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
    //         ],
    //         "control": [
    //           "place cursor and right-click mouse",
    //           "right-click-hold and drag",
    //           "left-click and drag",
    //           "mouse wheel or controls bottom right",
    //           "",
    //           "controls menu at the bottom of screen"
    //         ],
    //         "position": [
    //           { "icon": "menu", "content": "Show or Hide walls" },
    //           { "icon": "radio_button_checked", "content": "Home view" },
    //           { "icon": "3d-icon", "content": "2D and 3D views" },
    //           { "icon": "add", "content": "Zoom in" },
    //           { "icon": "remove", "content": "Zoom out" }
    //         ]
    //       },
    //       "url": "http://gc.datifex.com:6121/"
    //     },
    //     {
    //       "appName": "Remote CBSA Border Facility",
    //       "keyName": "remote-cbsa-border-facility",
    //       "key": "cbsa",
    //       "disabled": false,
    //       "content": {
    //         "contentHeader": "Remote CBSA Border Facility",
    //         "content": [
    //           "The Proof of Concepts application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
    //         ],
    //         "control": [
    //           "place cursor and right-click mouse",
    //           "right-click-hold and drag",
    //           "left-click and drag",
    //           "mouse wheel or controls bottom right",
    //           "",
    //           "controls menu at the bottom of screen"
    //         ],
    //         "position": [
    //           { "icon": "menu", "content": "Show or Hide walls" },
    //           { "icon": "radio_button_checked", "content": "Home view" },
    //           { "icon": "3d-icon", "content": "2D and 3D views" },
    //           { "icon": "add", "content": "Zoom in" },
    //           { "icon": "remove", "content": "Zoom out" }
    //         ]
    //       },
    //       "url": "http://gc.datifex.com:6121/"
    //     },
    //     {
    //       "appName": "Intermodal Terminal",
    //       "keyName": "intermodal-terminal",
    //       "key": "terminal",
    //       "disabled": false,
    //       "content": {
    //         "contentHeader": "Intermodal Terminal",
    //         "content": [
    //           "The Proof of Concepts application consolidates and visualizes data from thousands of aircraft cabin sensors and passenger movement in real-time for researchers, and communication of test results to clients and management."
    //         ],
    //         "control": [
    //           "place cursor and right-click mouse",
    //           "right-click-hold and drag",
    //           "left-click and drag",
    //           "mouse wheel or controls bottom right",
    //           "",
    //           "controls menu at the bottom of screen"
    //         ],
    //         "position": [
    //           { "icon": "menu", "content": "Show or Hide walls" },
    //           { "icon": "radio_button_checked", "content": "Home view" },
    //           { "icon": "3d-icon", "content": "2D and 3D views" },
    //           { "icon": "add", "content": "Zoom in" },
    //           { "icon": "remove", "content": "Zoom out" }
    //         ]
    //       },
    //       "url": "http://gc.datifex.com:6121/"
    //     }
    //   ]
    // },

  ]
  broadcastSubs: Subscription;
  overlapWidth: any = 100;
  userLoginDetailsWindow: boolean = false;
  constructor(private datePipe: DatePipe, private router: Router, private communicationServ: CommunicationService, private userService: UserService, private cdr: ChangeDetectorRef, private translate: TranslateService, private dialog: MatDialog, private languageChange: LanguageService, public configService: ConfigService, private broadcastService: BroadcastService, private authService: MsalService, private http: HttpClient, private chitService: ChitService) {
    if (this.loggedIn) {
      this.translate.addLangs(['en', 'fr']);
      this.translate.setDefaultLang('en');
      this.translate.use('en');
    }
    this.broadcastSubs = this.communicationServ.getInstance().subscribe((data: any) => {
      if (data.src === "main") {
        if (data.event === 'dologin') {
          this.login();
        }
      }
      if (data.src === "header") {
        if (data.event === 'loader') {
          this.loaderState = false
        }
      }
      if (data.src === "navyapp") {
        if (data.event === 'notification') {
          if (data.data) {
            this.getMessageData();
          }
        }
      }
      if (data.src === "comment") {
        if (data.event == "userSocketSendInfo") {
          this.sendUserDetailsInfo(data.data)
        }
      }
    })
    this.overlayWidth();


    // alert(this.isTouchDevice());
  }
  isTouchDevice() {
    return window.ontouchstart !== undefined;
  }
  overlayWidth() {
    if (this.selectedMenu) {
      this.overlapWidth = (window.innerWidth - 740);
    } else {
      this.overlapWidth = (window.innerWidth - 370);
    }
  }

  sendUserDetailsInfo(obj) {
    this.socket.emit('sendInfo', obj);
  }
  getSCSURL() {
    let hostname = window.location.hostname;
    if (hostname === 'localhost') {
      let url = 'http://localhost:4201/';
      // let url = 'https://navyscs.azurewebsites.net/';
      this.userService.scsURL = url;
      return url;
    } else if (hostname === 'navyapp.azurewebsites.net') {
      let url = 'https://navyscs.azurewebsites.net/';
      this.userService.scsURL = url;
      return url;
    } else if (hostname === 'navyapp.canadacentral.cloudapp.azure.com') {
      let url = 'http://navyapp.canadacentral.cloudapp.azure.com:4200/';
      this.userService.scsURL = url;
      return url;
    } else if (hostname === 'navy.datifex.net') {
      let url = 'https://scs.navy.datifex.net/';
      this.userService.scsURL = url;
      return url;
    } else if (hostname === 'navy.datifex.com') {
      let url = 'https://scs.navy.datifex.com/';
      this.userService.scsURL = url;
      return url;
    }
    else if (hostname === 'demo.datifex.com') {
      let url = 'https://scs.demo.datifex.com/';
      this.userService.scsURL = url;
      return url;
    }
  }

  getSDMSURL() {
    let hostname = window.location.hostname;
    if (hostname === 'localhost') {
      let url = 'http://localhost:4201/';
      // let url = 'https://navysdms.azurewebsites.net/';
      this.userService.sdmsURL = url;
      return url;
    } else if (hostname === 'navyapp.azurewebsites.net') {
      let url = 'https://navysdms.azurewebsites.net/';
      this.userService.sdmsURL = url;
      return url;
    } else if (hostname === 'navyapp.canadacentral.cloudapp.azure.com') {
      let url = 'http://navyapp.canadacentral.cloudapp.azure.com:4201/';
      this.userService.sdmsURL = url;
      return url;
    } else if (hostname === 'navy.datifex.net') {
      let url = 'https://sdms.navy.datifex.net/';
      this.userService.sdmsURL = url;
      return url;
    } else if (hostname === 'navy.datifex.com') {
      let url = 'https://sdms.navy.datifex.com/';
      this.userService.sdmsURL = url;
      return url;
    }
    else if (hostname === 'demo.datifex.com') {
      let url = 'https://sdms.demo.datifex.com/';
      this.userService.sdmsURL = url;
      return url;
    }
  }

  getSLMSURL() {
    let hostname = window.location.hostname;
    if (hostname === 'localhost') {
      let url = 'http://localhost:4201/';
      // let url = 'https://slms.navy.datifex.net/';
      this.userService.slmsURL = url;
      return url;
    } else if (hostname === 'navyapp.azurewebsites.net') {
      let url = 'https://slms.navy.datifex.com/';
      this.userService.slmsURL = url;
      return url;
    } else if (hostname === 'navyapp.canadacentral.cloudapp.azure.com') {
      let url = 'http://navyapp.canadacentral.cloudapp.azure.com:4204/';
      this.userService.slmsURL = url;
      return url;
    } else if (hostname === 'navy.datifex.net') {
      let url = 'https://slms.navy.datifex.net/';
      this.userService.slmsURL = url;
      return url;
    } else if (hostname === 'navy.datifex.com') {
      let url = 'https://slms.navy.datifex.com/';
      this.userService.slmsURL = url;
      return url;
    } else if (hostname === 'demo.datifex.com') {
      let url = 'https://slms.demo.datifex.com/';
      this.userService.slmsURL = url;
      return url;
    }
  }

  getNotificationLength() {
    let arrLen = this.notificationList.filter(el => el.message != '');
    return arrLen.length;
  }

  profileDialog() {
    this.dialog.open(ProfilePopup, { data: { profileDetails: this.profileDetails, accountEmail: this.accountEmail } });
  }

  /* * * * *
  * method for communicate event instance with data to access all components
  * * * * * */
  broadcastInfo(data: any) {
    this.communicationServ.getInstance().next(data);
  }
  broadcastIframeSubs: any;
  ngOnInit() {
    //this.openDialog();
    // this.getData()
    // this.getUserActivityInfo();
    var scs_url = 'https://nodesdms.azurewebsites.net/version.json';
    var sdms_url = 'https://nodesdms.azurewebsites.net/version.json';
    //var scs_url = 'http://localhost:4201/assets/appData/version.json';
    //var sdms_url = 'http://localhost:4202/assets/appData/version.json';

    if (this.loggedIn) {
      this.configService.getConfig(scs_url).subscribe((data: {}) => {
        console.log("scs", data);
        this.scsConfig = data['scs'];
      })

      this.configService.getConfig(sdms_url).subscribe((data: {}) => {
        console.log("sdms", data);
        this.sdmsConfig = data['sdms'];
      })
    }

    /**
     * Login scripts 
     */
    let loginSuccessSubscription: Subscription;
    let loginFailureSubscription: Subscription;

    this.isIframe = window !== window.parent && !window.opener;

    this.checkAccount();

    loginSuccessSubscription = this.broadcastService.subscribe('msal:loginSuccess', () => {
      this.checkAccount();
    });

    loginFailureSubscription = this.broadcastService.subscribe('msal:loginFailure', (error) => {
      console.log('Login Fails:', error);
    });

    this.subscriptions.push(loginSuccessSubscription);
    this.subscriptions.push(loginFailureSubscription);

    if (this.loggedIn) {
      this.authService.handleRedirectCallback((authError, response) => {
        if (authError) {
          console.error('Redirect Error: ', authError.errorMessage);
          return;
        }
        console.log('Redirect Success: ', response.accessToken);
      });

      this.authService.setLogger(new Logger((_logLevel, message, _piiEnabled) => {
        console.log('MSAL Logging: ', message);
      }, {
        correlationId: CryptoUtils.createNewGuid(),
        piiLoggingEnabled: false
      }));
    }
    if (window.innerWidth < 1024) {
      this.webView = false;
    } else {
      if (this.isTouchDevice()) {
        this.webView = false;
      } else {
        this.webView = true;
      }
    }

    this.broadcastIframeSubs = this.communicationServ.getIframeInstance()
      .subscribe((data: any) => {
        if (data.iframeConnection === "sdms-connection") {
          if (data.event === 'notification') {
            this.getMessageData();
          }
        }
      })

  }
  broadcastIframeInfo(data: any) {
    this.communicationServ.createIframeInstance(data);
  }

  ngAfterViewInit() {
    if (this.loggedIn) {
      this.translate.addLangs(['en', 'fr']);
      this.translate.setDefaultLang('en');
      this.translate.use('en');
    }
    this.cdr.detectChanges();

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  getAccessToken(cbck) {
    if (!!this.authService.getAccount()) {
      console.log("this.authService", this.authService);
      var accountEmail = this.authService['account']['userName'];
      var userId = this.authService['account']['accountIdentifier'];
      var userName = this.authService['account']['name'];
      let loginInfo = { userId, userName: userName, accountEmail: accountEmail };
      this.userService.getAccessToken(loginInfo).subscribe(data => {
        if (data['accessToken']) {
          this.userService.accessToken = data['accessToken'];
          this.userService.refershToken = data['refershToken'];
          this.chitService.accessToken = data['accessToken'];
          this.chitService.refershToken = data['refershToken'];
          cbck(true);
        } else {
          cbck(false);
        }
      },
        error => {
          console.log(error);
          cbck(false)
        });
    } else {
      cbck(false);
    }
  }
  accountEmail: any = ''
  checkAccount() {
    this.getAccessToken((boo) => {
      if (boo) {
        this.loggedIn = !!this.authService.getAccount();
        this.loggedIn ? this.loadData((data) => {
          console.log("loggedIn: ", data);
        }) : '';
        if (this.loggedIn) {
          this.sideNavigation();
          this.translate.addLangs(['en', 'fr']);
          this.translate.setDefaultLang('en');
          this.translate.use('en');
          this.cdr.detectChanges();
          if (this.authService['account']) {
            // console.log("this.authService['account']", this.authService['account']);
            var accountEmail = this.authService['account']['userName'];
            this.userId = this.authService['account']['accountIdentifier'];
            this.userName = this.authService['account']['name']
            this.accountEmail = accountEmail;
            this.userLoginEmail = this.authService['account']['userName'];
            this.userScreenResolution = screen.width + '*' + screen.height;
            this.init_socket();
            this.getComponenetAcceess(accountEmail);
            this.getRoleAccess(accountEmail);
            this.checkAdmin(accountEmail);
            // this.getMessageData();
            // console.log("window.innerWidth;", window.innerWidth + '*' + window.innerHeight);
            let loginInfo = { userId: this.userId, userName: this.userName, accountEmail: accountEmail, accessToken: this.userService.accessToken, refershToken: this.userService.refershToken };
            // console.log("accessObject : ", loginInfo)
            this.broadcastInfo({ src: 'header', event: 'loginstatus', data: loginInfo, key: this.loggedIn });
          }
        }
      }
    })
  }

  login() {
    const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

    if (isIE) {
      this.authService.loginRedirect();
    } else {
      this.authService.loginPopup().then(_response => {
        //console.log("loginPopup ", response)
      })
        .catch(err => {
          console.log("userCancelledError ", err)
        });
    }
  }

  checkAdmin(userName) {
    var users = [];
    this.userService.getUserData()
      .subscribe((res) => {
        Object.keys(res).forEach(key => {
          users.push(res[key])
        })
        // var result = users.filter(t => t.teamsid.toLowerCase().trim().replaceAll(/\s/g, '') === userName.toLowerCase().trim().replaceAll(/\s/g, ''));
        var result = users.filter(t => (t.teamsid.toLowerCase().trim().replaceAll(/\s/g, '') === userName.toLowerCase().trim().replaceAll(/\s/g, '')) || (t.email.toLowerCase().trim().replaceAll(/\s/g, '') === userName.toLowerCase().trim().replaceAll(/\s/g, '')));

        this.profileDetails = result;
        if (result.length > 0) {
          if (result[0].Admin === 'Y') {
            if(this.userAccessDetails.Suspend === 'N'){
            this.isAdmin = true;
            }else{
              if(!this.userAccessDetails.Suspend){
                this.isAdmin = true;
              }
            }
          }
        }
      });
  }

  /**Set AccessObject for access according to roles**/
  getRoleAccess(userName) {
    var users = [];
    this.userService.getUserData()
      .subscribe((res) => {
        Object.keys(res).forEach(key => {
          users.push(res[key])
        })
        // var result = users.filter(t => t.teamsid.toLowerCase().trim().replaceAll(/\s/g, '') === userName.toLowerCase().trim().replaceAll(/\s/g, ''));
        var result = users.filter(t => (t.teamsid.toLowerCase().trim().replaceAll(/\s/g, '') === userName.toLowerCase().trim().replaceAll(/\s/g, '')) || (t.email.toLowerCase().trim().replaceAll(/\s/g, '') === userName.toLowerCase().trim().replaceAll(/\s/g, '')));
        this.profileDetails = result;

        if (result.length > 0) {
          this.userAccessDetails = result[0];
          // alert("Your Email Id match : " + this.userAccessDetails.email);
          // let obj = Object.assign({},this.userAccessDetails, this.AccessObject);
          this.broadcastInfo({ src: 'header', event: 'profileDetails', data: this.userAccessDetails, key: '' });
          if (result[0].Role === 'OOD') {
            var sd = this.AppUrlList.find(j => j.key === 'sdms');
            this.AccessObject.activity = "Y";
            this.AccessObject.chit = "Y";
          }
          else if (result[0].Role === 'CO' || result[0].Role === 'DPO' || result[0].Role === 'QM' || result[0].Role === 'MSEO' || result[0].Role === 'CSEO' || result[0].Role === 'CCFL' || result[0].Role === 'CCFP' || result[0].Role === 'WFM' || result[0].Role === 'QHM') {
            this.AccessObject.activity = "Y";
          }
        } else {
          // alert("Your Email Id Not match : " + userName + " Please contact our admin");
        }
      });
  }
  userLoginText: any;
  userLoginDetails: any;
  userLoginEmail: any;
  /**Render sidebar data according to user access**/
  getComponenetAcceess(userName) {
    var users = [];
    var default_user = [
      { "_id": "610d3dbfd6b4251d4cc9b220", "email": "raj.t@datifex.com", "SCS": "Y", "SDMS": "Y", "MARTECH": "Y", "MARLANT": "Y", "MARPAC": "Y", "Last Name": "Thulasidoss", "First Name": "Raj", "Rank E": "Civ", "Rank F": "Civ", "Org E": "Datifex, Inc.", "Org F": "Datifex, Inc.", "Role": null, "Cell": "(902) 439-4509", "Location": null, "Language": "E", "Admin": "Y", "hazard": "Y", "aed": "N", "first-aid": "Y", "spill-kits": "N", "evacuation-posters": "N", "kiosk": "Y", "laydown": "Y", "contractors": "N", "portable-generators": "N", "fork-lifts": "N", "cranes": "N", "FMF": "Y", "WFM": "Y" }
    ];
    // ----------------------------------------- working code -------------------    
    this.userService.getUserData()
      .subscribe((response) => {
        users = response;
        // console.log("users=", users);
        var result = users.filter(t => (t.teamsid.toLowerCase().trim().replaceAll(/\s/g, '') === userName.toLowerCase().trim().replaceAll(/\s/g, '')) || (t.email.toLowerCase().trim().replaceAll(/\s/g, '') === userName.toLowerCase().trim().replaceAll(/\s/g, '')));
        this.profileDetails = result;
        if (result.length > 0) {
          this.userAccessDetails = result[0];
          // alert("You are successfully logged in : " + userName);
          this.userLoginDetailsWindow = true;
          this.userLoginDetails = "You are successfully logged in"
        } else {
          console.log("login failed for user", userName);
          this.userAccessDetails = default_user[0];
          // alert("Your Email Id Not match : " + userName + " Please contact our admin");
          this.userLoginDetailsWindow = true;
          // this.userLoginDetails = "Your Email Id Not match Please contact our admin"
        }

        if (this.userAccessDetails) {
          // console.log("userAccessDetails", this.userAccessDetails, this.AppUrlList)
          this.updateMenuAccess(this.userAccessDetails);
          this.broadcastInfo({ src: 'header', event: 'profileDetails', data: this.userAccessDetails, key: '' });
          this.menuAccess();
        }
      },
        error => {
          console.log('Request failed with error')
          console.log(error)
        })
  }
  menuAccess() {
    console.log("menuAccess",this.userAccessDetails,this.AppUrlList)
    if (this.userAccessDetails) {
      if (this.AppUrlList) {
        if(this.userAccessDetails.Suspend === 'Y'){
          this.AppUrlList.forEach((element, key) => {
            element.disabled = true;
          })
        }else{
          this.AppUrlList.forEach((element, key) => {

            if (element.key == 'sdms') {
              if (this.userAccessDetails.SDMS === 'Y') {
                element.disabled = false;
              } else {
                element.disabled = true;
              }
            }
            if (element.key == 'scs') {
              if (this.userAccessDetails.SCS === 'Y') {
                element.disabled = false;
              } else {
                element.disabled = true;
              }
            }
            if (element.key == 'poc') {
              if (this.userAccessDetails.POC === 'Y') {
                element.disabled = false;
              } else {
                element.disabled = true;
              }
            }
            if (element.key == 'slms') {
              if (this.userAccessDetails.SLMS === 'Y') {
                element.disabled = false;
              } else {
                element.disabled = true;
              }
            }
            if (element.key == 'Fleetway') {
              if (this.userAccessDetails.fleetway === 'Y') {
                element.disabled = false;
              } else {
                element.disabled = true;
              }
            }
            if (element.key == 'ourship') {
              if (this.userAccessDetails.ourship === 'Y') {
                element.disabled = false;
              } else {
                element.disabled = true;
              }
            }
            if (element.key == 'yourship') {
              if (this.userAccessDetails.yourship === 'Y') {
                element.disabled = false;
              } else {
                element.disabled = true;
              }
            }
            if (element.key == 'demo_models') {
              if (this.userAccessDetails.demo_models === 'Y') {
                element.disabled = false;
              } else {
                element.disabled = true;
              }
            }
            // console.log("data", element.key);
          });
        }

      }

      this.AppSideNavList = this.AppUrlList;
    }
  }
  allowClearMessage() {
    let emailArr = ['raj.t@datifex.com', 'prabha.r@datifex.com', 'gokul.r@datifex.com', 'vengad.s@datifex.com'];
    return emailArr.some(el => el.toLocaleLowerCase() === this.accountEmail.toLocaleLowerCase())
  }

  chitActivityList = [];
  curUserChitList = []
  getChitData() {
    this.userService.getChitData()
      .subscribe((response) => {
        this.chitActivityList = response;
        this.chitActivityList.forEach(element => {
          if (element.azureIdCtrl) {
            if (element.azureIdCtrl == this.profileDetails[0]['_id']) {
              this.curUserChitList.push(element);
            }
          }
        });
        this.loadServerData();
        console.log("curUserChitList", this.curUserChitList)
      },
        error => {
          console.log('Request failed with error')
          console.log(error)
        })

  }
  notificationList: any = [];
  getMessageData() {
    // this.notificationList = [];
    this.userService.getMessageData()
      .subscribe((response) => {
        console.log("message", response);
        if (response.result == 'success') {
          this.notificationList = response.notifications;
          if (this.notificationList.length > 0) {
            this.notificationList = this.notificationList.filter(data => {
              console.log("notification-issues", data)
              if (data['receiver'] && data['receiver'].length === 0) {
                return data;
              } else if (data['receiver'] && data['receiver'].some(el => el && el['email'] && el['email'].toLowerCase() === this.accountEmail && this.accountEmail.toLowerCase())) {
                return data;
              }
            })
            this.notificationList = this.notificationList.reverse();
          }
        }
      },
        error => {
          console.log('Request failed with error');
          console.log(error)
        })
  }

  clearAllMessage() {
    var r = confirm("Are you sure want to delete the all messages !!");
    if (r == true) {
      this.userService.clearAllMessage()
        .subscribe((response) => {
          if (response['result'] === 'success') {
            this.notificationList = [];
          }
        }, error => {
          console.log('Request failed with error');
          console.log(error)
        })
    }
  }

  deleteMessage(id) {
    var r = confirm("Are you sure want to delete message !!");
    if (r == true) {
      this.userService.deleteMessage(id)
        .subscribe((response) => {
          if (response['result'] === 'success') {
            this.notificationList = this.notificationList.filter(el => el.id !== id);
          }
        }, error => {
          console.log('Request failed with error');
          console.log(error)
        })
    }
  }



  /* * *
  * get meta data from the server
  * * */
  portList = ['cfbhalifax', 'cfbesquimalt'];
  halifaxShipList = [];
  esquimaltShipList = [];
  chitShipList = [];
  loadServerData(cbck = null) {
    let port = '';
    this.portList.forEach(e => {
      this.userService.getShipData('json', { port: e, filename: 'shipObjects' }).then((res) => {
        if (res['result'] != 'failed') {
          if (e == 'cfbhalifax') {
            this.halifaxShipList = res['ships'];
            if (this.loginUserShip.length > 0) {
              this.loginUserShip.forEach(elem => {
                this.halifaxShipList.forEach(element => {
                  if (element.ship) {
                    if (element.ship == elem.vessel) {
                      this.chitShipList.push(element);
                      port = e;
                    }
                  }
                });
              });
            }
          }
          if (e == 'cfbesquimalt') {
            this.esquimaltShipList = res['ships'];
            if (this.loginUserShip.length > 0) {
              this.loginUserShip.forEach(elem => {
                this.esquimaltShipList.forEach(element => {
                  if (element.ship) {
                    if (element.ship == elem.vessel) {
                      this.chitShipList.push(element);
                      port = e;
                    }
                  }
                });
              });
            }
          }

          setTimeout(() => {
            if (port != '') {
              let commandingData = { 'id': this.profileDetails[0]['_id'], 'port': port, 'ship': this.chitShipList };
              this.broadcastInfo({ src: 'header', event: 'commandingShip', data: commandingData });
            } else {
              this.broadcastInfo({ src: 'header', event: 'commandingData', data: "yes", key: "" });
              console.log("No vessel has been assigned to you");
              // let commandingData = { 'id': this.profileDetails[0]['_id'], 'port':'cfbesquimalt', 'ship': this.chitShipList };
              // this.broadcastInfo({ src: 'header', event: 'commandingShip', data: commandingData });
            }
            if (cbck) {
              cbck();
            }
          }, 200);

        } else {
          if (cbck) {
            cbck();
          }
        }
      });
    });
  }

  loginUserShip: any;
  getUserActivityInfo(cbck) {
    this.chitService.getOodData().subscribe(response => {
      var shipLocationMatch = response.filter(t => t.ood_email == this.profileDetails[0].email);
      console.log("shipLocationMatch", shipLocationMatch[0]);
      this.loginUserShip = shipLocationMatch;
      this.loadServerData(() => {
        cbck();
      })
    })
  }

  /* * *
  * get meta data from the server
  * * */
  shipConfig
  locationArry
  loadServerConfigData() {
    this.portList.forEach(e => {
      this.userService.getShipData('json', { port: e, filename: 'shipConfig' }).then((res) => {
        if (res['result'] != 'failed') {
          console.log('shipconfigData' + e, res);
          this.shipConfig = res;
          this.userService.getShipData('json', { port: e, filename: 'shipPosition' }).then((Locations) => {
            if (Locations['result'] != 'failed') {
              this.locationArry = Locations;
            }
          })
          // this.shipMetaData = shipMetaData;
        } else {
          // this.openPublishDialog('metadata not found !!');
        }
      });
    })
  }

  shipDataAssign(data) {
    data.forEach(obj => {
      let location = (obj.location ? obj.location.split(" ").join("").replace("/", "_") : "");
      this.locationArry['position'].forEach(loc => {
        if (location === loc.location) {
          if (this.shipConfig[obj.ship]) {

          }
        }
      })
    })
  }

  updateMenuAccess(userAccess) {
    console.log("userAccess ", userAccess);
    console.log("this.AppUrlList ", this.AppUrlList)
    this.AppUrlList.forEach(mainMenu => {
      if (mainMenu['childUrl']) {
        mainMenu['childUrl'].forEach(childMenu => {
          let keyName = childMenu['keyName'];
          let parent = childMenu['parent'];
          console.log("userAccess[parent]", userAccess[parent]);
          if (!childMenu.disabled) {
            if (parent) {
              childMenu.disabled = userAccess[parent] == 'Y' ? false : (userAccess[parent] == 'N' ? true : childMenu.disabled);
            } else {
              childMenu.disabled = userAccess[keyName] == 'Y' ? false : (userAccess[keyName] == 'N' ? true : childMenu.disabled);
            }
            if (childMenu['childList']) {
              childMenu['childList'].forEach(childList => {
                let childKeyName = childList['keyName'];
                if (!childList.disabled) {
                  childList.disabled = userAccess[childKeyName] == 'Y' ? false : (userAccess[childKeyName] == 'N' ? true : childList.disabled);
                }
              })
            }
          }

        })
      }
    });
  }

  logout() {
    this.authService.logout();
  }

  // Silently request an access token
  // async getAccessToken(): Promise<string> {
  //   let result = await this.authService.acquireTokenSilent(OAuthSettings)
  //     .catch((reason) => {
  //       console.log('Get token failed', JSON.stringify(reason, null, 2));
  //     });
  //   if (result) {
  //     return result.accessToken;
  //   }
  //   return null;
  // }

  // private async getUser(): Promise<User> {
  //   if (!this.loggedIn) return null;

  //   let graphClient = Client.init({
  //     // Initialize the Graph client with an auth
  //     // provider that requests the token from the
  //     // auth service
  //     authProvider: async (done) => {
  //       let token = await this.getAccessToken()
  //         .catch((reason) => {
  //           done(reason, null);
  //         });

  //       if (token) {
  //         done(null, token);
  //       } else {
  //         console.log("Could not get an access token ")
  //         return null;
  //       }
  //     }
  //   });

  //   // Get the user from Graph (GET /me)
  //   let graphUser: MicrosoftGraph.User = await graphClient
  //     .api('/me')
  //     .select('displayName,id')
  //     .get();

  //   let user = new User();
  //   user.userName = graphUser.displayName;
  //   user.userId = graphUser.id;
  //   return user;
  // }

  /**
   * get account detail
   */
  getAccountDetail() {
    this.user = new User();
    this.user.userName = this.authService['account']['name'];
    this.user.userId = this.authService['account']['accountIdentifier'];
  }

  loadData(_callback) {
    var _this = this;
    this.getAccountDetail();
  }

  /* * * * *
 * init socket service
 * * * * * */
  // socketPath = 'http://localhost:3000';
  socketPath = 'https://nodesdms.azurewebsites.net';
  init_socket() {
    console.log('init_socket');
    var _this = this;
    let socketPath = this.socketPath || 'http://localhost:3000';
    if (_this.userId) {
      let socketport = `${socketPath}?userId=${_this.userId}&username=${_this.userName}`;
      _this.socket = io.connect(socketport);
      _this.socket.on('connect', onConnect);
      _this.socket.on('disconnect', onDisconnect);
      _this.socket.on('connect_error', onError);
      _this.socket.on('reconnect_error', onError);

      function onConnect(_evt) {
        console.log("navyapp socket connected!!");
        writeToScreen("CONNECTED");

        _this.socket.on("updateNotification", message => {
          _this.updateNotification(message);
        });
      }
      _this.socket.on("getInfo", message => {
        console.log("*************getInfo", message);
        _this.receiveInfo(message);
      });

      function onDisconnect(_evt) {
        writeToScreen("DISCONNECTED");
        console.log("socket disconnected!!");
      }
      function onError(message) {
        writeToScreen('<span style="color: red;">ERROR:</span> ' + message);
      }
      function writeToScreen(message) {
        var pre = document.createElement("p");
        pre.style.wordWrap = "break-word";
        pre.innerHTML = message;
        //output.appendChild(pre);
      }
    }
  }

  receiveInfo(message) {
    if (message.socket_event == "visitor-notification") {
      this.broadcastIframeInfo({ iframeConnection: 'sdms-connection', event: 'visitor-notification', data: message, key: "" });
    }
  }

  /**
   * method recive info broadcast message through socket
   */
  updateNotification(notifications) {
    console.log(" navyapp updateNotification ");
    this.notificationList = notifications;
    if (this.notificationList.length > 0) {
      this.notificationList = this.notificationList.filter(data => {        
        if (data['receiver'] && data['receiver'].length === 0) {
          return data;
        } else if (data['receiver'] && data['receiver'].some(el => el && el['email'] && el['email'].toLowerCase() === this.accountEmail && this.accountEmail.toLowerCase())) {
          return data;
        }
      })
      this.notificationList = this.notificationList.reverse();
    }
  }

  homeScreen() {
    this.homePage.emit()
  }

  sideNavigation() {
    this.opened = true;
    this.sidenav.toggle();
    this.sideNavigate = !this.sideNavigate;
    this.selectedMenu = '';
    this.overlayWidth();
  }

  menuActive(pageData) {
    if (pageData.childUrl.length > 0) {

      this.selectedMenu = pageData;
      this.childMenu = pageData.childUrl;
      if (window.innerWidth < 768) {
        this.opened = false;
        this.sidenav.toggle();
        this.sideNavigate = !this.sideNavigate;
      }
    } else {
      this.selectedMenu = pageData;
      this.openContent(pageData);
    }
    this.overlayWidth();
  }

  openMenuContent(pageData) {
    // this.getActive(pageData);
    if (pageData.childList) {
      this.getActive(pageData);
    } else {
      this.selectedMenu = pageData;
      this.openContent(pageData);
    }
    this.overlayWidth();
  }
  getActive(menuData) {
    let idnx = this.AppSideNavList.findIndex(e => e.key == this.selectedMenu.key);
    if (idnx > -1) {
      let idx = this.childMenu.findIndex(e => e.keyName == menuData.keyName);
      if (idx > -1) {
        this.AppSideNavList[idnx]['childUrl'][idx]['child'] = !this.AppSideNavList[idnx]['childUrl'][idx]['child'];
      } else {
        this.childMenu.forEach((element, indx) => {
          if (element.childList) {
            let idx = element.childList.findIndex(e => e.keyName == menuData.keyName);
            if (idx > -1) {
              this.AppSideNavList[idnx]['childUrl'][indx]['childList'][idx]['child'] = !this.AppSideNavList[idnx]['childUrl'][indx]['childList'][idx]['child'];
            } else {
              element.childList.forEach((elem, inx) => {
                if (elem.childList) {
                  let idx1 = elem.childList.findIndex(e => e.keyName == menuData.keyName);
                  if (idx1 > -1) {
                    this.AppSideNavList[idnx]['childUrl'][indx]['childList'][inx]['childList'][idx1]['child'] = !this.AppSideNavList[idnx]['childUrl'][indx]['childList'][inx]['childList'][idx1]['child'];
                  } else {
                    elem.childList.forEach((ele, idx) => {
                      if (ele.childList) {
                        let idx2 = ele.childList.findIndex(e => e.keyName == menuData.keyName);
                        if (idx2 > -1) {
                          this.AppSideNavList[idnx]['childUrl'][indx]['childList'][inx]['childList'][idx]['childList'][idx2]['child'] = !this.AppSideNavList[idnx]['childUrl'][indx]['childList'][inx]['childList'][idx]['childList'][idx2]['child'];
                        } else {
                          ele.childList.forEach((el, i) => {
                            if (el.childList) {
                              let idx3 = el.childList.findIndex(e => e.keyName == menuData.keyName);
                              if (idx3 > -1) {
                                this.AppSideNavList[idnx]['childUrl'][indx]['childList'][inx]['childList'][idx]['childList'][i]['child'] = !this.AppSideNavList[idnx]['childUrl'][indx]['childList'][inx]['childList'][idx]['childList'][i]['child'];
                              } else {
                              }
                            }
                          });
                        }
                      }
                    });
                  }
                }
              });
            }
          }
        });
      }

    }


  }
  openAdmin() {
    this.sideNavigate = !this.sideNavigate;
    this.opened = false;
    this.sidenav.toggle();
    this.userLoginInfo = false;
  }
  openContent(e) {
    if (e.keyName != 'visitor_registration' && e.keyName != 'visitor_scan') {
      this.submenuDataList = '';
      this.loaderState = true;
      this.submenuFourthDataList = '';
      this.router.navigate(['/']);
      this.sideNavigate = !this.sideNavigate;
      this.userLoginInfo = false;
      this.selectedMenu = '';
      this.overlayWidth();
      this.opened = false;
      this.sidenav.toggle();
    } else if (e.keyName == 'visitor_registration') {
      this.router.navigate(['/visitor'])
      this.submenuDataList = '';
      this.sideNavigate = !this.sideNavigate;
      this.opened = false;
      this.sidenav.toggle();
      this.userLoginInfo = false;
    } else if (e.keyName == 'visitor_scan') {
      this.router.navigate(['/scanner'])
      this.submenuDataList = '';
      this.sideNavigate = !this.sideNavigate;
      this.opened = false;
      this.sidenav.toggle();
      this.userLoginInfo = false;
    }
    if (e.url == 'commanding') {
      // this.getChitData();
      this.getUserActivityInfo(() => {
        this.openContentPage.emit(e);
      });
    } else if (e.keyName != 'visitor_registration' && e.keyName != "visitor_scan") {
      this.openContentPage.emit(e);
      this.opened = false;
      this.sidenav.toggle();
    }
    if (e.keyName == 'video_course') {
      this.loaderState = false;
    } else if (e.keyName == "video_seawater") {
      this.loaderState = false;
    } else if (e.keyName == "video_mechanical") {
      this.loaderState = false;
    } else if (e.keyName == "video_rqs3") {
      this.loaderState = false;
    } else if (e.keyName == "video_fleetview") {
      this.loaderState = false;
    } else if (e.keyName == "video_sos") {
      this.loaderState = false;
    } else if (e.keyName == "video_radhaz" || e.keyName == "video_c2022" || e.keyName == "video_digiDock" || e.keyName == "video_nato") {
      this.loaderState = false;
    }

  }

  /* * * *
   * search object
   * * * * */
  overlaySidenav() {
    this.sideNavigate = !this.sideNavigate;
    this.sidenav.toggle();
    this.opened = false;
    this.selectedMenu = '';
    this.overlayWidth();
  }

  /* * * *
   * search object
   * * * * */
  searchValue(e) {
    console.log(e.target.value);
    this.search = e.target.value;
  }

  /* * * *01-12-2020
   * search clear
   * * * * */
  searchClear(e) {
    this.search = '';
  }

  leftrightControl(e) {
    if (e == "right") {
      this.LR_Control = "Right";

    }
    if (e == "left") {
      this.LR_Control = "Left";
    }
  }

  openDialog2() {
    const dialogRef = this.dialog.open(HeaderContentDialog, { data: { versionNotes: this.sdmsConfig, versionName: "SDMS" } });
    //    console.log("data=",this.message);
    dialogRef.afterClosed().subscribe(_result => {
      // console.log(`Dialog result: ${result}`);
    });
    dialogRef.disableClose = false;
  }

  openDialog3() {
    const dialogRef = this.dialog.open(HeaderContentDialog, { data: { versionNotes: this.scsConfig, versionName: "SCS" } });
    //    console.log("data=",this.message);
    dialogRef.afterClosed().subscribe(_result => {
      // console.log(`Dialog result: ${result}`);
    });
    dialogRef.disableClose = false;
  }

  // nxtLanguage: any = 'English';
  onChangeLanguage(langName) {
    // let langKey;
    if (langName == 'English') {
      this.languageChange.changeLanguage('fr');
      this.translate.use('fr');
      this.languageSelected = 'French';
      // this.nxtLanguage = 'French';
    }
    if (langName == 'French') {
      this.languageChange.changeLanguage('en');
      this.translate.use('en');
      // this.nxtLanguage = 'English';
      this.languageSelected = 'English';
    }
  }
  getPath(cbck = null, e = null) {
    let path = e.path || (e.composedPath && e.composedPath());
    cbck(path);
  }
  submenuDataList: any = '';
  hoverEnterMenu(e, i) {
    // console.log("enter",e.target.id);
    this.getPath((data) => {
      // console.log("enter",data);
      var elem = data
      if (elem.length > 0) {
        elem.forEach(element => {
          if (element.id != "" && (element.className ? element.className.indexOf('submenuEvent') > -1 : false)) {// (element.className == "submenuEvent mat-list-item" || element.className == "mat-list-item submenuEvent" || element.className == "submenuEvent mat-list-item ng-star-inserted")) {
            //  if()
            this.submenuFourthDataList = '';
            if (!this.childMenu[i].childList) {
              // setTimeout(() => {
              this.submenuDataList = '';

              return
              // }, 0);
            }
            this.childMenu[i].child = true;
            if (this.childMenu[i]) {
              this.submenuDataList = this.childMenu[i];
              var eleId = document.getElementById(element.id)
              if (eleId != null) {
                var rect = eleId.getBoundingClientRect();
              }
              // setTimeout(() => {
              // if (document.getElementById(element.id + '_sub') != null) {
              //   if (window.innerHeight / 1.7 < rect.top) {
              //     document.getElementById(element.id + '_sub').style.bottom = rect.bottom / 6 + 'px';
              //     document.getElementById(element.id + '_sub').style.top = 'unset';
              //   } else {
              //     document.getElementById(element.id + '_sub').style.top = rect.top + 'px';
              //     document.getElementById(element.id + '_sub').style.bottom = 'unset';
              //   }
              //   document.getElementById(element.id + '_sub').style.left = rect.right + 'px';
              // }
              if (document.getElementById(element.id + '_sub') != null) {
                if (window.innerHeight < 650) {
                  if (window.innerHeight / 1.7 < rect.top) {
                    document.getElementById(element.id + '_sub').style.bottom = rect.bottom / 2 + 'px';
                    document.getElementById(element.id + '_sub').style.top = 'unset';
                  } else {
                    document.getElementById(element.id + '_sub').style.top = rect.top + 'px';
                    document.getElementById(element.id + '_sub').style.bottom = 'unset';
                  }
                } else {
                  if (window.innerHeight / 2.5 < rect.top) {
                    document.getElementById(element.id + '_sub').style.bottom = rect.bottom / 1.4 + 'px';
                    document.getElementById(element.id + '_sub').style.top = 'unset';
                  } else {
                    document.getElementById(element.id + '_sub').style.top = rect.top + 'px';
                    document.getElementById(element.id + '_sub').style.bottom = 'unset';
                  }
                }

                document.getElementById(element.id + '_sub').style.left = rect.right + 'px';
              }
            }
          } else if (element.id != "" && (element.className ? element.className.indexOf('subThirdmenuEvent') > -1 : false)) {// else if (element.id != "" && (element.className.indexOf('subThirdmenuEvent')>-1element.className == "subThirdmenuEvent mat-list-item" ||"mat-list-item subThirdmenuEvent" || element.className == "subThirdmenuEvent mat-list-item ng-star-inserted" || element.className == "fourth_submenu ng-star-inserted")) {
            if (!this.submenuDataList.childList[i].childList) {
              // setTimeout(() => {
              this.submenuFourthDataList = '';
              return
              // }, 0);

            }
            this.submenuFourthDataList = this.submenuDataList.childList[i];
            var eleId = document.getElementById(element.id)
            if (eleId != null) {
              var rect = eleId.getBoundingClientRect();
            }
            // setTimeout(() => {
            if (document.getElementById(element.id + '_sub') != null) {
              if (window.innerHeight < 650) {
                if (window.innerHeight / 1.8 < rect.top) {
                  document.getElementById(element.id + '_sub').style.bottom = rect.bottom / 2 + 'px';
                  document.getElementById(element.id + '_sub').style.top = 'unset';
                } else {
                  document.getElementById(element.id + '_sub').style.top = rect.top + 'px';
                  document.getElementById(element.id + '_sub').style.bottom = 'unset';
                }
              } else {
                if (window.innerHeight / 3.5 < rect.top) {
                  document.getElementById(element.id + '_sub').style.bottom = rect.bottom / 1.2 + 'px';
                  document.getElementById(element.id + '_sub').style.top = 'unset';
                } else {
                  document.getElementById(element.id + '_sub').style.top = rect.top / 2 + 'px';
                  document.getElementById(element.id + '_sub').style.bottom = 'unset';
                }
              }

              document.getElementById(element.id + '_sub').style.left = rect.right + 'px';
            }
          } else {
            if (element.id == "sideNav" || element.id == "overlay-sidenav") {
              setTimeout(() => {
                this.submenuDataList = '';
                this.submenuFourthDataList = '';
                return
              }, 100);
            }
          }
        });
      }
    }, e)
  }

  hoverLeaveMenu(e, i) {
    // console.log("leave",e.target.id);
    // this.childMenu[i].child=false;
    // var elem = e.path;
    // var findState = false;
    // // this.submenuDataList='';
    // if (elem.length > 0) {
    //   elem.forEach(element => {
    //     if (element.id != "" && element.className == "submenuEvent mat-list-item") {
    //       // this.childMenu[i].child=true;
    //       // this.submenuDataList = this.childMenu[i];
    //       // console.log("submenuDataList",this.submenuDataList);
    //       // var eleId = document.getElementById(element.id)
    //       // let rect = eleId.getBoundingClientRect();
    //       // console.log(rect)
    //       // document.getElementById(element.id+'_sub').style.top=rect.top+'px';

    //       findState = true;
    //     }

    //   });
    //   if (!findState) {
    //     setTimeout(() => {

    //     }, 2000);

    //     }
    //   }
  }
  getUserProfile(username) {
    return username[0];

  }
  getDateFormat(date) {
    return this.datePipe.transform(date, 'MMMM d, y');
  }
  getDateHours(date) {
    var startCDate: any = new Date()
    var endCDate: any = new Date(date)
    var delta = Math.abs(endCDate - startCDate) / 1000;
    // calculate (and subtract) whole days
    var days = Math.floor(delta / 86400);
    // console.log( "day",delta)
    // calculate (and subtract) whole hours
    var hours = (Math.floor(delta / 3600) % 24);
    var minutes = Math.abs(endCDate.getTime() - startCDate.getTime()) / (1000 * 60) % 60;
    var seconds = Math.abs(endCDate.getTime() - startCDate.getTime()) / (1000) % 60;
    // console.log("hours", days, hours, Math.round(minutes), Math.round(seconds)) 
    if (days > 0) {
      if (days > 3) {
        return days + " " + "Days" + " " + "ago";
      } else {
        if (days > 2) {
          return this.datePipe.transform(date, 'MMMM d, y')
        }
      }
    } else if (hours <= 24 && hours > 0) {
      return hours + " " + "Hours" + " " + "ago";
    } else if (minutes < 60 && minutes > 0) {
      return Math.round(minutes) + " " + "Minutes" + " " + "ago";
    } else if (seconds < 60) {
      return Math.round(seconds) + " " + "Seconds" + " " + "ago";
    }
  }

  notificationView(notify) {
    if (notify.app == 'SDMS') {
      let url = this.sdmsURL + "?appmode=chit&chitId=" + notify.chitId + "&userId=" + this.profileDetails[0]['_id'];
      let data = notify.data;
      // let data = { "key": "sdms", "url": url };
      // this.openContentPage.emit(data);

      if (data.userList) {
        var notificationData = data;
        data.userList.forEach(ele => {
          if (ele.email == this.accountEmail) {
            var approvalStatus = ele.Approval || 0
            this.broadcastInfo({ src: "header", event: "notificationData", data: approvalStatus, notificationData: notificationData })

          }
        })
      }

    }
    if (notify.app == 'scs' && notify['link']) {
      let url = notify['link'];
      let data = { "key": "scs", "url": url };
      this.openContentPage.emit(data);
    }
  }
  webView: any = true;
  @HostListener('window:resize', ['$event']) onResize(event) {
    console.log(event.target.innerWidth, event.target.innerHeight);
    if (innerWidth < 1024) {
      this.webView = false;
    } else {
      if (this.isTouchDevice()) {
        this.webView = false;
      } else {
        this.webView = true;
      }

    }
  }
}

@Component({
  selector: 'header-notes-popup',
  templateUrl: './header-notes-popup.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderContentDialog implements OnInit {
  dialog: any;

  constructor(private router: Router, public dialogRef: MatDialogRef<HeaderContentDialog>, @Inject(MAT_DIALOG_DATA) public message: any) {
    console.log("message=", this.message);
  }

  ngOnInit() {

  }

  cancel() {
    this.dialogRef.close(false);
  }

  continue() {
    this.dialogRef.close(true);
  }
}

// profile 

@Component({
  selector: './profile-popup',
  templateUrl: './profile-popup.html',
  styleUrls: ['./header.component.scss']
})
export class ProfilePopup {
  languageSelected: any = '';
  selectedLogo: boolean = true;
  selectedLogoList: boolean = false;
  pacificCheck: boolean = true;
  atlanticCheck: boolean = false;
  cellNumber: boolean = false;
  phoneNumber: boolean = false;
  cellNumberDefualt: boolean = true;
  phoneNumberDefualt: boolean = true;
  profileDetails: any = '';
  userLoginID: any = "";
  defaultUser = { "_id": "610d3dbfd6b4251d4cc9b220", "email": "default", "SCS": "Y", "SDMS": "Y", "MARTECH": "Y", "MARLANT": "Y", "MARPAC": "Y", "Last Name": "User", "First Name": "Default", "Rank E": "Civ", "Rank F": "Civ", "Org E": "Datifex, Inc.", "Org F": "Datifex, Inc.", "Role": null, "Cell": "(902) 439-4509", "Location": null, "Language": "E", "Admin": "Y", "hazard": "Y", "aed": "N", "first-aid": "Y", "spill-kits": "N", "evacuation-posters": "N", "kiosk": "Y", "laydown": "Y", "contractors": "N", "portable-generators": "N", "fork-lifts": "N", "cranes": "N", "FMF": "Y", "WFM": "Y" }

  // selectedlogo = "un-selected-log";
  @Input() loggedIn: any = false;
  broadcastSubs: Subscription;
  constructor(private router: Router, @Inject(MAT_DIALOG_DATA) public data: HeaderComponent, private userService: UserService, private communicationServ: CommunicationService, private cdr: ChangeDetectorRef, private translate: TranslateService, private dialog: MatDialog, private languageChange: LanguageService, public configService: ConfigService, private broadcastService: BroadcastService, private authService: MsalService, private http: HttpClient) {
    let Lan;
    if (data.profileDetails.length > 0) {
      Lan = data.profileDetails[0];
      this.profileDetails = data.profileDetails[0];
    } else {
      Lan = this.defaultUser
      this.profileDetails.this.defaultUser;
    }
    this.userPin = this.profileDetails['Pin'];
    this.userLoginID = data.accountEmail;

    console.log("Profile data recives", this.profileDetails);
    if (Lan) {
      if (Lan.Language === 'E') {
        this.languageSelected = 'English';
      } else {
        this.languageSelected = 'French';
      }
    }
    // this.languageSelected = this.profileDetails.Language;
    if (this.loggedIn) {
      this.translate.addLangs(['en', 'fr']);
      this.translate.setDefaultLang('en');
      this.translate.use('en');
    }
    this.broadcastSubs = this.communicationServ.getInstance()
      .subscribe((data: any) => {
        if (data.src === "main") {
          if (data.event === 'dologin') {
            this.login();
          }
        }
      })
  }

  /* * * * *
  * method for communicate event instance with data to access all components
  * * * * * */
  broadcastInfo(data: any) {
    this.communicationServ.getInstance().next(data);
  }

  login() {
    const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

    if (isIE) {
      this.authService.loginRedirect();
    } else {
      this.authService.loginPopup();
    }
  }

  logout() {
    this.authService.logout();
  }

  profileChangeSection() {
    this.selectedLogoList = true;
  }
  cellNumberChange() {
    this.cellNumber = true;
    this.cellNumberDefualt = false;
  }
  phoneNumberChange() {
    this.phoneNumber = true;
    this.phoneNumberDefualt = false;
  }
  cancelChange() {
    this.phoneNumber = false;
    this.cellNumber = false;
    this.phoneNumberDefualt = true;
    this.cellNumberDefualt = true;
  }
  selectAtlanticLogo() {
    // this.atlanticCheck = true;
    this.atlanticCheck = !this.atlanticCheck;
  }
  selectPacificLogo() {
    this.pacificCheck = !this.pacificCheck;
  }
  // nxtLanguage: any = 'English';
  onChangeLanguage(langName) {
    // this.languageSelected = this.profileDetails.Language;
    // let langKey;
    if (langName == 'English') {
      this.languageChange.changeLanguage('fr');
      this.translate.use('fr');
      this.languageSelected = 'French';
      // this.nxtLanguage = 'French';
    }
    if (langName == 'French') {
      this.languageChange.changeLanguage('en');
      this.translate.use('en');
      // this.nxtLanguage = 'English';
      this.languageSelected = 'English';
    }
  }

  userPin: any = this.profileDetails['Pin'];
  userPinActive: boolean = true;
  userType: any = 'password';

  editUserPin() {
    this.userPinActive = false;
  }
  editUserPinClose() {
    this.userPinActive = true;
    this.userPin = this.profileDetails['Pin'];
  }
  saveUserPin() {
    this.userPinActive = true;
    this.profileDetails['Pin'] = this.userPin;
    // this.broadcastInfo({ src: 'profile', event: 'pinUpdate', data:this.profileDetails });
    this.updateUser();
  }

  userPinValid
  updateUser() {
    console.log("updateUser", this.profileDetails);
    var formdata = this.profileDetails;
    if (this.userPin) {
      this.userService.updateUserData(formdata['_id'], formdata)
        .subscribe((res) => {
          console.log("updateUserres", res);
          var userData = res;
          if (userData.length > 0) {
            // if(userData[0].Pin==this.userPin){
            delete this.userPinValid;
            delete this.userPin;
            this.dialog.closeAll();
            this.userService.sendReload("reload");
            // }else{
            //   this.userPinValid = "Invalid Pin Number";
            // }
          }
        }, error => {
          console.error('There was an error!');
        })
    } else {
      this.userPinValid = "Please Enter Pin Number";
    }
  }

  userPinValidate(e) {
    console.log("userPinValidate", e);
    delete this.userPinValid;
  }
}
function logout(): (error: any) => void {
  throw new Error('Function not implemented.');
}



