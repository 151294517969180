import { Component, Inject, AfterViewInit, HostListener, OnInit, ChangeDetectorRef } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconRegistry } from "@angular/material/icon";
import { LanguageService } from '../services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { fromEvent } from 'rxjs';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ChitService } from '../services/chit.service';
import { UserService } from '../services/user.service';
import { CommunicationService } from "../services/communication.service";

export interface pageData {
  page: any;
  key: string;
}
export interface ship {
  shipname: string;
}
@Component({
  selector: 'app-navy-app',
  templateUrl: './navy-app.component.html',
  styleUrls: ['./navy-app.component.scss']
})
export class NavyAppComponent implements AfterViewInit {
  searchControl = new FormControl();
  portSelection: any;
  options: ship[] = [
    {
      "shipname": "HMCS Vancouver"
    },
    {
      "shipname": "HMCS Calgary"
    },
    {
      "shipname": "HMCS Regina"
    },
    {
      "shipname": "HMCS Ottawa"
    },
    {
      "shipname": "HMCS Winnipeg"
    },
    {
      "shipname": "HMCS Yellowknife"
    },
    {
      "shipname": "HMCS NANAIMO"
    },
    {
      "shipname": "HMCS Edmonton"
    },
    {
      "shipname": "HMCS Chicoutimi"
    },
    {
      "shipname": "HMCS WHITEHORSE"
    },
    {
      "shipname": "HMNZS Te Mana"
    },
    {
      "shipname": "HMCS WINDSOR"
    },
    {
      "shipname": "HMCS SASKATOON"
    }
  ];
  filteredOptions: Observable<ship[]>;
  shipSearch: any = "";
  shipSearchState: boolean = false;
  loadUrl: any = '';
  homeScreen: boolean = true;
  windowHeight: any;
  windowWidth: any;
  loadScreenImg: any;
  scale = 100;
  top = 0;
  left = 0;
  pageContent: any;
  lang: any = 'en';
  broadcastSubs: any;
  loggedIn = false;
  libraryState: boolean = false;

  planType: any;
  loginInfo: any = {};
  profileInfo: any = {};
  commandingUrl: any;
  broadcastIframeSubs: any;

  constructor(private communicationServ: CommunicationService, private userService: UserService, private chitService: ChitService, private cdr: ChangeDetectorRef, private domSanitizer: DomSanitizer, private matIconRegistry: MatIconRegistry, public dialog: MatDialog, private translate: TranslateService, private language: LanguageService) {
    this.windowWidth = window.innerWidth + 20;
    this.windowHeight = Number(window.innerHeight - 40);
    this.matIconRegistry.addSvgIcon(
      `2d-icon`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/2d-icon.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `3d-icon`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/3d-icon.svg")
    );
    this.language.languageChange.subscribe(props => {
      console.log("language", props);
      this.translate.use(props);
      this.lang = props;     
      this.broadcastIframeInfo({ iframeConnection: 'scs-connection', event: 'Translate', data: props, key: "" });
      this.broadcastIframeInfo({ iframeConnection: 'sdms-connection', event: 'Translate', data: props, key: "" });
    })
  }

  /* * * * *
  * method for communicate event instance with data to access all components
  * * * * * */
  broadcastInfo(data: any) {
    this.communicationServ.getInstance().next(data);
  }

  broadcastIframeInfo(data: any) {
    this.communicationServ.createIframeInstance(data);
  }

  private _filter(value: string): ship[] {
    const filterValue = value.toLowerCase();
    return this.options.filter(option => option.shipname.toLowerCase().includes(filterValue));
  }

  ngOnInit() {
    this.filteredOptions = this.searchControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );

    this.broadcastSubs = this.communicationServ.getInstance()
      .subscribe((data: any) => {
        if (data.src === "header") {
          if (data.event === 'loginstatus') {
            this.loggedIn = data['key'];
            let loginInfo = data['data'];
            if (loginInfo) {
              this.loginInfo = loginInfo;
              // console.log("loginInfo ", loginInfo);           
            }
          }
          if (data.event === 'commandingData') {
            var command = data['data'];
            if (command == 'yes') {
              this.openDialog();
            }
          }
          if (data.event === 'profileDetails') {
            let profileInfo = data['data'];
            if (profileInfo) {
              this.profileInfo = profileInfo;
              console.log("profileInfo ", profileInfo);
              this.chitService.sendProfileData(this.profileInfo);
            }
          }
          if (data.event == 'commandingShip') {
            console.log(data.data);
            this.commandingUrl = this.getSDMSURL() + "?planType=commanding_" + data.data.id + "_" + data.data.port;
          }
          if (data.src === "header") {
            if (data.event === 'notificationData') {
              // this.broadcastInfo({ src: "header", event: "notificationData", data: approvalStatus, notificationData: notificationData })
              this.broadcastIframeInfo({ iframeConnection: 'sdms-connection', event: 'notificationInfo', data: data.data, notificationData: data.notificationData });
            }
          }
        }
      })

    this.broadcastIframeSubs = this.communicationServ.getIframeInstance()
      .subscribe((data: any) => {
        if (data.iframeConnection === "scs-connection") {
          if (data.event === 'scs-init') {
            this.broadcastIframeInfo({ iframeConnection: 'scs-connection', event: 'Translate', data: this.lang, key: "" });
            this.broadcastIframeInfo({ iframeConnection: 'scs-connection', event: 'loginInformation', data: this.loginInfo, key: "" });
            this.broadcastIframeInfo({ iframeConnection: 'scs-connection', event: 'profileInfo', data: this.profileInfo, key: "" });
            this.broadcastInfo({ src: 'header', event: 'loader', data: false });
          }
        }
        if (data.iframeConnection === "sdms-connection") {
          if (data.event === 'sdms-init') {
            this.broadcastIframeInfo({ iframeConnection: 'sdms-connection', event: 'Translate', data: this.lang, key: "" });
            this.broadcastIframeInfo({ iframeConnection: 'sdms-connection', event: 'loginInfo', data: this.loginInfo, key: "" });
            this.broadcastIframeInfo({ iframeConnection: 'sdms-connection', event: 'profileInfo', data: this.profileInfo, key: "" });
            this.broadcastInfo({ src: 'header', event: 'loader', data: false });
            this.broadcastInfo({ src: 'navyapp', event: 'notification', data: true });
          }
        }
        if (data.iframeConnection === "slms-connection") {
          if (data.event === 'slms-init') {
            this.broadcastIframeInfo({ iframeConnection: 'slms-connection', event: 'Translate', data: this.lang, key: "" });
            this.broadcastIframeInfo({ iframeConnection: 'slms-connection', event: 'loginInfo', data: this.loginInfo, key: "" });
            this.broadcastInfo({ src: 'header', event: 'loader', data: false });
          }
        }
      })
  }

  getSDMSURL() {
    let hostname = window.location.hostname;
    if (hostname === 'localhost') {
      let url = 'http://localhost:4202/';   
      // let url = 'https://navysdms.azurewebsites.net/';
      this.userService.sdmsURL = url;
      return url;
    } else if (hostname === 'navyapp.azurewebsites.net') {
      let url = 'https://navysdms.azurewebsites.net/';
      this.userService.sdmsURL = url;
      return url;
    } else if (hostname === 'navyapp.canadacentral.cloudapp.azure.com') {
      let url = 'http://navyapp.canadacentral.cloudapp.azure.com:4201/';
      this.userService.sdmsURL = url;
      return url;
    } else if (hostname === 'navy.datifex.net') {
      let url = 'https://sdms.navy.datifex.net/';
      this.userService.sdmsURL = url;
      return url;
    } else if (hostname === 'demo.datifex.com') {
      let url = 'https://sdms.demo.datifex.com/';
      this.userService.sdmsURL = url;
      return url;
    } else if (hostname === 'navy.datifex.com') {
      let url = 'https://sdms.navy.datifex.com/';
      this.userService.sdmsURL = url;
      return url;
    }
  }

  login() {
    this.broadcastInfo({ src: 'main', event: 'dologin', data: '', key: "" });
  }

  // const dialogRef = this.dialog.open(PageContentDialog, {
  //   width: '800px',
  //   height: 'auto',
  //   data: { page: e['content'], key: this.loadScreenImg }
  // });
  openDialog() {
    const dialogRef = this.dialog.open(PageContentDialog, {
      width: '500px',
      height: '200',
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
  cancel() {
    this.homeScreen = true;
    this.loadUrl = '';
    this.loadScreenImg = '';
  }
  ngAfterViewInit(): void {
    fromEvent(window, 'wheel').subscribe((ev: WheelEvent) => {
      const newScale = this.scale - ev.deltaY * 0.2;
      this.scale = Math.max(newScale, 100);
      this.top = ev.clientY;
      this.left = ev.clientX;
    });
    this.cdr.detectChanges()
  }
  homePage() {
    this.homeScreen = true;
    this.libraryState = false;
    this.shipSearchState = false;
    this.loadUrl = '';
    this.loadScreenImg = '';
  }

  imgClick(event) {
    event.preventDefault();
    return false
  }
  openContentPage(e) {
    console.log("openContentPage", e)

    if (this.pageContent) {
      if (e.key == 'sdms') {
        var iframe = document.getElementById('objWeb');
        if (iframe == null) {
          this.openUrlContent(e);
          return;
        }
        if (e['url'].toLowerCase() == 'library') {
          this.homeScreen = true;
          this.shipSearchState = false;
          this.libraryState = true;
          this.broadcastInfo({ src: 'header', event: 'loader', data: false });
          return;
        }
        var preUrlId;
        if (this.pageContent) {
          let preQueryParams = this.pageContent['url'].split('?')[1].split('&');
          for (var i = 0; i < preQueryParams.length; i++) {
            let searchUrl = preQueryParams[i].split("=");
            if (searchUrl[0] == "planType") {
              preUrlId = searchUrl[1].toUpperCase();
            }
          }
        }

        if (e['url'].toLowerCase() == 'commanding') {
          delete this.portSelection;
          // this.pageContent = e;
          var queryParam = this.commandingUrl.split('?')[1].split('=');
          var params;
          params = queryParam[1].toLowerCase()
          port = queryParam[1].toLowerCase().split('_')[2];
          preUrlId = port;
          if (preUrlId.toLowerCase() == 'radhaz') {
            this.homeScreen = true;
            this.libraryState = false;
            this.shipSearchState = true;
            this.shipSearch = '';
            return;
          }
          // if (this.pageContent) {
          //   if (preUrlId.toLowerCase().includes('shipdata')) {
          //     var preSplitUrlData = preUrlId.toLowerCase().split('_');
          //     port = preSplitUrlData[2];
          //   } else if (preUrlId.toLowerCase().includes('fmf') || preUrlId.toLowerCase().includes('wfm')) {
          //     preSplitUrlData = preUrlId.toLowerCase().split('_');
          //     port = preSplitUrlData[0] == 'marlant' ? 'cfbhalifax' : 'cfbesquimalt';
          //   } else {
          //     if (preUrlId.toLowerCase() == 'marlant' || preUrlId.toLowerCase() == 'marpac') {
          //       port = preUrlId.toLowerCase() == 'marlant' ? 'cfbhalifax' : 'cfbesquimalt';
          //     }
          //   }
          //   this.portSelection = port;

          // }


          this.commandingSubmit(port);         
          this.libraryState = false;
          // this.shipSearchState=true;
          this.shipSearch = '';
          return;
          //          
        }
        let queryParams = e['url'].split('?')[1].split('&');
        var curUrlId;
        var iWindow = (<HTMLIFrameElement>iframe).contentWindow;
        for (var i = 0; i < queryParams.length; i++) {
          let searchUrl = queryParams[i].split("=");
          if (searchUrl[0] == "planType") {
            curUrlId = searchUrl[1].toUpperCase();
          }
          if (searchUrl[0] == 'appmode') {
            this.openUrlContent(e);
            return;
          }
        }
        //loaded

        if (curUrlId.toLowerCase() == 'gdp' || curUrlId.toLowerCase() == 'radhaz' || curUrlId.toLowerCase() == 'marlant' || curUrlId.toLowerCase() == 'marpac') {
          this.openUrlContent(e);
        } else {
          if (preUrlId.toLowerCase() == 'radhaz') {
            this.openUrlContent(e);
            return;
          }
          if (curUrlId.toLowerCase().includes('fmf')) {
            var splitUrlData = curUrlId.toLowerCase().split('_');
            if (preUrlId.toLowerCase().includes('shipdata')) {
              var preSplitUrlData = preUrlId.toLowerCase().split('_');
              var port = preSplitUrlData[2] == 'cfbhalifax' ? 'marlant' : 'marpac';
            } else if (preUrlId.toLowerCase().includes('fmf') || preUrlId.toLowerCase().includes('wfm')) {
              preSplitUrlData = preUrlId.toLowerCase().split('_');
              port = preSplitUrlData[0];
            } else {
              if (preUrlId.toLowerCase() == 'marlant' || preUrlId.toLowerCase() == 'marpac') {
                port = preUrlId.toLowerCase();
              } else {
                this.openUrlContent(e);
                return;
              }
            }
            if (port != splitUrlData[0]) {
              this.openUrlContent(e);
              return;
            }
          }
          if (curUrlId.toLowerCase().includes('wfm')) {
            var splitUrlData = curUrlId.toLowerCase().split('_');
            if (preUrlId.toLowerCase().includes('shipdata')) {
              var preSplitUrlData = preUrlId.toLowerCase().split('_');
              var port = preSplitUrlData[2] == 'cfbhalifax' ? 'marlant' : 'marpac';
            } else if (preUrlId.toLowerCase().includes('fmf') || preUrlId.toLowerCase().includes('wfm')) {
              preSplitUrlData = preUrlId.toLowerCase().split('_');
              port = preSplitUrlData[0];
            } else {
              if (preUrlId.toLowerCase() == 'marlant' || preUrlId.toLowerCase() == 'marpac') {
                port = preUrlId.toLowerCase();
              } else {
                this.openUrlContent(e);
                return;
              }
            }
            if (port != splitUrlData[0]) {
              this.openUrlContent(e);
              return;
            }
          }
          if (curUrlId.toLowerCase().includes('shipdata')) {
            var splitUrlData = curUrlId.toLowerCase().split('_');
            if (preUrlId.toLowerCase().includes('shipdata')) {
              var preSplitUrlData = preUrlId.toLowerCase().split('_');
              port = preSplitUrlData[2];
            } else if (preUrlId.toLowerCase().includes('fmf') || preUrlId.toLowerCase().includes('wfm')) {
              preSplitUrlData = preUrlId.toLowerCase().split('_');
              port = preSplitUrlData[0] == 'marlant' ? 'cfbhalifax' : 'cfbesquimalt';
            } else {
              if (preUrlId.toLowerCase() == 'marlant' || preUrlId.toLowerCase() == 'marpac') {
                port = preUrlId.toLowerCase() == 'marlant' ? 'cfbhalifax' : 'cfbesquimalt';
              } else {
                this.openUrlContent(e);
                return;
              }
            }
            if (port != splitUrlData[2]) {
              this.openUrlContent(e);
              return;
            }
            this.shipSearchState = false;
          }

          // iWindow.postMessage({ "for": "urlData", "data": curUrlId.toLowerCase() }, '*');
          this.broadcastIframeInfo({ iframeConnection: 'sdms-connection', event: 'urlData', data: curUrlId.toLowerCase(), key: "" });
        }

      } else {
        this.openUrlContent(e);
      }
    } else {
      this.openUrlContent(e);
    }

    // const dialogRef = this.dialog.open(PageContentDialog, {
    //   width: '800px',
    //   height: 'auto',
    //   data: { page: e['content'], key: this.loadScreenImg }
    // });

    // dialogRef.afterClosed().subscribe(result => {
    //   if (result) {
    //     this.loadUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(e['url']);

    //   } else {
    //     this.homeScreen = true;
    //   }
    // });
  }

  // @HostListener('window:message', ['$event'])
  // onmessage(event) {
  //   console.log("navyapp 2 window:message ", event);
  //   if (event.data === "sdms-connection") {
  //     event.source.postMessage({ "for": "loginInfo", "data": this.loginInfo }, event.origin);
  //     event.source.postMessage({ "for": "profileInfo", "data": this.profileInfo }, event.origin);
  //   }
  //   if (event.data === "scs-connection") {
  //     event.source.postMessage({ "for": "loginInformation", "data": this.loginInfo }, event.origin);
  //   }
  // }

  learningScreen: any = false;
  openUrlContent(e) {
    this.learningScreen = false;
    this.homeScreen = false;
    this.libraryState = false;
    this.shipSearchState = false;
    this.loadUrl = '';
    this.loadScreenImg = '';
    this.pageContent = e;
    if (e['key'] == 'scs' || e['key'] == 'sdms' || e['key'] == 'poc' || e['key'] == 'slms' || e['key'] == 'Fleetway'|| e['key'] == 'ourship'|| e['key'] == 'yourship' ||  e['key'] == 'demo_models') {
      if (e['url'].toLowerCase() == 'library') {
        this.homeScreen = true;
        this.libraryState = true;
        this.broadcastInfo({ src: 'header', event: 'loader', data: false });
        return;
      }
      if (e['url'].toLowerCase() == 'commanding') {
        // this.homeScreen = true;
        // this.shipSearchState=true;
        this.commandingSubmit()
        this.shipSearch = '';
        delete this.portSelection;
        return;
      }
      this.loadUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(e['url']);
      // setTimeout(() => {
      //   var iframe = document.getElementById('objWeb');
      //   if (iframe == null) return;
      //   var iWindow = (<HTMLIFrameElement>iframe).contentWindow;
      //   iWindow.postMessage({ "for": "Translate", "data": this.lang }, '*');
      //   iWindow.postMessage({ "for": "loginInfo", "data": this.loginInfo }, '*');
      //   iWindow.postMessage({ "for": "profileInfo", "data": this.profileInfo }, '*');
      // }, 1500);
    }
    // else if (e['key'] == 'slms') {
    //   this.learningScreen = true;
    // } 
    else {
      this.loadScreenImg = e['key'];
    }
  }
  libPanelClose() {
    this.libraryState = false;
  }
  soonPanelClose() {
    this.learningScreen = false;
    this.homeScreen = true;
  }
  shipPanelClose() {
    this.shipSearchState = false;
    delete this.portSelection;
  }
  searchShipClear() {
    this.shipSearch = '';
  }
  portToggle(e, key) {
    this.portSelection = key;

  }
  commandingSubmit(port = null) {
    var url = this.commandingUrl // this.getSDMSURL() + "?planType=shipData_"+this.shipSearch+"_"+this.portSelection;
    var data = { "key": "sdms", "url": url }
    console.log(data);
    if (port == null) {

      if (url) {
        this.openContentPage(data);
      } else {
        this.homeScreen = true;
        this.libraryState = false;
        this.shipSearchState = false;
        this.loadUrl = '';
        this.loadScreenImg = '';
      }
    } else {
      if (this.commandingUrl) {
        var queryParam = this.commandingUrl.split('?')[1].split('=');
        let curUrlId;
        var params;
        // for (var i = 0; i < queryParam.length; i++) {
        //   let searchUrl = queryParam[i].split("=");
        // if (searchUrl[0] == "planType") {
        params = queryParam[1].toLowerCase()
        curUrlId = queryParam[1].toLowerCase().split('_')[2];
        //   }
        // }
        if (curUrlId == port) {
          // var iframe = document.getElementById('objWeb');
          // var iWindow = (<HTMLIFrameElement>iframe).contentWindow;
          // iWindow.postMessage({ "for": "urlData", "data": params.toLowerCase() }, '*');
          this.broadcastIframeInfo({ iframeConnection: 'sdms-connection', event: 'urlData', data: params.toLowerCase(), key: "" });
        } else {
          this.openContentPage(data);
        }
      }
    }
  }
  @HostListener('window:resize', ['$event']) onResize(event) {
    console.log(event.target.innerWidth, event.target.innerHeight);
    this.windowWidth = event.target.innerWidth + 20;
    this.windowHeight = Number(event.target.innerHeight - 40);
  }
}

@Component({
  selector: 'page-content-popup',
  templateUrl: 'page-content-popup.html',
})
export class PageContentDialog implements OnInit {
  passwordErrorMsg: any = '';
  interPassword: any = "";
  commandingOffer: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<PageContentDialog>,
    @Inject(MAT_DIALOG_DATA) public data: pageData) {
    dialogRef.disableClose = true;
    console.log("data", data);
  }

  ngOnInit() {
  }

  // submit(e): void {
  //   if (this.data.key == 'intermodal') {
  //     this.passwordErrorMsg = '';
  //     console.log("password", this.interPassword)
  //     if (this.interPassword != '') {

  //       console.log("index", this.interPassword);
  //       if (this.interPassword == 'dfxterminal') {
  //         this.passwordErrorMsg = "";
  //         this.dialogRef.close(true);
  //       } else {
  //         this.passwordErrorMsg = 'Incorrect Password';
  //       }

  //     } else {
  //       this.passwordErrorMsg = 'Password Required';
  //     }
  //   } else {
  //     this.dialogRef.close(true);
  //   }
  // }

  cancel() {
    this.dialogRef.close(false);
  }
  continue() {
    this.dialogRef.close(true);
  }

}