import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { AdminComponent } from './admin/admin.component';
import { ChitsComponent } from './chits/chits.component';
import { HeaderComponent } from './header/header.component';
import { VisitorComponent } from './visitor/visitor.component'
import { QrScannerComponent } from './qr-scanner/qr-scanner.component';

import {
  AuthGuardService as AuthGuard
} from './services/auth.service';

const routes: Routes = [
  {
    path: 'user',
    component: AdminComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin',
    component: ChitsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'visitor',
    component: VisitorComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'scanner',
    component: QrScannerComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
