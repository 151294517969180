import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  accessToken: any;
  refershToken: any;

  scsURL: any = 'https://navyscs.azurewebsites.net/';
  sdmsURL: any = 'https://navysdms.azurewebsites.net/';
  // slmsURL: any = 'https://slms.navy.datifex.net/';
  slmsURL: any = 'https://slms.demo.datifex.com';

  // url: any = 'http://localhost:3000/';
  url: any = 'https://nodesdms.azurewebsites.net/';

  private subject = new Subject<any>();
  constructor(private http: HttpClient) { }

  getAccessToken(data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.post(this.url + 'sign/', body, { 'headers': headers })
  }

  getUserData(): Observable<any> {
    return this.http.get(this.url + 'authaccess/');
  }

  getChitData(): Observable<any> {
    return this.http.get(this.url + 'chits/');
  }

  getMessageData(): Observable<any> {
    return this.http.get(this.url + 'message/');
  }

  /* * *
  * post api call
  * * */
  pullData(ur, data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.post(ur, body, { 'headers': headers })
  }

  getUserDataById(id): Observable<any> {
    return this.http.get(this.url + 'authaccess/' + id);
  }

  deleteUserData(id): Observable<any> {
    return this.http.delete(this.url + 'authaccess/' + id);
  }

  addUserData(data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.post(this.url + 'authaccess/', body, { 'headers': headers })
  }

  updateUserData(id, data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.put(this.url + 'authaccess/' + id, body, { 'headers': headers })
  }


  /* * *
   * visitor API
   * * */
  getVisitorScanData(id): Observable<any> {
    return this.http.get(this.url + 'visitor_history/'+id);
  }
  getVisitorData(): Observable<any> {
    return this.http.get(this.url + 'visitor/');
  }

  getVisitorDataById(id): Observable<any> {
    return this.http.get(this.url + 'visitor/' + id);
  }

  addVistiorData(data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.post(this.url + 'visitor/', body, { 'headers': headers })
  }

  updateVisitorData(id, data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.put(this.url + 'visitor/' + id, body, { 'headers': headers })
  }
 
  deleteVisitorData(id): Observable<any> {
    return this.http.delete(this.url + 'visitor/' + id);
  }

  reSendQrCode(data){
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.post(this.url + 'visitor/reSendQrCode', body, { 'headers': headers })
  }

  /* * *
  * visitor scanning Api
  * * */
  visitorSignin(data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.post(this.url + 'visitor/signin', body, { 'headers': headers })
  }

  visitorSignout(data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.post(this.url + 'visitor/signout', body, { 'headers': headers })
  }

  visitorScan(data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.post(this.url + 'visitor/scan', body, { 'headers': headers })
  }

 
  /* * *
  * method for get Json data from server
  * * */
  getShipData(uri, data) {
    let self = this;
    return new Promise((resolve, reject) => {
      self.pullData(this.url + uri, data).subscribe(data => {
        resolve(data)
      },
        error => reject(error)
      );
    })
  }

  clearAllMessage() {
    return this.http.delete(this.url + 'message/' + 'clear/');
  }

  deleteMessage(id) {
    return this.http.delete(this.url + 'message/' + id);
  }
  //  Reload data
  sendReload(reload: string) {
    this.subject.next({ text: reload });
  }
  getReload(): Observable<any> {
    return this.subject.asObservable();
  }
}