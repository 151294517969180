import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

    constructor() {

    }

    login(email, password) {
      if (password === "navy") {
        return true;
      } else {
        return false;
      }
    }
  
}
