import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { MsalService, BroadcastService } from '@azure/msal-angular';
@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(public auth: MsalService, public router: Router) {}
  canActivate(): boolean {
    if (!this.auth.getAccount()) {
      this.router.navigate(['/']);
      return false;
    }
    return true;
  }
}