import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoginService } from '../services/login.service';

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss']
})
export class AuthenticationComponent implements OnInit {

  isLinear = true;
  loginFormGroup: FormGroup;
  email: String;
  password: String;


  constructor(private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private loginService: LoginService,
    private router: Router) { }

  ngOnInit(): void {
    this.loginFormGroup = this.formBuilder.group({
      email: ['', Validators.email],
      password: ['', [Validators.required]]

    });

  }

  onSubmit() {
    if (this.loginFormGroup.valid) {
        if (this.loginService.login(this.email, this.password)) {
          this.dialog.closeAll();
          this.snackBar.open('Login Succesful', '', {
            duration: 3000,
          });
        } else {
          this.loginFormGroup.markAsUntouched();
          this.snackBar.open('Invalid credentials', '', {
            duration: 3000
          })
        }
    }
  }

}
