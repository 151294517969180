import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ChitService } from '../services/chit.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Location } from '@angular/common';
import { callbackify } from 'util';
import { MatSelect } from '@angular/material';
import { CommunicationService } from '../services/communication.service';
import * as locationList from '../../assets/shipData/shipList.json';
import { Sort } from '@angular/material/sort';

@Component({
  selector: 'app-chits',
  templateUrl: './chits.component.html',
  styleUrls: ['./chits.component.scss']
})
export class ChitsComponent implements OnInit {
  isLinear = true;
  chitId: any;
  userId: any;
  subscription: Subscription;
  supervisorListData: any;
  supervisorListDataRes: any;
  oodListData: any;
  vesselListData: any;
  tagoutListData: any;
  user: any;
  supervisor: any;

  edit: any;
  create: any;
  selectedIndexBinding: 1;
  broadcastSubs: any;
  contractorDetails: any;
  dataSource: any;
  displayedSupervisorColumns: string[] = ['user_id', 'vessel', 'supervisor_name', 'onSiteContact','_id'];
  displayedScheduleColumns: string[] = ['vessel', 'userId', 'dockyard', 'location', 'role', 'start_date', 'end_date','_id'];
  displayedVesselColumns: string[] = ['vessel_id', 'vessel_name', 'port', '_id'];
  displayedTagoutColumns: string[] = ['name', 'vessel','_id'];
  displayedContractorColumns: string[] = ['firstName', 'lastName', 'companyName', 'emailID','mobileNo','role','rank','_id'];
  displayedLocationColumns: string[] = ['type', 'locationType', 'meshId', 'location','_id'];
  constructor(public dialog: MatDialog, private _formBuilder: FormBuilder, private communicationServ: CommunicationService, private chitService: ChitService, private route: Router) {

    this.subscription = this.chitService.getProfileData().subscribe(message => {
      var profile = message.text;
      if (profile == 'reload') {
        this.supervisorList();
        this.oodList();
        this.scheduleList();
        this.vesselList();
        this.tagoutList();
        this.getContractorDet();
        this.getLocationList();
      } else {
        this.userId = profile._id;
      }
    });
    this.subscription = this.chitService.getReload().subscribe(reload => {
      var profile = reload.text;
      if (profile == 'reload') {
        this.supervisorList();
        this.oodList();
        this.scheduleList();
        this.vesselList();
        this.tagoutList();
        this.getContractorDet();
        this.getLocationList();
      }
    });

  }
  broadcastInfo(data: any) {
    this.communicationServ.getInstance().next(data);
  }
  ngOnInit() {
    // to get accesstoken
    let animation = (timer) => {
      if (this.chitService.accessToken) {
        this.supervisorList();
        this.oodList();
        this.scheduleList();
        this.vesselList();
        this.tagoutList();
        this.getContractorDet();
        this.getLocationList();
        cancelAnimationFrame(AnimationTimer);
      } else {
        AnimationTimer = window.requestAnimationFrame(animation);
      }
    }
    var AnimationTimer = window.requestAnimationFrame(animation);

    this.broadcastSubs = this.communicationServ.getInstance()
      .subscribe((data: any) => {
        if (data.src === "admin") {
          if (data.event === 'activeTab') {
            this.selectedIndexBinding = data['data'];
          }
        }
      })
    this.selectedIndexBinding = this.selectedIndexBinding;
  }
  // supervisor search
  searchSupervisor: any = "";
  searchSupervisorData() {
    var filterData = [];
    var filterUserKey = ['user_id', 'vessel', 'supervisor_name', 'onSiteContact']
    if (this.searchSupervisor === '') {
      filterData = null;
      this.supervisorListData = this.supervisorListDataRes;
    } else {
      if (this.supervisorListDataRes.length > 0) {
        for (let i = 0; i < this.supervisorListDataRes.length; i++) {
          if (filterUserKey.length > 0) {
            filterUserKey.forEach((key) => {
              if (typeof this.supervisorListDataRes[i][key] === 'string' && typeof this.searchSupervisor === 'string') {
                if (this.supervisorListDataRes[i][key].toString().toLowerCase().indexOf(this.searchSupervisor.toLowerCase()) > -1) {
                  const found = filterData.some(el => el._id === this.supervisorListDataRes[i]['_id']);
                  if (!found) {
                    filterData.push(this.supervisorListDataRes[i]);
                  }
                }
              }
            });
          }
        }
      }
      this.supervisorListData = filterData;
    }
  }
  // supervisor Sort
  sortSupervisorData(sort: Sort) {
    const data = this.supervisorListDataRes.slice();
    if (!sort.active || sort.direction === '') {
      this.supervisorListData = data;
      return;
    }
    this.supervisorListData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      console.log("data", sort.active);
      switch (sort.active) {

        case 'user_id':
          return compare(a.user_id, b.user_id, isAsc);
        case 'vessel':
          return compare(a['vessel'], b['vessel'], isAsc);
        case 'supervisor_name':
          return compare(a['supervisor_name'], b['supervisor_name'], isAsc);
        case 'onSiteContact':
          return compare(a['onSiteContact'], b['onSiteContact'], isAsc);
        default:
          return 0;
      }
    });
  }

  // supervisor search
  searchSchedule: any = "";
  searchScheduleData() {
    var filterData = [];
    var filterUserKey = ['vessel', 'userId', 'dockyard', 'location', 'role', 'start_date', 'end_date']
    if (this.searchSchedule === '') {
      filterData = null;
      this.scheduleListData = this.scheduleListDataRes;
    } else {
      if (this.scheduleListDataRes.length > 0) {
        for (let i = 0; i < this.scheduleListDataRes.length; i++) {
          if (filterUserKey.length > 0) {
            filterUserKey.forEach((key) => {
              if (typeof this.scheduleListDataRes[i][key] === 'string' && typeof this.searchSchedule === 'string') {
                if (this.scheduleListDataRes[i][key].toString().toLowerCase().indexOf(this.searchSchedule.toLowerCase()) > -1) {
                  const found = filterData.some(el => el._id === this.scheduleListDataRes[i]['_id']);
                  if (!found) {
                    filterData.push(this.scheduleListDataRes[i]);
                  }
                }
              }
            });
          }
        }
      }
      this.scheduleListData = filterData;
    }
  }
  // supervisor Sort
  sortScheduleData(sort: Sort) {
    const data = this.scheduleListDataRes.slice();
    if (!sort.active || sort.direction === '') {
      this.scheduleListData = data;
      return;
    }
    this.scheduleListData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      console.log("data", sort.active);
      switch (sort.active) {

        case 'vessel':
          return compare(a.vessel, b.vessel, isAsc);
        case 'userId':
          return compare(a['userId'], b['userId'], isAsc);
        case 'dockyard':
          return compare(a['dockyard'], b['dockyard'], isAsc);
        case 'location':
          return compare(a['location'], b['location'], isAsc);
        case 'role':
          return compare(a['role'], b['role'], isAsc);
        case 'start_date':
          return compare(a['start_date'], b['start_date'], isAsc);
        case 'end_date':
          return compare(a['end_date'], b['end_date'], isAsc);
        default:
          return 0;
      }
    });
  }

  // vessel search
  searchVessel: any = "";
  searchVesseData() {
    var filterData = [];
    var filterUserKey = ['vessel_id', 'vessel_name', 'port']
    if (this.searchVessel === '') {
      filterData = null;
      this.vesselListData = this.vesselListDataRes;
    } else {
      if (this.vesselListDataRes.length > 0) {
        for (let i = 0; i < this.vesselListDataRes.length; i++) {
          if (filterUserKey.length > 0) {
            filterUserKey.forEach((key) => {
              if (typeof this.vesselListDataRes[i][key] === 'string' && typeof this.searchVessel === 'string') {
                if (this.vesselListDataRes[i][key].toString().toLowerCase().indexOf(this.searchVessel.toLowerCase()) > -1) {
                  const found = filterData.some(el => el._id === this.vesselListDataRes[i]['_id']);
                  if (!found) {
                    filterData.push(this.vesselListDataRes[i]);
                  }
                }
              }
            });
          }
        }
      }
      this.vesselListData = filterData;
    }
  }
  // vessel Sort
  sortVesseData(sort: Sort) {
    const data = this.vesselListDataRes.slice();
    if (!sort.active || sort.direction === '') {
      this.vesselListData = data;
      return;
    }
    this.vesselListData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      console.log("data", sort.active);
      switch (sort.active) {

        case 'vessel_id':
          return compare(a.vessel_id, b.vessel_id, isAsc);
        case 'vessel_name':
          return compare(a['vessel_name'], b['vessel_name'], isAsc);
        case 'port':
          return compare(a['port'], b['port'], isAsc);
        default:
          return 0;
      }
    });
  }

  // Tagout search
  searchTagout: any = "";
  searchTagoutData() {
    var filterData = [];
    var filterUserKey = ['name', 'vessel']
    if (this.searchTagout === '') {
      filterData = null;
      this.tagoutListData = this.tagoutListDataRes;
    } else {
      if (this.tagoutListDataRes.length > 0) {
        for (let i = 0; i < this.tagoutListDataRes.length; i++) {
          if (filterUserKey.length > 0) {
            filterUserKey.forEach((key) => {
              if (typeof this.tagoutListDataRes[i][key] === 'string' && typeof this.searchTagout === 'string') {
                if (this.tagoutListDataRes[i][key].toString().toLowerCase().indexOf(this.searchTagout.toLowerCase()) > -1) {
                  const found = filterData.some(el => el._id === this.tagoutListDataRes[i]['_id']);
                  if (!found) {
                    filterData.push(this.tagoutListDataRes[i]);
                  }
                }
              }
            });
          }
        }
      }
      this.tagoutListData = filterData;
    }
  }
  // Tagout Sort
  sortTagoutData(sort: Sort) {
    const data = this.tagoutListDataRes.slice();
    if (!sort.active || sort.direction === '') {
      this.tagoutListData = data;
      return;
    }
    this.tagoutListData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      console.log("data", sort.active);
      switch (sort.active) {

        case 'name':
          return compare(a.name, b.name, isAsc);
        case 'vessel':
          return compare(a['vessel'], b['vessel'], isAsc);
        default:
          return 0;
      }
    });
  }

  // Tagout search
  searchContractor: any = "";
  searchContractorData() {
    var filterData = [];
    var filterUserKey = ['firstName', 'lastName', 'companyName', 'emailID','mobileNo','role','rank']
    if (this.searchContractor === '') {
      filterData = null;
      this.contractorDetails = this.contractorDetailsRes;
    } else {
      if (this.contractorDetailsRes.length > 0) {
        for (let i = 0; i < this.contractorDetailsRes.length; i++) {
          if (filterUserKey.length > 0) {
            filterUserKey.forEach((key) => {
              if (typeof this.contractorDetailsRes[i][key] === 'string' && typeof this.searchContractor === 'string') {
                if (this.contractorDetailsRes[i][key].toString().toLowerCase().indexOf(this.searchContractor.toLowerCase()) > -1) {
                  const found = filterData.some(el => el._id === this.contractorDetailsRes[i]['_id']);
                  if (!found) {
                    filterData.push(this.contractorDetailsRes[i]);
                  }
                }
              }
            });
          }
        }
      }
      this.contractorDetails = filterData;
    }
  }
  // Tagout Sort
  sortContractorData(sort: Sort) {
    const data = this.contractorDetailsRes.slice();
    if (!sort.active || sort.direction === '') {
      this.tagoutListData = data;
      return;
    }
    this.tagoutListData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      console.log("data", sort.active);
      switch (sort.active) {
        case 'firstName':
          return compare(a.firstName, b.firstName, isAsc);
        case 'lastName':
          return compare(a.lastName, b.lastName, isAsc);
        case 'companyName':
          return compare(a.companyName, b.companyName, isAsc);
        case 'emailID':
          return compare(a.emailID, b.emailID, isAsc);
        case 'mobileNo':
          return compare(a.mobileNo, b.mobileNo, isAsc);
        case 'role':
          return compare(a.role, b.role, isAsc);
        case 'rank':
          return compare(a.rank, b.rank, isAsc);
        default:
          return 0;
      }
    });
  }

    // Location search
    searchLocation: any = "";
    searchLocationData() {
      var filterData = [];
      var filterUserKey = ['type', 'locationType', 'meshId', 'location']
      if (this.searchLocation === '') {
        filterData = null;
        this.locationList = this.locationListRes;
      } else {
        if (this.locationListRes.length > 0) {
          for (let i = 0; i < this.locationListRes.length; i++) {
            if (filterUserKey.length > 0) {
              filterUserKey.forEach((key) => {
                if (typeof this.locationListRes[i][key] === 'string' && typeof this.searchLocation === 'string') {
                  if (this.locationListRes[i][key].toString().toLowerCase().indexOf(this.searchLocation.toLowerCase()) > -1) {
                    const found = filterData.some(el => el._id === this.locationListRes[i]['_id']);
                    if (!found) {
                      filterData.push(this.locationListRes[i]);
                    }
                  }
                }
              });
            }
          }
        }
        this.locationList = filterData;
      }
    }
    // Location Sort
    sortLocationData(sort: Sort) {
      const data = this.locationListRes.slice();
      if (!sort.active || sort.direction === '') {
        this.locationList = data;
        return;
      }
      this.locationList = data.sort((a, b) => {
        const isAsc = sort.direction === 'asc';
        console.log("data", sort.active);
        switch (sort.active) {
          case 'firstName':
            return compare(a.firstName, b.firstName, isAsc);
          case 'lastName':
            return compare(a.lastName, b.lastName, isAsc);
          case 'companyName':
            return compare(a.companyName, b.companyName, isAsc);
          case 'emailID':
            return compare(a.emailID, b.emailID, isAsc);
          case 'mobileNo':
            return compare(a.mobileNo, b.mobileNo, isAsc);
          case 'role':
            return compare(a.role, b.role, isAsc);
          case 'rank':
            return compare(a.rank, b.rank, isAsc);
          default:
            return 0;
        }
      });
    }

  searchClear() {
    this.searchSupervisor = "";
    this.searchSchedule = "";
    this.searchVessel = "";
    this.searchLocation = "";
    this.supervisorListData = this.supervisorListDataRes;
    this.scheduleListData = this.scheduleListDataRes;
    this.vesselListData = this.vesselListDataRes;
    this.tagoutListData = this.tagoutListDataRes;
    this.locationList = this.locationListRes;
    let filterdData = null;
  }

  tabChanged(event) {
    if (event.index == 0) {
      this.route.navigate(['/user']);
    } else {
      this.supervisorList();
      this.oodList();
      this.scheduleList();
      this.vesselList();
      this.tagoutList();
    }
  }

  // Supervisor

  supervisorList() {
    this.chitService.getSupervisorData()
      .subscribe(
        (response) => {
          var repos = response;
          this.supervisorListDataRes = repos;
          this.supervisorListData = this.supervisorListDataRes;
        },
        error => {
          console.error('Request failed with error')
          console.log(error)
        })
  }
  supervisorDelete(id) {
    this.chitService.deleteSupervisor(id)
      .subscribe(
        (response) => {
          var repos = response;
          this.supervisorList();
        },
        error => {
          console.error('Request failed with error')
          console.log(error)
        })
  }
  scheduleListData: any = [];
  scheduleListDataRes: any = [];
  // OOD
  scheduleList() {
    this.chitService.getOodScheduleData()
      .subscribe(
        (response) => {
          if (response.length > 0) {
            this.scheduleListDataRes = response.reverse();
            this.scheduleListData = this.scheduleListDataRes;
            console.log('response received scheduleListData', this.scheduleListData)

          } else {
            this.scheduleListData = [];
          }
        },
        error => {
          console.error('Request failed with error')
          console.log(error)
        })
  }
  scheduleDelete(schedule) {
    let data = { id: schedule['_id'], dockyard: schedule['dockyard'] };
    this.chitService.deleteOodSchedule(data)
      .subscribe(
        (response) => {
          var repos = response;
          this.scheduleList();
        },
        error => {
          console.error('Request failed with error')
          console.log(error)
        })
  }
  // OOD
  oodList() {
    this.chitService.getOodData()
      .subscribe(
        (response) => {
          if (response.length > 0) {
            this.oodListData = response.reverse();
            console.log('response received', this.oodListData)

          } else {
            this.oodListData = [];
          }
        },
        error => {
          console.error('Request failed with error')
          console.log(error)
        })
  }
  oodDelete(id) {
    this.chitService.deleteOod(id)
      .subscribe(
        (response) => {
          var repos = response;
          this.oodList();
        },
        error => {
          console.error('Request failed with error')
          console.log(error)
        })
  }
  // Vessel

  // OOD
  vesselListDataRes: any;
  vesselList() {
    this.chitService.getVesselData()
      .subscribe(
        (response) => {
          var repos = response;
          this.vesselListDataRes = repos;
          this.vesselListData = this.vesselListDataRes;
        },
        error => {
          console.error('Request failed with error')
          console.log(error)
        })
  }

  vesselDelete(id) {
    this.chitService.deleteVessel(id)
      .subscribe(
        (response) => {
          var repos = response;
          this.vesselList();
        },
        error => {
          console.error('Request failed with error')
          console.log(error)
        })
  }
  tagoutListDataRes: any;
  // tagoutList
  tagoutList() {
    this.chitService.getTagoutData()
      .subscribe(
        (response) => {
          var repos = response;
          this.tagoutListDataRes = repos;
          this.tagoutListData = this.tagoutListDataRes
        },
        error => {
          console.error('Request failed with error')
          console.log(error)
        })
  }
  tagoutDelete(id) {
    this.chitService.deleteTagout(id)
      .subscribe(
        (response) => {
          var repos = response;
          this.tagoutList();
        },
        error => {
          console.error('Request failed with error')
          console.log(error)
        })
  }
  supervisorEdit(data, id, item) {
    this.dialog.open(ChitPopup, {
      data: {
        user: {
          id: this.userId,
        },
        edit: {
          edit: data,
          id: id,
          item: item
        },
        create: {
          create: '',
        },
      },
    });
  }

  contractorEdit(data, conDet) {
    this.dialog.open(ChitPopup, {
      data: {
        user: {
          id: this.userId,
        },
        edit: {
          edit: data,
          conDet: conDet
        },
        create: {
          create: '',
        },
      },
    });
  }

  contractorDelete(id) {
    this.chitService.deleteContractor(id)
      .subscribe(
        (response) => {
          var repos = response;
          this.getContractorDet();
        },
        error => {
          console.error('Request failed with error')
          console.log(error)
        })
  }

  contractorDetailsRes: any;
  getContractorDet() {
    this.chitService.getContractorDet().subscribe((res) => {
      console.log('contractorDet', res);
      this.contractorDetailsRes = res;
      this.contractorDetails = this.contractorDetailsRes;
    }, error => {
      console.error('There was an error!');
    });;
  }

  locationList: any;
  locationListRes: any;
  getLocationList() {
    this.chitService.getLocationList().subscribe((res) => {
      console.log('locationList', res);
      this.locationListRes = res;
      this.locationList = this.locationListRes;
    }, error => {
      console.error('There was an error!');
    });
  }


  locationEdit(data, locationDet) {
    this.dialog.open(ChitPopup, {
      data: {
        user: {
          id: this.userId,
        },
        edit: {
          edit: data,
          locationDet: locationDet
        },
        create: {
          create: '',
        },
      },
    });
  }

  locationDelete(id) {
    this.chitService.deleteLocation(id)
      .subscribe(
        (response) => {
          var repos = response;
          this.getLocationList();
        },
        error => {
          console.error('Request failed with error')
          console.log(error)
        })
  }

  openDialog(data): void {
    this.dialog.open(ChitPopup, {
      data: {
        user: {
          id: this.userId,
        },
        supervisor: {
          edit: '',
        },
        ood: {
          edit: '',
        },
        vessel: {
          edit: '',
        },
        create: {
          create: data,
        },
      },
    });
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

@Component({
  selector: 'chit-popup',
  templateUrl: 'chit-popup.html',
  styleUrls: ['./chits.component.scss']
})
export class ChitPopup {
  @ViewChild('vessel', { static: false }) vesselField: MatSelect;
  chitId: any;
  userId: any;
  userListData: any;
  supervisorFormGroup!: FormGroup;
  oodScheduleFormGroup!: FormGroup;
  oodScheduleDataFormGroup!: FormGroup;
  vesselMasterFormGroup!: FormGroup;
  tagoutMasterFormGroup!: FormGroup;
  tagoutRolesFormGroup!: FormGroup;
  contractorFormGroup!: FormGroup;
  locationFormGroup!: FormGroup;


  supervisorForm: boolean = false;
  oodForm: boolean = false;
  vesselForm: boolean = false;
  lockoutForm: boolean = false;
  contractorForm: boolean = false;
  locationForm: boolean = false;

  rolesForm: boolean = false;
  oodScheduleForm: boolean = false;

  CreateOodScheduleButton: boolean = true;
  UpdateOodScheduleButton: boolean = false;
  CreatesupervisorButton: boolean = true;
  UpdatesupervisorButton: boolean = false;
  CreateOodButton: boolean = true;
  UpdateOodButton: boolean = false;
  CreateVesselButton: boolean = true;
  UpdateVesselButton: boolean = false;
  CreateTagoutButton: boolean = true;
  UpdateTagoutButton: boolean = false;
  createRolesButton: boolean = true;
  updateRolesButton: boolean = false;
  locationFormButton: boolean = false;

  supervisorInfo: any;
  onSiteContactInfo: any;
  // locationUrl: 'https://navysdms.azurewebsites.net/json';
  locationUrl: 'http://localhost:3000/json';
  shipList: any = locationList['default'];
  oodContactInfo: any;
  startTime = '08:00';
  endTime = '20:00';

  subscription: Subscription;
  // chitMasterData: any;
  supervisor: any = [
    { 'id': '001', 'supName': 'Hunter', 'supRank': '001', 'supTelephone': '6139952534' },
    { 'id': '002', 'supName': 'Jackson', 'supRank': '002', 'supTelephone': '6139952534' },
    { 'id': '003', 'supName': 'Noah', 'supRank': '003', 'supTelephone': '6139952534' },
    { 'id': '004', 'supName': 'Liam', 'supRank': '004', 'supTelephone': '6139952534' },
    { 'id': '005', 'supName': 'Benjamin', 'supRank': '005', 'supTelephone': '6139952534' },
    { 'id': '006', 'supName': 'Oliver', 'supRank': '006', 'supTelephone': '6139952534' },
    { 'id': '007', 'supName': 'Ethan', 'supRank': '007', 'supTelephone': '6139952534' }
  ];
  port: any = [
    { 'port': 'CFB Esquimalt', 'value': 'CFB Esquimalt' },
    { 'port': 'CFB Halifax', 'value': 'CFB Halifax' },
  ]
  dockyardeDetails = this.port.port;
  VesselName: any = []
  oodNameDetails: any;
  supervisorNameDetails: any;
  onSiteNameDetails: any;
  // VesselName: any = ['Toronto', 'Waterloo', 'Welland', 'London', 'North York', 'Oakville', 'Nunavut', 'Nova Scotia', 'New Brunswick', 'Manitoba', 'Barkerville', 'Jasper']
  schedule: any = ['OOD', 'Duty Tech', 'URFSO'];
  roleDatas = ["DEFAULT", "CO", "OOD", "DPO", "QM", "MSEO", "CSEO", 'Duty Tech', 'URFSO', "CCFL", "CCFP", "WFM", "QHM", "FLEETWAY", "On-Site Contact", "Staff Supervisor"];
  scheduleDuty: any = ['initiate', 'guarantee', 'authorize', 'done'];

  Roles: any = {
    'initiate': ['Tester', 'Maintainer'],
    'guarantee': ['MSEO/CSEO', 'OOD/DTech'],
    'authorize': ['Maintainer', 'Dept Rep', 'MSEO/CSEO', 'OOD/DTech', 'Lockout/Tagout(Coordinator)'],
    'done': ['Qualified Person']
  }
  contractorFormButton: boolean = false;
  updateFormButton: boolean = false;
  locationListData: any = [
    { 'key': 'visitor', 'value': 'Visitor' },
    { 'key': 'rounds', 'value': 'Rounds' },
    { 'key': 'flex', 'value': 'Flex' },
    { 'key': 'loto', 'value': 'LOTO' }
  ]
  roundstype: any = ['Small Arms', 'Safety & Security', 'Habitability', 'MCR Technical Rounds', 'Departmental Rounds', 'Magazine Rounds', 'Fire Prevention']
  Locations = [];
  flexType: any = ['Maintenance', 'Ventilation']
  lotoType = [
    {
      "key": "A1",
      "value": "A1"
    },
    {
      "key": "A1,2",
      "value": "A1,2"
    },
    {
      "key": "A2",
      "value": "A2"
    },
    {
      "key": "A2,2",
      "value": "A2,2"
    }]
  updatelocationFormButton: boolean = false;
  constructor(@Inject(MAT_DIALOG_DATA) public data: ChitsComponent, public dialog: MatDialog, private _formBuilder: FormBuilder, private chitService: ChitService, private routes: ActivatedRoute, private _location: Location, private route: Router) {

    this.Locations = chitService.Locations;

    this.contractorFormGroup = this._formBuilder.group({
      _id: '',
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      emailID: ['', Validators.email],
      mobileNo: ['', Validators.required],
      role: [''],
      rank: [''],
      companyName: ['']
    });

    this.locationFormGroup = this._formBuilder.group({
      _id: '',
      type: ['', Validators.required],
      meshId: [''],
      location: ['{"x":0,"y":0,"z":0}', Validators.required],
      locationType: ["", Validators.required],
      locationKey: [""]

      // lastName: ['', Validators.required],
      // emailID: ['', Validators.email],
      // mobileNo: ['', Validators.required],
      // role: [''],
      // rank: [''],
      // companyName: ['']
    });

    console.log("Dtat", data);
    var user = data.user
    this.userId = user.id;
    var supervisorData = data.create
    var supervisorCreate = supervisorData.create
    var supervisor = data.edit ? data.edit : "";
    var supervisorEdit = supervisor.edit ? supervisor.edit : '';
    if (supervisorCreate == "supervisor") {
      this.supervisorForm = true;
      this.CreatesupervisorButton = true;
      this.UpdatesupervisorButton = false;
    }
    if (supervisorCreate == "ood") {
      this.oodForm = true;
      this.CreateOodButton = true;
      this.UpdateOodButton = false;
    }
    if (supervisorCreate == "ood-schedule") {
      this.oodScheduleForm = true;
      this.CreateOodScheduleButton = true;
      this.UpdateOodScheduleButton = false;
    }
    if (supervisorCreate == "vessel") {
      this.vesselForm = true;
      this.CreateVesselButton = true;
      this.UpdateVesselButton = false;
    }
    if (supervisorCreate == "tagout") {
      this.lockoutForm = true;
      this.CreateTagoutButton = true;
      this.UpdateTagoutButton = false;
    }
    if (supervisorEdit == "supervisor") {
      this.supervisorForm = true;
      this.CreatesupervisorButton = false;
      this.UpdatesupervisorButton = true;
      this.getSupervisorData(supervisor.id);
      this.chitId = supervisor.id;
    }
    if (supervisorEdit == "ood") {
      this.oodForm = true;
      this.CreateOodButton = false;
      this.UpdateOodButton = true;
      this.getOodData(supervisor.id);
      this.chitId = supervisor.id;
    }
    if (supervisorEdit == "ood-schedule") {
      this.oodScheduleForm = true;
      this.CreateOodScheduleButton = false;
      this.UpdateOodScheduleButton = true;
      this.getOodScheduleData(supervisor);
      this.chitId = supervisor.id;
    }
    if (supervisorEdit == "vessel") {
      this.vesselForm = true;
      this.CreateVesselButton = false;
      this.UpdateVesselButton = true;
      this.getVesselData(supervisor.id);
      this.chitId = supervisor.id;
    }
    if (supervisorEdit == "tagout") {
      this.lockoutForm = true;
      this.CreateTagoutButton = false;
      this.UpdateTagoutButton = true;
      this.getTagoutData(supervisor.id);
      this.getRolesData(supervisor.id);
      this.chitId = supervisor.id;
      this.selectedTagoutID = supervisor.id;
    }
    if (supervisorCreate == "contractor") {
      this.contractorForm = true;
      this.contractorFormButton = true;
    }
    if (supervisorEdit == "contractor") {
      this.contractorForm = true;
      this.contractorFormButton = false;
      this.updateFormButton = true;
      this.setContractForm(supervisor.conDet)
    }
    if (supervisorCreate == "location") {
      this.locationForm = true;
      this.locationFormButton = true;
    }
    if (supervisorEdit == "location") {
      this.locationForm = true;
      this.locationFormButton = false;
      this.updatelocationFormButton = true;
      this.setLocationForm(supervisor.locationDet)
    }
  }


  ngOnInit() {
    // to get accesstoken
    let animation = (timer) => {
      if (this.chitService.accessToken) {
        this.userList();
        this.getShipConfig();
        cancelAnimationFrame(AnimationTimer);
      } else {
        AnimationTimer = window.requestAnimationFrame(animation);
      }
    }
    var AnimationTimer = window.requestAnimationFrame(animation);

    this.supervisorFormGroup = this._formBuilder.group({
      user_id: [''],
      supervisor_name: ['', Validators.required],
      vessel: ['', Validators.required],
      onSiteContact: ['', Validators.required],
      supervisor_name_email: [''],
      onSiteContactEmail: [''],
    });
    this.oodScheduleDataFormGroup = this._formBuilder.group({
      userId: ['', Validators.required],
      vessel: ['', Validators.required],
      role: ['', Validators.required],
      dockyard: ['', Validators.required],
      start_date: [''],
      end_date: ['']
    });
    this.oodScheduleFormGroup = this._formBuilder.group({
      ood_name: ['', Validators.required],
      vessel: ['', Validators.required],
      dockyard: ['CFB Esquimalt', Validators.required],
      shedule: ['', Validators.required],
      start_date: [((new Date()).toISOString().substring(0, 10)), Validators.required],
      start_time: ['', Validators.required],
      end_time: ['', Validators.required],
      supervisor_name: ['', Validators.required],
      onSiteContact: ['', Validators.required],
      supervisor_email: [''],
      onSiteContactEmail: [''],
      ursfo: [''],
      proximity_ood: [''],
      qhm: [''],
      ood: [''],
    });
    this.vesselMasterFormGroup = this._formBuilder.group({
      vessel_id: ['', Validators.required],
      vessel_name: ['', Validators.required],
      port: ['', Validators.required],
    });
    this.tagoutMasterFormGroup = this._formBuilder.group({
      name: ['', Validators.required],
      vessel: ['', Validators.required]
    });
    this.tagoutRolesFormGroup = this._formBuilder.group({
      tagout_id: [this.selectedTagoutID],
      scheduleDuty: ['', Validators.required],
      role: ['', Validators.required],
    });

  }

  // supervisor
  Createsupervisor() {
    if (this.chitId) {
      let data = {
        onSiteContact: this.onSiteContactInfo['First Name'],
        onSiteContactEmail: this.onSiteContactInfo.email,
        supervisor_name: this.supervisorInfo['First Name'],
        supervisor_name_email: this.supervisorInfo.email,
        user_id: this.supervisorFormGroup.get('user_id').value,
        vessel: this.supervisorFormGroup.get('vessel').value,
      }
      this.chitService.updateSupervisor(this.chitId, data)
        .subscribe((res) => {
          this.dialog.closeAll();
          this.chitService.sendReload("reload");
        }, error => {
          console.error('There was an error!');
        })
    } else {
      let data = {
        onSiteContact: this.onSiteContactInfo['First Name'],
        onSiteContactEmail: this.onSiteContactInfo.email,
        supervisor_name: this.supervisorInfo['First Name'],
        supervisor_name_email: this.supervisorInfo.email,
        user_id: this.supervisorFormGroup.get('user_id').value,
        vessel: this.supervisorFormGroup.get('vessel').value,
      }
      // console.log("Create Form Data", data);
      this.chitService.addSupervisor(data)
        .subscribe((res) => {
          // console.log("supervisor", res)
          this.chitId = res.insertedId;
          // this.route.navigate(['/chits']);
          this.dialog.closeAll();
          this.chitService.sendReload("reload");
          var status = res.status
        }, error => {
          console.error('There was an error!');
        })
    }
  }
  getSupervisorData(id) {
    this.chitService.getSupervisorDataList(id)
      .subscribe(
        (response) => {
          var repos = response;
          var supervisor = repos[0]
          this.supervisorFormGroup.patchValue(supervisor);
          this.supervisorNameDetails = supervisor.supervisor_name_email ? supervisor.supervisor_name_email : ""
          this.onSiteNameDetails = supervisor.onSiteContactEmail ? supervisor.onSiteContactEmail : ""
        },
        error => {
          console.error('Request failed with error')
          console.log(error)
        })
  }
  Updatesupervisor() {
    let data = {
      onSiteContact: this.supervisorFormGroup.get('onSiteContact').value,
      onSiteContactEmail: this.supervisorFormGroup.get('onSiteContactEmail').value,
      supervisor_name: this.supervisorFormGroup.get('supervisor_name_email').value,
      supervisor_name_email: this.supervisorFormGroup.get('supervisor_name_email').value,
      user_id: this.supervisorFormGroup.get('user_id').value,
      vessel: this.supervisorFormGroup.get('vessel').value,
    }
  }

  getDockyardShipData(port, data, cbck) {
    let shipMetaData = {};
    port = port.split(" ").join("").toLowerCase();
    this.chitService.pullJsonData('json', { port: port, filename: 'shipObjects' }).then((res) => {
      if (res['result'] != 'failed') {
        shipMetaData = res;
        if (shipMetaData['ships'].length > 0) {
          const shipFound = shipMetaData['ships'].some(ship => ship['ship'] === data['vessel'] && (new Date(data['start_date']) >= new Date(ship['startdate']) && new Date(data['start_date']) <= new Date(ship['enddate'])))
          const shipData = shipMetaData['ships'].filter(ship => ship['ship'] === data['vessel'] && (new Date(data['start_date']) >= new Date(ship['startdate']) && new Date(data['start_date']) <= new Date(ship['enddate'])))
          if (shipFound) {
            cbck(true, shipData[0].location);
          } else {
            cbck(false, '');
          }
        } else {
          cbck(false, '');
        }
      } else {
        cbck(false, '');
      }
    }).catch((error) => {
      console.log(error);
      cbck(false, '');
    });
  }

  // OOD
  selectedVessel: any = '';
  shipLocation: any;
  selectedDockyardVesselState: boolean = false;
  CreateOod() {
    if (this.chitId) {
      let data = {
        dockyard: this.oodScheduleFormGroup.get('dockyard').value,
        ood_name: this.oodContactInfo['First Name'],
        end_time: this.oodScheduleFormGroup.get('end_time').value,
        shedule: this.oodScheduleFormGroup.get('shedule').value,
        start_date: this.oodScheduleFormGroup.get('start_date').value,
        start_time: this.oodScheduleFormGroup.get('start_time').value,
        vessel: this.oodScheduleFormGroup.get('vessel').value,
        ood_email: this.oodContactInfo.email,
        onSiteContact: this.oodScheduleFormGroup.get('onSiteContact').value,
        onSiteContactEmail: this.oodScheduleFormGroup.get('onSiteContactEmail').value,
        supervisor_name: this.oodScheduleFormGroup.get('supervisor_name').value,
        supervisor_email: this.oodScheduleFormGroup.get('supervisor_email').value,
        ursfo: this.oodScheduleFormGroup.get('ursfo').value,
        proximity_ood: this.oodScheduleFormGroup.get('proximity_ood').value,
        qhm: this.oodScheduleFormGroup.get('qhm').value,
        ood: this.oodScheduleFormGroup.get('ood').value,
        location: this.shipLocation,
      }
      let port = this.oodScheduleFormGroup.get('dockyard').value;
      this.selectedVessel = this.oodScheduleFormGroup.get('vessel').value;
      if (port) {
        this.getDockyardShipData(port, data, (boo, location) => {
          if (boo) {
            this.selectedDockyardVesselState = false;
            this.chitService.updateOod(this.chitId, data)
              .subscribe((res) => {
                this.dialog.closeAll();
                this.chitService.sendReload("reload");
              }, error => {
              })
          } else {
            this.selectedDockyardVesselState = true;
            if (this.vesselField) this.vesselField.focus();
          }
        })
      } else {
        console.log("Given data is not valid!!")
      }
    } else {
      console.log("data", this.oodScheduleFormGroup.value);
      let data = {
        dockyard: this.oodScheduleFormGroup.get('dockyard').value,
        ood_name: this.oodScheduleFormGroup.get('ood_name').value,
        end_time: this.oodScheduleFormGroup.get('end_time').value,
        shedule: this.oodScheduleFormGroup.get('shedule').value,
        start_date: this.oodScheduleFormGroup.get('start_date').value,
        start_time: this.oodScheduleFormGroup.get('start_time').value,
        vessel: this.oodScheduleFormGroup.get('vessel').value,
        ood_email: this.oodScheduleFormGroup.get('ood_name').value,
        onSiteContact: this.oodScheduleFormGroup.get('onSiteContact').value,
        onSiteContactEmail: this.oodScheduleFormGroup.get('onSiteContactEmail').value,
        supervisor_name: this.oodScheduleFormGroup.get('supervisor_name').value,
        supervisor_email: this.oodScheduleFormGroup.get('supervisor_email').value,
        ursfo: this.oodScheduleFormGroup.get('ursfo').value,
        proximity_ood: this.oodScheduleFormGroup.get('proximity_ood').value,
        qhm: this.oodScheduleFormGroup.get('qhm').value,
        ood: this.oodScheduleFormGroup.get('ood').value,
        location: this.shipLocation,
      }

      let port = this.oodScheduleFormGroup.get('dockyard').value;
      this.selectedVessel = this.oodScheduleFormGroup.get('vessel').value;
      if (port) {
        this.getDockyardShipData(port, data, (boo, location) => {
          if (boo) {
            let data = {
              dockyard: this.oodScheduleFormGroup.get('dockyard').value,
              ood_name: this.oodScheduleFormGroup.get('ood_name').value,
              end_time: this.oodScheduleFormGroup.get('end_time').value,
              shedule: this.oodScheduleFormGroup.get('shedule').value,
              start_date: this.oodScheduleFormGroup.get('start_date').value,
              start_time: this.oodScheduleFormGroup.get('start_time').value,
              vessel: this.oodScheduleFormGroup.get('vessel').value,
              ood_email: this.oodScheduleFormGroup.get('ood_name').value,
              onSiteContact: this.oodScheduleFormGroup.get('onSiteContact').value,
              onSiteContactEmail: this.oodScheduleFormGroup.get('onSiteContactEmail').value,
              supervisor_name: this.oodScheduleFormGroup.get('supervisor_name').value,
              supervisor_email: this.oodScheduleFormGroup.get('supervisor_email').value,
              ursfo: this.oodScheduleFormGroup.get('ursfo').value,
              proximity_ood: this.oodScheduleFormGroup.get('proximity_ood').value,
              qhm: this.oodScheduleFormGroup.get('qhm').value,
              ood: this.oodScheduleFormGroup.get('ood').value,
              location: location,
            }

            this.selectedDockyardVesselState = false;
            this.chitService.addOod(data)
              .subscribe((res) => {
                this.chitId = res.insertedId;
                this.dialog.closeAll();
                this.chitService.sendReload("reload");
                var status = res.status
              }, error => {
                console.error('There was an error!');
              })
          } else {
            this.selectedDockyardVesselState = true;
            if (this.vesselField) this.vesselField.focus();
          }
        })
      } else {
        console.log("Given data is not valid!!")
      }
    }
  }


  CreateOodSchedule() {
    let port = this.oodScheduleDataFormGroup.get('dockyard').value;
    this.selectedVessel = this.oodScheduleDataFormGroup.get('vessel').value;
    let data = {
      vessel: this.oodScheduleDataFormGroup.get('vessel').value,
      userId: this.oodScheduleDataFormGroup.get('userId').value,
      start_date: this.oodScheduleDataFormGroup.get('start_date').value,
      end_date: this.oodScheduleDataFormGroup.get('end_date').value,
      role: this.oodScheduleDataFormGroup.get('role').value,
      dockyard: this.oodScheduleDataFormGroup.get('dockyard').value,
      location: this.shipLocation
    }
    if (port) {
      this.getDockyardShipData(port, data, (boo, location) => {
        if (boo) {
          data['location'] = location;
          this.chitService.addOodSchedule(data)
            .subscribe((res) => {
              this.chitId = res.insertedId;
              this.dialog.closeAll();
              this.chitService.sendReload("reload");
              var status = res.status
            }, error => {
              console.error('There was an error!');
            })
        } else {
          this.selectedDockyardVesselState = true;
          if (this.vesselField) this.vesselField.focus();
        }
      })
    }
  }
  UpdateOodSchedule() {
    let port = this.oodScheduleDataFormGroup.get('dockyard').value;
    this.selectedVessel = this.oodScheduleDataFormGroup.get('vessel').value;
    let data = {
      vessel: this.oodScheduleDataFormGroup.get('vessel').value,
      userId: this.oodScheduleDataFormGroup.get('userId').value,
      start_date: this.oodScheduleDataFormGroup.get('start_date').value,
      end_date: this.oodScheduleDataFormGroup.get('end_date').value,
      role: this.oodScheduleDataFormGroup.get('role').value,
      dockyard: this.oodScheduleDataFormGroup.get('dockyard').value,
      location: this.shipLocation
    }
    if (port) {
      this.getDockyardShipData(port, data, (boo, location) => {
        if (boo) {
          data['location'] = location;
          this.chitService.updateOodSchedule(this.chitId, data)
            .subscribe((res) => {
              this.dialog.closeAll();
              this.chitService.sendReload("reload");
            }, error => {
              console.error('There was an error!');
            })
        } else {
          this.selectedDockyardVesselState = true;
          if (this.vesselField) this.vesselField.focus();
        }
      })
    }

  }
  getOodScheduleData(schedule) {
    let data = { id: schedule['id'], dockyard: schedule['item']['dockyard'] };
    this.chitService.getOodScheduleDataList(data)
      .subscribe(
        (response) => {
          var repos = response;
          var getOodData = repos[0]
          this.oodScheduleDataFormGroup.patchValue(getOodData);
        },
        error => {
          console.error('Request failed with error')
          console.log(error)
        })
  }
  oodListData: any;
  getOodData(id) {
    this.chitService.getOodDataList(id)
      .subscribe(
        (response) => {
          var repos = response;
          var getOodData = repos[0]
          this.oodScheduleFormGroup.patchValue(getOodData);
          this.oodNameDetails = getOodData.ood_email ? getOodData.ood_email : ""
          this.shipLocation = getOodData.location ? getOodData.location : '';
        },
        error => {
          console.error('Request failed with error')
          console.log(error)
        })
  }
  UpdateOod() {
    let data = {
      dockyard: this.oodScheduleFormGroup.get('dockyard').value,
      ood_name: this.oodScheduleFormGroup.get('ood_name').value,
      end_time: this.oodScheduleFormGroup.get('end_time').value,
      shedule: this.oodScheduleFormGroup.get('shedule').value,
      start_date: this.oodScheduleFormGroup.get('start_date').value,
      start_time: this.oodScheduleFormGroup.get('start_time').value,
      vessel: this.oodScheduleFormGroup.get('vessel').value,
      ood_email: this.oodScheduleFormGroup.get('ood_name').value,
      onSiteContact: this.oodScheduleFormGroup.get('onSiteContact').value,
      onSiteContactEmail: this.oodScheduleFormGroup.get('onSiteContactEmail').value,
      supervisor_name: this.oodScheduleFormGroup.get('supervisor_name').value,
      supervisor_email: this.oodScheduleFormGroup.get('supervisor_email').value,
      ursfo: this.oodScheduleFormGroup.get('ursfo').value,
      proximity_ood: this.oodScheduleFormGroup.get('proximity_ood').value,
      qhm: this.oodScheduleFormGroup.get('qhm').value,
      ood: this.oodScheduleFormGroup.get('ood').value,
      location: this.shipLocation,
    }
    let port = this.oodScheduleFormGroup.get('dockyard').value;
    this.selectedVessel = this.oodScheduleFormGroup.get('vessel').value;
    if (port) {
      this.getDockyardShipData(port, data, (boo, location) => {
        if (boo) {
          let data = {
            dockyard: this.oodScheduleFormGroup.get('dockyard').value,
            ood_name: this.oodScheduleFormGroup.get('ood_name').value,
            end_time: this.oodScheduleFormGroup.get('end_time').value,
            shedule: this.oodScheduleFormGroup.get('shedule').value,
            start_date: this.oodScheduleFormGroup.get('start_date').value,
            start_time: this.oodScheduleFormGroup.get('start_time').value,
            vessel: this.oodScheduleFormGroup.get('vessel').value,
            ood_email: this.oodScheduleFormGroup.get('ood_name').value,
            onSiteContact: this.oodScheduleFormGroup.get('onSiteContact').value,
            onSiteContactEmail: this.oodScheduleFormGroup.get('onSiteContactEmail').value,
            supervisor_name: this.oodScheduleFormGroup.get('supervisor_name').value,
            supervisor_email: this.oodScheduleFormGroup.get('supervisor_email').value,
            ursfo: this.oodScheduleFormGroup.get('ursfo').value,
            proximity_ood: this.oodScheduleFormGroup.get('proximity_ood').value,
            qhm: this.oodScheduleFormGroup.get('qhm').value,
            ood: this.oodScheduleFormGroup.get('ood').value,
            location: location,
          }
          this.selectedDockyardVesselState = false;
          this.chitService.updateOod(this.chitId, data)
            .subscribe((res) => {
              this.dialog.closeAll();
              this.chitService.sendReload("reload");
            }, error => {
              console.error('There was an error!');
            })
        } else {
          this.selectedDockyardVesselState = true;
          if (this.vesselField) this.vesselField.focus();
        }
      })
    } else {
      console.log("Given data is not valid!!")
    }
  }

  // Vessel
  CreateVessel() {
    if (this.chitId) {
      console.log("Update Form Data", this.vesselMasterFormGroup.value);
      this.chitService.updateVessel(this.chitId, this.vesselMasterFormGroup.value)
        .subscribe((res) => {
          this.dialog.closeAll();
          this.chitService.sendReload("reload");
        }, error => {
          console.error('There was an error!');
        })
    } else {
      this.chitService.addVessel(this.vesselMasterFormGroup.value)
        .subscribe((res) => {
          this.chitId = res.insertedId;
          this.dialog.closeAll();
          this.chitService.sendReload("reload");
          var status = res.status
        }, error => {
          console.error('There was an error!');
        })
    }
  }
  getVesselData(id) {
    this.chitService.getVesselDataList(id)
      .subscribe(
        (response) => {
          var repos = response;
          var getVesselData = repos[0]
          this.vesselMasterFormGroup.patchValue(getVesselData);
        },
        error => {
          console.error('Request failed with error')
          console.log(error)
        })
  }
  UpdateVessel() {
    this.chitService.updateVessel(this.chitId, this.vesselMasterFormGroup.value)
      .subscribe((res) => {
        this.dialog.closeAll();
        this.chitService.sendReload("reload");
      }, error => {
        console.error('There was an error!');
      })
  }

  cancelForm() {
  }

  userList() {
    this.chitService.getUserData()
      .subscribe(
        (response) => {
          // console.log('response received', response)
          var repos = response;
          this.userListData = repos;
        },
        error => {
          console.error('Request failed with error')
          console.log(error)
        })
  }
  getShipConfig() {
    // this.locationArry = [];
    // this.shipConfig = []; 
    // this.reportServ.setConfigLastUploadedFile(this.uploadedJsonFile);
    // this.chitService.pullJsonData('http://localhost:3000/json', { port: 'cfbesquimalt', filename: 'shipConfig' }).then((shipConfig) => {
    this.chitService.pullJsonData('json', { port: 'cfbesquimalt', filename: 'shipConfig' }).then((shipConfig) => {

      // console.log("Ship Location ", shipConfig);
      this.VesselName = Object.keys(shipConfig);
      console.log("Ship Location ", this.VesselName);

    }).catch((error) => {
      console.log(error);
    });
  }
  supervisorDetails(value) {
    let shipData = this.userListData.find(el => el.email === value);
    if (shipData) {
      this.supervisorInfo = shipData;
    }
  }
  onSiteContactDetails(value) {
    let shipData = this.userListData.find(el => el.email === value);
    if (shipData) {
      this.onSiteContactInfo = shipData;
    }
  }
  oodDetails(value) {
    let shipData = this.userListData.find(el => el.email === value);
    if (shipData) {
      this.oodContactInfo = shipData;
    }
  }
  ursfoNameDetails: any;
  proximityOodNameDetails: any;
  qhmNameDetails: any;
  oodCurentNameDetails: any;

  ursfoDetails(value) {
    let shipData = this.userListData.find(el => el.email === value);
    if (shipData) {
      this.ursfoNameDetails = shipData.email;
    }
  }
  proximityOodDetails(value) {
    let shipData = this.userListData.find(el => el.email === value);
    if (shipData) {
      this.proximityOodNameDetails = shipData.email;
    }
  }
  qhmDetails(value) {
    let shipData = this.userListData.find(el => el.email === value);
    if (shipData) {
      this.qhmNameDetails = shipData.email;
    }
  }
  oodCurrentDetails(value) {
    let shipData = this.userListData.find(el => el.email === value);
    if (shipData) {
      this.oodCurentNameDetails = shipData.email;
    }
  }

  // Tagout
  CreateTagout() {
    this.chitService.addTagout(this.tagoutMasterFormGroup.value)
      .subscribe((res) => {
        this.dialog.closeAll();
        this.chitService.sendReload("reload");
        var status = res.status
      }, error => {
        console.error('There was an error!');
      })
  }
  selectedTagoutID = '';
  getTagoutData(id) {
    this.chitService.getTagoutDataList(id)
      .subscribe(
        (response) => {
          var repos = response;
          var getTagoutData = repos[0];
          this.tagoutMasterFormGroup.patchValue(getTagoutData);
          if (repos.length > 0) {
            this.selectedScheduleDuty = getTagoutData['sheduleDuty'];
          }
        },
        error => {
          console.error('Request failed with error')
          console.log(error)
        })
  }

  rolesListData = [];
  getRolesData(id) {
    this.chitService.getUserRoles(id)
      .subscribe(
        (response) => {
          this.rolesListData = response;
        },
        error => {
          console.error('Request failed with error')
          console.log(error)
        })
  }

  updateTagout() {
    this.chitService.updateTagout(this.chitId, this.tagoutMasterFormGroup.value)
      .subscribe((res) => {
        this.dialog.closeAll();
        this.chitService.sendReload("reload");
      }, error => {
        console.error('There was an error!');
      })
  }

  selectedScheduleDuty = 'initiate'
  onSelectScheduleDuty(value) {
    this.selectedScheduleDuty = value;
  }

  openRoles() {
    this.lockoutForm = false;
    this.rolesForm = true;
    this.createRolesButton = true;
    this.updateRolesButton = false;
    this.tagoutRolesFormGroup = this._formBuilder.group({
      tagout_id: [this.selectedTagoutID],
      scheduleDuty: ['', Validators.required],
      role: ['', Validators.required],
    });
  }

  createRole() {
    this.chitService.addRole(this.tagoutRolesFormGroup.value)
      .subscribe((res) => {
        this.cancelRoleForm();
        this.getRolesData(this.selectedTagoutID);
      }, error => {
        console.error('There was an error!');
      })
  }

  selectedRoleID = "";
  editRole(id) {
    this.lockoutForm = false;
    this.rolesForm = true;
    this.createRolesButton = false;
    this.updateRolesButton = true;
    this.chitService.getRole(id)
      .subscribe(
        (response) => {
          var getTagoutData = response[0];
          this.tagoutRolesFormGroup.patchValue(getTagoutData);
          if (response.length > 0) {
            this.selectedScheduleDuty = getTagoutData['scheduleDuty'];
            this.selectedRoleID = getTagoutData['_id'];
          }
        },
        error => {
          console.error('Request failed with error')
          console.log(error)
        })
  }

  updateRole() {
    this.chitService.updateRole(this.selectedRoleID, this.tagoutRolesFormGroup.value)
      .subscribe((res) => {
        this.cancelRoleForm();
        this.getRolesData(this.selectedTagoutID);
      }, error => {
        console.error('There was an error!');
      })
  }

  deleteRole(id) {
    this.chitService.deleteRole(id)
      .subscribe((res) => {
        this.cancelRoleForm();
        this.getRolesData(this.selectedTagoutID);
      }, error => {
        console.error('There was an error!');
      })
  }

  cancelRoleForm() {
    this.lockoutForm = true;
    this.rolesForm = false;
  }

  // *** CONTRACTOR FORM***
  createContractor() {
    console.log('contractor', this.contractorFormGroup);
    if (this.contractorFormGroup.status == 'VALID') {
      delete this.contractorFormGroup.value['_id'];
      this.chitService.addContractor(this.contractorFormGroup.value).subscribe((res) => {
        this.dialog.closeAll();
        this.chitService.sendReload("reload");
      }, error => {
        console.error('There was an error!');
      });
    }
  }

  setContractForm(contractorDet) {
    Object.keys(contractorDet).forEach(key => {
      this.contractorFormGroup.controls[key].setValue(contractorDet[key])
    });
  }

  updateContractor() {
    if (this.contractorFormGroup.status == 'VALID') {
      this.chitService.updateContractor(this.contractorFormGroup.value['_id'], this.contractorFormGroup.value).subscribe((res) => {
        this.dialog.closeAll();
        this.chitService.sendReload("reload");
      }, error => {
        console.error('There was an error!');
      });
    }
  }

  viewRoundsType: boolean = false;
  viewVisitorType: boolean = false;
  viewFlexType: boolean = false;
  viewlotoType: boolean = false;
  getlocationType(selectedValue) {
    console.log('getlocationType', selectedValue);
    if (selectedValue == 'rounds') {
      this.viewVisitorType = false;
      this.viewRoundsType = true;
      this.viewFlexType = false;
      this.viewlotoType = false;
    } else if (selectedValue == 'visitor') {
      this.viewRoundsType = false
      this.viewVisitorType = true;
      this.viewFlexType = false;
      this.viewlotoType = false;
    } else if (selectedValue == 'flex') {
      this.viewFlexType = true;
      this.viewRoundsType = false
      this.viewVisitorType = false;
      this.viewlotoType = false;
    }else if (selectedValue == 'loto') {
      this.viewFlexType = false;
      this.viewRoundsType = false
      this.viewVisitorType = false;
      this.viewlotoType = true;
    }
  }

  getRoundType(selectedValue) {
    console.log('getRoundType', selectedValue);
  }
  getvisitorlocation(selectedValue) {
    console.log('getvisitorlocation', selectedValue);
  }
  getflexlocation(selectedValue) {
  }

  createLocation() {
    if (this.locationFormGroup.status == 'VALID') {
      delete this.locationFormGroup.value['_id'];
      let locationkey;
      if (this.locationFormGroup.value['type'] == 'rounds') {
        locationkey = 'rounds_' + this.locationFormGroup.value['locationType'];
      } else if (this.locationFormGroup.value['type'] == 'visitor') {
        locationkey = 'visitor_' + this.locationFormGroup.value['locationType'];
      } else if (this.locationFormGroup.value['type'] == 'flex') {
        locationkey = 'flex_' + this.locationFormGroup.value['locationType'];
      }else if (this.locationFormGroup.value['type'] == 'loto') {
        locationkey = 'loto_' + this.locationFormGroup.value['locationType'];
      }
      let pushParams = {
        'locationKey': locationkey.split(" ").join(''),
        'location': JSON.parse(this.locationFormGroup.value['location'])
      }
      let objData = Object.assign({}, this.locationFormGroup.value, pushParams);
      console.log('createLocation', objData);
      this.chitService.createLocation(objData).subscribe((res) => {
        console.log('location', res)
        this.dialog.closeAll();
        this.chitService.sendReload("reload");
      }, error => {
        console.error('There was an error!');
      });
    }
  }
  setLocationForm(locationDet) {
    Object.keys(locationDet).forEach(key => {
      if (key == 'type') {
        if (locationDet[key] == 'rounds') {
          this.viewVisitorType = false;
          this.viewRoundsType = true;
          this.viewFlexType = false;
          this.viewlotoType = false;
        } else if (locationDet[key] == 'visitor') {
          this.viewRoundsType = false
          this.viewVisitorType = true;
          this.viewFlexType = false;
          this.viewlotoType = false;
        } else if (locationDet[key] == 'flex') {
          this.viewFlexType = true;
          this.viewRoundsType = false
          this.viewVisitorType = false;
          this.viewlotoType = false;
        }else if (locationDet[key] == 'loto') {
          this.viewFlexType = false;
          this.viewRoundsType = false
          this.viewVisitorType = false;
          this.viewlotoType = true;
        }
      }
      if (key == 'location') {
        let location = JSON.stringify(locationDet[key]);
        this.locationFormGroup.controls[key].setValue(location);
      } else {
        this.locationFormGroup.controls[key].setValue(locationDet[key])
      }
    });
  }

  updateLocation() {
    if (this.locationFormGroup.status == 'VALID') {
      let locationkey;
      if (this.locationFormGroup.value['type'] == 'rounds') {
        locationkey = 'rounds_' + this.locationFormGroup.value['locationType'];
      } else if (this.locationFormGroup.value['type'] == 'visitor') {
        locationkey = 'visitor_' + this.locationFormGroup.value['locationType'];
      } else if (this.locationFormGroup.value['type'] == 'flex') {
        locationkey = 'flex_' + this.locationFormGroup.value['locationType'];
      }else if (this.locationFormGroup.value['type'] == 'loto') {
        locationkey = 'loto_' + this.locationFormGroup.value['locationType'];
      }
      let pushParams = {
        'locationKey': locationkey.split(" ").join(''),
        'location': JSON.parse(this.locationFormGroup.value['location'])
      }
      let objData = Object.assign({}, this.locationFormGroup.value, pushParams);
      this.chitService.updateLocation(this.locationFormGroup.value['_id'], objData).subscribe((res) => {
        this.dialog.closeAll();
        this.chitService.sendReload("reload");
      }, error => {
        console.error('There was an error!');
      });
    }
  }
}