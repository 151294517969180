import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent, HeaderContentDialog, ProfilePopup } from './header/header.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MatToolbarModule,
  MatListModule,
  MatDialogModule,
  MatMenuModule,
  MatIconModule,
  MatSidenavModule,
  MatFormFieldModule,
  MatInputModule,
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatTabsModule,
  MatGridListModule,
  MatTableModule,
  MatDialogConfig,
  MatSlideToggleModule,
} from '@angular/material';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NavyAppComponent, PageContentDialog } from './navy-app/navy-app.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AuthenticationComponent } from './authentication/authentication.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginService } from './services/login.service';
import { UserService } from './services/user.service';
import { CommunicationService } from './services/communication.service';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, '/assets/i18n/', '-lang.json');
}
import { MsalModule, MsalInterceptor } from '@azure/msal-angular';
import { AdminComponent, AdminPopup } from './admin/admin.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBadgeModule } from '@angular/material/badge';
import { AuthGuardService } from './services/auth.service'
import { customInterceptor } from './services/http.interceptor';
import { ChitsComponent, ChitPopup } from './chits/chits.component'
import { MatStepperModule } from '@angular/material/stepper';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule } from '@angular/material/sort';
import { VisitorComponent, VisitorPopup } from './visitor/visitor.component';
import { QrScannerComponent, PopupComponent } from './qr-scanner/qr-scanner.component';

import { NgxScannerQrcodeModule } from 'ngx-scanner-qrcode';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

var config = {
  clientId: "a3cf3fb4-11fb-491a-aabd-5e9e00548968",
  authority: 'https://login.microsoftonline.com/c201b39a-972b-4dfc-ae05-0d66ce8adfb0',
  redirectUri: window.origin + '/',
};

let hostname = window.location.hostname;
if (hostname === 'localhost') {
  config['clientId'] = 'eeb166ab-6393-4557-b8d3-9a688a604754';
} else if (hostname === 'navyapp.azurewebsites.net') {
  config['clientId'] = 'a3cf3fb4-11fb-491a-aabd-5e9e00548968';
} else if (hostname === 'navy.datifex.net') {
  config['clientId'] = 'a3cf3fb4-11fb-491a-aabd-5e9e00548968';
} else if (hostname === 'demo.datifex.com') {
  config['clientId'] = 'a3cf3fb4-11fb-491a-aabd-5e9e00548968';
} else if (hostname === 'navy.datifex.com') {
  config['clientId'] = 'a3cf3fb4-11fb-491a-aabd-5e9e00548968';
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    NavyAppComponent,
    PageContentDialog,
    AuthenticationComponent,
    HeaderContentDialog,
    ProfilePopup,
    AdminComponent,
    AdminPopup,
    ChitsComponent,
    ChitPopup,
    VisitorComponent,
    VisitorPopup,
    QrScannerComponent,
    PopupComponent
  ],
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgxScannerQrcodeModule,
    DragDropModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    MatCheckboxModule,
    MatTabsModule,
    AppRoutingModule,
    MatGridListModule, MatButtonToggleModule,
    BrowserAnimationsModule, MatMenuModule, MatCardModule, MatAutocompleteModule,
    MatToolbarModule, MatSidenavModule, MatListModule, MatDialogModule, MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatRadioModule,
    MatCardModule,
    MatButtonModule,
    MatTooltipModule,
    MatBadgeModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule, MatFormFieldModule, MatInputModule, MatButtonModule, MatStepperModule, MatSlideToggleModule, MatProgressSpinnerModule, MatSortModule,
    MsalModule.forRoot({
      auth: config,

      // auth: {
      //   clientId: "a3cf3fb4-11fb-491a-aabd-5e9e00548968",
      //   authority: 'https://login.microsoftonline.com/c201b39a-972b-4dfc-ae05-0d66ce8adfb0',
      //   redirectUri: "https://navyapp.azurewebsites.net/",

      //   // clientId: "eeb166ab-6393-4557-b8d3-9a688a604754",
      //   // authority: 'https://login.microsoftonline.com/c201b39a-972b-4dfc-ae05-0d66ce8adfb0',
      //   // redirectUri: "http://localhost:4200",
      // },

      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: isIE, // set to true for IE 11
      },
    },
      {
        popUp: !isIE,
        consentScopes: [
          'user.read',
          'openid',
          'profile',
          'directory.read'
        ],
        protectedResourceMap: [
          ['https://graph.microsoft.com/v1.0/me', ['user.read']],
          ['https://graph.microsoft.com/v1.0/users', ['directory.read']],
          // ["https://navyapp.azurewebsites.net/", ["api://854fcfb1-81ec-4e33-911f-9041e2b3fdd9/access_api"]]
        ],
        extraQueryParameters: {}
      })
  ], exports: [DragDropModule, MatStepperModule, MatDatepickerModule, HttpClientModule, MatSortModule],
  entryComponents: [
    PageContentDialog,
    AuthenticationComponent,
    HeaderContentDialog,
    ProfilePopup,
    AdminComponent,
    AdminPopup,
    ChitPopup,
    VisitorPopup,
    PopupComponent
  ],
  providers: [
    CommunicationService,
    UserService,
    AuthGuardService,
    CommunicationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: customInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
